/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Amplify } from 'aws-amplify';
import awsConfig from 'aws-exports';
import { AppState } from 'redux/store/store';

import { User } from 'modules/user/userApiTypes';

import { authStorage } from './authStorage';
import {
  AuthResponse,
  AuthState,
  ChangePasswordPayload,
  CreateNewPasswordPayload,
  ForgotPasswordPayload,
  SigninPayload,
  SignupPayload,
  UserRequestValues,
} from './types';

Amplify.configure(awsConfig);

const initialState: AuthState = {
  idToken: await authStorage.getIdToken(),
  accessToken: await authStorage.getAccessToken(),
  isLoading: false,
  user: await authStorage.getUser(),
  error: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart(state: AuthState, action: PayloadAction<SigninPayload>) {
      state.isLoading = true;
    },
    loginSuccess(state: AuthState, action: PayloadAction<AuthResponse>) {
      state.isLoading = false;
      state.error = null;
      state.user = action.payload.user;
    },
    loginFailure(state: AuthState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    signupStart(state: AuthState, action: PayloadAction<SignupPayload>) {
      state.isLoading = true;
    },
    signupSuccess(state: AuthState) {
      state.isLoading = false;
      state.error = null;
    },
    signupFailure(state: AuthState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    forgotPasswordStart(state: AuthState, action: PayloadAction<ForgotPasswordPayload>) {
      state.isLoading = true;
      state.error = null;
    },
    forgotPasswordSuccess(state: AuthState) {
      state.isLoading = false;
      state.error = null;
    },
    forgotPasswordFailure(state: AuthState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    createNewPasswordStart(state: AuthState, action: PayloadAction<CreateNewPasswordPayload>) {
      state.isLoading = true;
      state.error = null;
    },
    createNewPasswordSuccess(state: AuthState) {
      state.isLoading = false;
      state.error = null;
    },
    createNewPasswordFailure(state: AuthState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    changePasswordStart(state: AuthState, action: PayloadAction<ChangePasswordPayload>) {
      state.isLoading = false;
      state.error = null;
    },
    changePasswordSuccess(state: AuthState) {
      state.isLoading = false;
      state.error = null;
    },
    changePasswordFailure(state: AuthState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    updateUserpicStart(state: AuthState, action: PayloadAction<string>) {
      state.isLoading = true;
      state.error = null;
    },
    updateUserpicSuccess: (state: AuthState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = null;
      if (state.user) state.user.userpic = payload;
    },
    updateUserpicFailure(state: AuthState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    updateUserStart(state: AuthState, action: PayloadAction<UserRequestValues>) {
      state.isLoading = true;
    },
    updateUserSuccess(state: AuthState, action: PayloadAction<User>) {
      state.isLoading = false;
      state.error = null;
      if (state.user) {
        state.user.fullname = action.payload.fullName;
        state.user.phone = action.payload.phoneNumber;
      }
    },
    updateUserFailure(state: AuthState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    logout(state: AuthState) {
      state.idToken = null;
      state.accessToken = null;
      state.user = null;
    },
  },
});

export const getAuthState = ({ auth }: AppState) => auth;
export const getIsAuthLoading = (state: AppState) => getAuthState(state).isLoading;
export const getError = (state: AppState) => getAuthState(state).error;
export const getUserId = (state: AppState) => getAuthState(state).user?.id;

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  signupStart,
  signupSuccess,
  signupFailure,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  createNewPasswordStart,
  createNewPasswordSuccess,
  createNewPasswordFailure,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
  updateUserpicStart,
  updateUserpicSuccess,
  updateUserpicFailure,
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
  logout,
} = authSlice.actions;
export const auth = authSlice.reducer;
