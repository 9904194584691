import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createRootReducer } from 'redux/reducers';
import { ctvApi } from 'redux/rootApiSlice';
import { rootSaga } from 'redux/rootSaga';

import { notifyError } from 'services/log/logService';

const sagaMiddleware = createSagaMiddleware();

function createAppStore() {
  const reducer = createRootReducer();

  const middlewares = [sagaMiddleware, ctvApi.middleware];

  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(middlewares),
  });
}

export const store = createAppStore();
sagaMiddleware
  .run(rootSaga)
  .toPromise()
  .catch((error) => {
    notifyError(error);
  });

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
