import { AuthSession, FetchUserAttributesOutput } from 'aws-amplify/auth';

import { authApi } from './authApi';

async function getIdToken() {
  const session: AuthSession = (await authApi.getSession()) || {};

  return session?.tokens?.idToken?.toString() || '';
}

async function getAccessToken() {
  const session: AuthSession = (await authApi.getSession()) || {};

  return session?.tokens?.accessToken?.toString() || '';
}

async function getUser() {
  const session: AuthSession = (await authApi.getSession()) || {};

  if (!session?.tokens) return null;

  const attributes: FetchUserAttributesOutput = (await authApi.fetchAttributes()) || {};

  const idToken = session?.tokens?.idToken;
  const pictureUrl = attributes?.['custom:picture_url'];

  return {
    email: attributes.email,
    fullname: attributes['custom:full_name'],
    phone: attributes['custom:phone'],
    role: attributes['custom:role'],
    id: attributes.sub,
    userpic: pictureUrl ? `${pictureUrl}&idToken=${idToken}` : '',
    advertiserId: null,
  };
}

export const authStorage = {
  getUser,
  getIdToken,
  getAccessToken,
};
