export const HttpStatus = {
  Success: 200,
  NoContent: 204,
  Created: 201,
  Found: 302,
  NotModified: 304,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  MethodNotAllowed: 405,
  NotAcceptable: 406,
  Conflict: 409,
  InternalServerError: 500,
  NotImplemented: 501,
  BadGateway: 502,
  ServiceUnavailable: 503,
  GatewayTimeout: 504,
} as const;

export const Http500ErrorText =
  'The server encountered an error and could not complete your request. Please, try again later.';

export const DUPLICATE_ENTRY_CODE = 1023;
