/* eslint-disable no-empty */
import { AxiosInstance } from 'axios';

import NotificationService from 'modules/notifications/notificationService';
import { ADVERTISERS_URL, AGENCIES_URL } from 'modules/organization/constants';
import { VIEW_URL } from 'modules/performance/constants';
import { INVITE_USER_URL, UPDATE_MEMBER_URL, USERS_URL } from 'modules/user/constants';
import {
  INSERTION_ORDER_STATUS_URL_LIST,
  INSERTION_ORDER_URL,
  LINE_ITEM_EDIT_URL,
  XANDR_STATUS_URL,
} from 'modules/xandr/constants';

import { ROUTES } from 'routing/constants';

import { FILE_URL } from 'services/file/fileService';

import { DUPLICATE_ENTRY_CODE, HttpStatus } from './httpStatus';

export function addResponseInterceptor(instance: AxiosInstance) {
  instance.interceptors.response.use(
    async (response) => {
      const status = response?.status;
      const requestedUrl = response?.request?.responseURL;
      const currentUrl = window.location.href;

      if (status === HttpStatus.Success) {
        if (requestedUrl.endsWith(FILE_URL)) {
          NotificationService.success('Image uploaded');
        } else if (
          (requestedUrl.endsWith(USERS_URL) && currentUrl.endsWith(ROUTES.USER_SETTINGS)) ||
          (requestedUrl.endsWith(UPDATE_MEMBER_URL) && currentUrl.endsWith(ROUTES.MEMBERS_SETTINGS))
        ) {
          NotificationService.success('User updated');
        } else if (requestedUrl.endsWith(ADVERTISERS_URL)) {
          NotificationService.success('Advertiser updated');
        } else if (
          requestedUrl.endsWith(AGENCIES_URL) &&
          currentUrl.endsWith(ROUTES.AGENCY_SETTINGS)
        ) {
          NotificationService.success('Agency updated');
        } else if (requestedUrl.endsWith(VIEW_URL) && currentUrl.endsWith(ROUTES.HOME)) {
          NotificationService.success('View updated');
        } else if (requestedUrl.endsWith(LINE_ITEM_EDIT_URL)) {
          NotificationService.success('Line item updated');
        } else if (requestedUrl.endsWith(INSERTION_ORDER_URL)) {
          NotificationService.success('Insertion order updated');
        }
      } else if (status === HttpStatus.Created) {
        if (requestedUrl.endsWith(INVITE_USER_URL)) {
          NotificationService.success('User invited');
        } else if (requestedUrl.endsWith(ADVERTISERS_URL)) {
          NotificationService.success('Advertiser created');
        } else if (requestedUrl.endsWith(AGENCIES_URL)) {
          NotificationService.success('Agency created');
        } else if (requestedUrl.endsWith(VIEW_URL)) {
          NotificationService.success('View created');
        } else if (requestedUrl.endsWith(INSERTION_ORDER_STATUS_URL_LIST)) {
          NotificationService.success('Synced to dashboard');
        }
      } else if (status === HttpStatus.NoContent) {
        if (requestedUrl.endsWith(FILE_URL)) {
          NotificationService.info('Image deleted');
        } else if (requestedUrl.includes(USERS_URL)) {
          NotificationService.success('User deleted');
        } else if (requestedUrl.includes(VIEW_URL)) {
          NotificationService.success('View deleted');
        }
      }

      return response;
    },
    async (error) => {
      const status = error?.response?.status;
      const message = error?.response?.data?.message;
      const code = error?.response?.data?.code;
      const requestedUrl = error.response?.request?.responseURL;

      if (status === HttpStatus.Unauthorized) {
        window.location.replace(ROUTES.LOGIN);
      } else if (status === HttpStatus.Forbidden) {
      } else if (status === HttpStatus.BadRequest) {
        if (code === DUPLICATE_ENTRY_CODE) {
          NotificationService.warn(
            'An item with this name already exists. Please choose a different name to avoid duplicates.',
          );
        } else {
          NotificationService.warn(message);
        }
      } else if (status === HttpStatus.InternalServerError) {
        // temporary workaround, because 500 for now is a normal response status where there is no xandr active connection
        if (!requestedUrl.includes(XANDR_STATUS_URL)) {
          NotificationService.error(message);
        }
      } else if (error.request) {
        // console.error('No response received from server');
      } else {
        // console.error('Error in request setup:', error.message);
      }

      return Promise.reject(error);
    },
  );
}
