import { Button, Indicator } from 'components/common';

import { Report } from 'modules/report/reportApiTypes';
import { useReport } from 'modules/report/useReport';

type DeleteReportModalProps = {
  onClose: () => void;
  report: Report | null;
  refetch: () => void;
};

export const DeleteReportModal = ({ onClose, report, refetch }: DeleteReportModalProps) => {
  const { deleteReport, isLoading, errorText } = useReport();

  const handleDelete = async () => {
    report && (await deleteReport(report.id));
    refetch();
    onClose();
  };

  return (
    <div className="w-128 bg-white rounded-xl shadow  flex-col justify-start items-start inline-flex">
      <div className="self-stretch px-6 justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 py-5 flex-col justify-start items-start gap-2 inline-flex">
          <h1 className="self-stretch text-base-md font-medium leading-snug text-secondary-black">
            Delete report
          </h1>
        </div>
      </div>
      <div className="px-6 pt-3 gap-6 stretch-to-form">
        <div className="text-secondary-black text-base-sm font-medium leading-tight">
          Are you sure you want to permanently delete this report?
        </div>
        <div className="stretch-to-form gap-3">
          {errorText && (
            <Indicator variant="danger" size="lg">
              {errorText}
            </Indicator>
          )}
        </div>
      </div>
      <div className="self-stretch h-16 px-6 border-t border-primary-gray-100 justify-end items-center gap-2 inline-flex">
        <div className="justify-end items-center gap-2 flex">
          <Button
            size="md"
            variant="secondary"
            onClick={onClose}
            disabled={isLoading}
            className="w-20"
          >
            Cancel
          </Button>
          <Button size="md" variant="danger" onClick={handleDelete} isLoading={isLoading}>
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};
