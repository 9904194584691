export type XandrAdvertiser = {
  id: number;
  name: string;
  timezone: string;
  creationDate: string;
  modificationDate: string;
};

export type XandrState = {
  isLoading: boolean;
  error: string | null;
  connection: XandrConnection | null;
  insertionOrders: InsertionOrder[] | null;
  status: XandrStatus | null;
  editedLineItem: LineItem | null;
  editedInsertionOrder: InsertionOrder | null;
  lineItems: LineItem[] | null;
  audiences: string[] | null;
};

export type XandrConnectionRequest = {
  advertiserId: string;
  xandrAdvertiserId: string;
};

export enum PriceType {
  ADDED = 'ADDED',
  CPM = 'CPM',
}

export const DeviceType = {
  CTV: {
    label: 'CTV',
    value: 'CTV',
  },
  DESKTOP: {
    label: 'Desktop',
    value: 'DESKTOP',
  },
  MOBILE: {
    label: 'Mobile',
    value: 'MOBILE',
  },
  TABLET: {
    label: 'Tablet',
    value: 'TABLET',
  },
} as const;

export type Device = {
  id: number;
  name: string;
};

export type MediaType = {
  id: number;
  name: string;
};

export type LineItem = {
  id: number;
  displayName: string;
  priceType: string; // ADDED, CPM
  audienceName: string;
  mediaTypeId: number;
  deviceTypeIds: number[];
  cpm?: number;
  budgetCap?: number;
  name?: string | null;
  enabled?: boolean | string;
  synced?: boolean | string;
  mediaType?: MediaType | null;
  devices?: Device[] | null;
  advertiser?: XandrAdvertiser | null;
  draftLI?: LineItem | null;
  creationDate?: string | null;
  modificationDate?: string | null;
  new?: boolean | null;
};

export type InsertionOrder = {
  id: number;
  name?: string | null;
  displayName: string;
  enabled?: boolean | null;
  synced?: boolean | null;
  advertiser?: XandrAdvertiser | null;
  lineItems?: LineItem[] | null;
  draftIO?: InsertionOrder | null;
  creationDate?: string | null;
  modificationDate?: string | null;
  new?: boolean | null;
};

export type InsertionOrderEditRequest = {
  advertiserId: string;
  insertionOrder: InsertionOrder;
  resolve?: () => void;
  reject?: (error: string) => void;
};

export type InsertionOrderEnableRequest = { id: number; enabled: boolean; advertiserId: string };

export type XandrStatus = {
  id: number;
  name: string;
  timezone: string;
  creationDate: string;
  modificationDate: string;
};

export type Person = {
  id: string;
  subId: string;
  email: string;
  fullName: string;
  phoneNumber: string;
  role: string; // UserRoles
  status: string;
  advertiser: string;
  creationDate: string;
  modificationDate: string;
};

export type Agency = {
  id: string;
  name: string;
  timeZone: string;
  enabledCustomLogo: true;
  logoUrl: string;
  wordMarkUrl: string;
  advertisers: string[];
  creationDate: string;
  modificationDate: string;
};

export type XandrConnection = {
  id: string;
  xandrId: number;
  name: string;
  industry: string;
  pixelCode: string;
  agency: Agency;
  persons: Person[];
  xandrAdvertiser: XandrAdvertiser;
  creationDate: string;
  modificationDate: string;
};

export type LineItemEditRequest = {
  advertiserId: string;
  lineItem: LineItem;
  resolve?: () => void;
  reject?: (error: string) => void;
};

export type LineItemsPagingResponse = {
  data: LineItem[];
  totalPages: number;
};

export type AudiencesPagingResponse = {
  data: string[];
  totalPages: number;
};
