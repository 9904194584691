/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

import {
  PixelEventCategory,
  PixelEventDaily,
  PixelEvents,
  PixelEventsRequest,
  PixelRequest,
  PixelState,
  PixelWidget,
} from './pixelApiTypes';

export const initialState: PixelState = {
  isLoading: false,
  error: null,
  widget: null,
  events: null,
  categories: null,
  eventsDaily: null,
};

export const pixelSlice = createSlice({
  name: 'pixel',
  initialState,
  reducers: {
    getPixelWidgetStart(state: PixelState, action: PayloadAction<PixelRequest>) {
      state.isLoading = true;
    },
    getPixelWidgetSuccess(state: PixelState, action: PayloadAction<PixelWidget>) {
      state.isLoading = false;
      state.widget = action.payload;
      state.error = null;
    },
    getPixelWidgetFailure(state: PixelState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getPixelEventsStart(state: PixelState, action: PayloadAction<PixelEventsRequest>) {
      state.isLoading = true;
    },
    getPixelEventsSuccess(state: PixelState, action: PayloadAction<PixelEvents>) {
      state.isLoading = false;
      state.events = action.payload;
      state.error = null;
    },
    getPixelEventsFailure(state: PixelState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getPixelCategoriesStart(state: PixelState, action: PayloadAction<PixelRequest>) {
      state.isLoading = true;
    },
    getPixelCategoriesSuccess(state: PixelState, action: PayloadAction<PixelEventCategory[]>) {
      state.isLoading = false;
      state.categories = action.payload;
      state.error = null;
    },
    getPixelCategoriesFailure(state: PixelState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getPixelExportStart(state: PixelState) {
      state.isLoading = true;
    },
    getPixelExportSuccess(state: PixelState, action: PayloadAction<unknown>) {
      state.isLoading = false;
      state.error = null;
    },
    getPixelExportFailure(state: PixelState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getPixelDailyEventsStart(state: PixelState, action: PayloadAction<PixelRequest>) {
      state.isLoading = true;
    },
    getPixelDailyEventsSuccess(state: PixelState, action: PayloadAction<PixelEventDaily[]>) {
      state.isLoading = false;
      state.eventsDaily = action.payload;
      state.error = null;
    },
    getPixelDailyEventsFailure(state: PixelState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const getPixelState = ({ pixel }: AppState) => pixel;
export const getIsPixelLoading = (state: AppState) => getPixelState(state).isLoading;
export const getError = (state: AppState) => getPixelState(state).error;

export const {
  getPixelWidgetFailure,
  getPixelWidgetStart,
  getPixelWidgetSuccess,
  getPixelEventsFailure,
  getPixelEventsStart,
  getPixelEventsSuccess,
  getPixelCategoriesFailure,
  getPixelCategoriesStart,
  getPixelCategoriesSuccess,
  getPixelExportFailure,
  getPixelExportStart,
  getPixelExportSuccess,
  getPixelDailyEventsFailure,
  getPixelDailyEventsStart,
  getPixelDailyEventsSuccess,
} = pixelSlice.actions;
export const pixel = pixelSlice.reducer;
