import { ConfigProvider as AntdConfigProvider } from 'antd';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AppRouter } from 'routing/AppRouter';

import { StoreProvider } from './redux/store/StoreProvider';

function App() {
  return (
    <StoreProvider>
      <AntdConfigProvider>
        <AppRouter />
        <ToastContainer />
      </AntdConfigProvider>
    </StoreProvider>
  );
}

export default App;
