import { PropsWithChildren } from 'react';

import backgroundVideo from 'assets/video/backgroundVideo.mp4';

export const VideoBackground = ({ children }: PropsWithChildren) => (
  <>
    <video
      src={backgroundVideo}
      autoPlay
      loop
      muted
      className="video w-full h-screen object-fill"
    ></video>
    <div className="content w-full h-full absolute flex justify-center items-center top-0 left-0">
      {children}
    </div>
  </>
);

VideoBackground.displayName = 'VideoBackground';
