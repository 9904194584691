import { createSelector } from '@reduxjs/toolkit';
import { ctvApi } from 'redux/rootApiSlice';

import { Constants, DeviceTypes, MediaTypes, Subcategories } from 'modules/misc/miscApiTypes';

export const dataApiSlice = ctvApi.injectEndpoints({
  endpoints: (build) => ({
    constants: build.query<Constants, null>({
      query: () => ({ method: 'GET', url: 'constants' }),
      providesTags: [{ type: 'ApiData', id: 'Constants' }],
    }),
    mediaTypes: build.query<MediaTypes, null>({
      query: () => ({ method: 'GET', url: 'types/media' }),
      providesTags: [{ type: 'ApiData', id: 'MediaTypes' }],
    }),
    deviceTypes: build.query<DeviceTypes, null>({
      query: () => ({ method: 'GET', url: 'types/device' }),
      providesTags: [{ type: 'ApiData', id: 'DeviceTypes' }],
    }),
    audiences: build.query<string[], string>({
      query: (advertiserId) => ({
        method: 'GET',
        url: `line-item/audience?advertiserId=${advertiserId}`,
      }),
      providesTags: [{ type: 'ApiData', id: 'Audiences' }],
    }),
    creatives: build.query<string[], string>({
      query: (advertiserId) => ({
        method: 'GET',
        url: `creative/names?advertiserId=${advertiserId}`,
      }),
      providesTags: [{ type: 'ApiData', id: 'Creatives' }],
    }),
    lineItems: build.query<string[], string>({
      query: (advertiserId) => ({
        method: 'GET',
        url: `line-item/names?advertiserId=${advertiserId}`,
      }),
      providesTags: [{ type: 'ApiData', id: 'LineItems' }],
    }),
    insertionOrders: build.query<string[], string>({
      query: (advertiserId) => ({
        method: 'GET',
        url: `insertion-order/names?advertiserId=${advertiserId}`,
      }),
      providesTags: [{ type: 'ApiData', id: 'InsertionOrders' }],
    }),
    publishers: build.query<string[], null>({
      query: () => ({ method: 'GET', url: 'publisher/names' }),
      providesTags: [{ type: 'ApiData', id: 'Publishers' }],
    }),
    subcategories: build.query<Subcategories, string>({
      query: (advertiserId) => ({
        method: 'POST',
        url: `pixel/subcategories/${advertiserId}`,
      }),
      providesTags: [{ type: 'ApiData', id: 'Subcategories' }],
    }),
  }),
});

export const {
  useConstantsQuery,
  useDeviceTypesQuery,
  useMediaTypesQuery,
  useAudiencesQuery,
  useCreativesQuery,
  useInsertionOrdersQuery,
  useLineItemsQuery,
  usePublishersQuery,
  useSubcategoriesQuery,
  usePrefetch: useApiDataSlicePrefetch,
} = dataApiSlice;

export const selectConstants = createSelector(
  dataApiSlice.endpoints.constants.select(null),
  (constantsResult) => constantsResult.data,
);

export const selectMetrics = createSelector(
  dataApiSlice.endpoints.constants.select(null),
  (constantsResult) => constantsResult.data?.Metrics,
);

export const selectTimeSlice = createSelector(
  dataApiSlice.endpoints.constants.select(null),
  (constantsResult) => constantsResult.data?.TimeSlice,
);

export const selectDimensions = createSelector(
  dataApiSlice.endpoints.constants.select(null),
  (constantsResult) => constantsResult.data?.Dimensions,
);

export const selectCadence = createSelector(
  dataApiSlice.endpoints.constants.select(null),
  (constantsResult) => constantsResult.data?.Cadence,
);
