import { Suspense } from 'react';

import { PageLayout, PageLayoutProps } from 'components/PageLayout';
import { Spin } from 'components/common';

import { ProtectedRoute } from 'routing/ProtectedRoute';

const PageLoader = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <Spin />
    </div>
  );
};

export const ProtectedLayout = ({ children, className, contentClassName }: PageLayoutProps) => {
  return (
    <ProtectedRoute>
      <PageLayout className={className} contentClassName={contentClassName}>
        <Suspense fallback={<PageLoader />}>{children}</Suspense>
      </PageLayout>
    </ProtectedRoute>
  );
};
