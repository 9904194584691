import { Tooltip as TooltipAntd, TooltipProps as TooltipPropsAntd } from 'antd';
import { memo, ReactNode } from 'react';

import { ReactComponent as InfoMark } from 'assets/images/icons/infoMark.svg';
import { ReactComponent as QuestionMark } from 'assets/images/icons/questionMark.svg';

type TooltipProps = {
  title: string | ReactNode;
  body?: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
};

export const Tooltip = memo(
  ({ className, children, title, body, ...rest }: TooltipPropsAntd & TooltipProps) => {
    const content = (
      <>
        <div className="text-white text-sm font-semibold leading-tight">{title}</div>
        <div className="self-stretch text-white text-sm font-normal leading-tight">{body}</div>
      </>
    );

    return (
      <TooltipAntd className={className} overlayClassName="ctv-tooltip" title={content} {...rest}>
        {children}
      </TooltipAntd>
    );
  },
);
Tooltip.displayName = 'Tooltip';

export const InfoTooltip = ({ title, body, ...rest }: TooltipProps) => (
  <Tooltip title={title} body={body} {...rest}>
    <InfoMark />
  </Tooltip>
);

export const QuestionTooltip = ({ title, body, ...rest }: TooltipProps) => (
  <Tooltip title={title} body={body} {...rest}>
    <QuestionMark />
  </Tooltip>
);
