/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

import { PagingRequestValues } from 'modules/organization/organizationApiTypes';

import {
  ReportState,
  ReportTemplate,
  Report,
  ReportCreateRequest,
  ReportsPagingResponse,
  DeleteReportRequest,
  DownloadReportRequest,
  ReportStatusResponse,
  ReportStatusRequest,
} from './reportApiTypes';

export const initialState: ReportState = {
  isLoading: false,
  error: null,
  reports: null,
  reportTemplates: null,
  createdReport: null,
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    getReportsStart(state: ReportState, action: PayloadAction<PagingRequestValues>) {
      state.isLoading = true;
    },
    getReportsSuccess(state: ReportState, action: PayloadAction<ReportsPagingResponse>) {
      state.isLoading = false;
      state.reports = action.payload;
      state.error = null;
    },
    getReportsFailure(state: ReportState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    saveReportStart(state: ReportState, action: PayloadAction<ReportCreateRequest>) {
      state.isLoading = true;
    },
    saveReportSuccess(state: ReportState, action: PayloadAction<Report>) {
      state.isLoading = false;
      state.createdReport = action.payload;
      state.error = null;
    },
    saveReportFailure(state: ReportState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getReportTemplatesStart(state: ReportState) {
      state.isLoading = true;
    },
    getReportTemplatesSuccess(state: ReportState, action: PayloadAction<ReportTemplate[]>) {
      state.isLoading = false;
      state.reportTemplates = action.payload;
      state.error = null;
    },
    getReportTemplatesFailure(state: ReportState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    deleteReportStart(state: ReportState, action: PayloadAction<DeleteReportRequest>) {
      state.isLoading = true;
    },
    deleteReportSuccess(state: ReportState) {
      state.isLoading = false;
      state.error = null;
    },
    deleteReportFailure(state: ReportState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    downloadReportStart(state: ReportState, action: PayloadAction<DownloadReportRequest>) {
      state.isLoading = true;
    },
    downloadReportSuccess(state: ReportState) {
      state.isLoading = false;
      state.error = null;
    },
    downloadReportFailure(state: ReportState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getReportStatusStart(state: ReportState, action: PayloadAction<ReportStatusRequest>) {
      state.isLoading = true;
    },
    getReportStatusSuccess(state: ReportState, action: PayloadAction<ReportStatusResponse>) {
      state.isLoading = false;
      state.error = null;
    },
    getReportStatusFailure(state: ReportState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const getReportState = ({ report }: AppState) => report;
export const getIsReportLoading = (state: AppState) => getReportState(state).isLoading;
export const getError = (state: AppState) => getReportState(state).error;

export const {
  getReportsFailure,
  getReportsStart,
  getReportsSuccess,
  getReportTemplatesFailure,
  getReportTemplatesStart,
  getReportTemplatesSuccess,
  saveReportFailure,
  saveReportStart,
  saveReportSuccess,
  deleteReportFailure,
  deleteReportStart,
  deleteReportSuccess,
  downloadReportStart,
  downloadReportSuccess,
  downloadReportFailure,
  getReportStatusStart,
  getReportStatusSuccess,
  getReportStatusFailure,
} = reportSlice.actions;
export const report = reportSlice.reducer;
