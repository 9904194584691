import { IconClose } from '@aws-amplify/ui-react/internal';
import { Flex } from 'antd';
import { useEffect, useMemo } from 'react';

import { ReactComponent as IconDataFlow } from 'assets/images/icons/dataflow.svg';

import { FilterDimension } from 'components/FilterDimension';
import {
  restrictionDimensionPair,
  visibleDimensions,
} from 'components/PerformanceTable/performanceTypes';
import { IconButton, Tooltip } from 'components/common';
import { IconType } from 'components/common/button/types';

import { useConstantsQuery } from 'modules/apiData/dataApiSlice';
import { usePerformance } from 'modules/performance/usePerformance';

export const FilterDimensionGrid = ({
  selectedDimensions,
  onChangeDimension,
}: {
  selectedDimensions: [string | null, string | null];
  onChangeDimension: (dimensions: [string | null, string | null]) => void;
}) => {
  const { data: constants } = useConstantsQuery(null);
  const { showSecondFilter, pickShowSecondFilter } = usePerformance();
  const allDimensionOptions = useMemo(() => (constants && constants.Dimensions) || {}, [constants]);

  const getDimensionOptions = useMemo(
    () =>
      Object.entries(allDimensionOptions)
        .map(([key, value]) => ({
          label: value.shownName,
          value: key,
        }))
        .filter((el) => visibleDimensions.includes(el.value)),
    [allDimensionOptions],
  );

  useEffect(() => {
    if (!selectedDimensions[0] && getDimensionOptions.length > 0) {
      onChangeDimension([getDimensionOptions[0]?.value, selectedDimensions[1]]);
    }
  }, [selectedDimensions, onChangeDimension, getDimensionOptions]);

  const isRestrictedDimension = (firstDimension: string, secondDimension: string) => {
    return restrictionDimensionPair[
      firstDimension as keyof typeof restrictionDimensionPair
    ]?.includes(secondDimension);
  };

  const getSecondDimensionOptions = useMemo(() => {
    return getDimensionOptions.map((option) => ({
      ...option,
      disabled: isRestrictedDimension(selectedDimensions[0]!, option.value),
    }));
  }, [selectedDimensions, getDimensionOptions]);

  const iconButton = (
    <IconButton
      size="md"
      variant={showSecondFilter ? 'tertiary' : 'secondary'}
      Icon={showSecondFilter ? (IconClose as IconType) : (IconDataFlow as IconType)}
      onClick={() => {
        if (showSecondFilter && selectedDimensions[1]) {
          onChangeDimension([selectedDimensions[0], null]);
        }
        pickShowSecondFilter(!showSecondFilter);
      }}
    />
  );

  return (
    <Flex gap="small">
      <FilterDimension
        prefixText="1st&nbsp;dimension:"
        allOptions={getDimensionOptions}
        selectedOption={selectedDimensions[0]}
        onChange={(value) => {
          const newDimensions: [string | null, string | null] = [
            value,
            isRestrictedDimension(value, selectedDimensions[1]!) ? null : selectedDimensions[1],
          ];

          onChangeDimension(newDimensions);
        }}
      />
      {showSecondFilter && (
        <FilterDimension
          prefixText="2nd&nbsp;dimension:"
          allOptions={getSecondDimensionOptions}
          selectedOption={selectedDimensions[1]}
          onChange={(value) => {
            const newDimensions: [string | null, string | null] = [selectedDimensions[0], value];

            onChangeDimension(newDimensions);
          }}
        />
      )}
      {(!showSecondFilter && (
        <Tooltip title body="Drill down data">
          {iconButton}
        </Tooltip>
      )) ||
        iconButton}
    </Flex>
  );
};
