import { useState } from 'react';

import { CreateAdvertiserForm } from './CreateAdvertiserForm';
import { CreateAgencyForm } from './CreateAgencyForm';
import { CreateAgencyPayload } from './agencySettingsTypes';

type ModalProps = {
  onClose: () => void;
};

export const CreateAgencyModal = ({ onClose }: ModalProps) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [agencyData, setAgencyData] = useState<CreateAgencyPayload>({ name: '', timeZone: '' });

  return (
    <div className="w-128 bg-white rounded-xl shadow  flex-col justify-start items-start inline-flex">
      {currentStep === 1 && (
        <CreateAgencyForm
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setAgencyData={setAgencyData}
        />
      )}
      {currentStep === 2 && (
        <CreateAdvertiserForm
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          onClose={onClose}
          agencyData={agencyData}
        />
      )}
    </div>
  );
};
