/* eslint-disable react-hooks/exhaustive-deps */
import { GridActionsCellItem, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { Edit } from 'feather-icons-react';
import { useEffect, useState } from 'react';
import { formatSortingParams } from 'utils';

import { Button, Grid, Modal, Spin } from 'components/common';

import { Advertiser } from 'modules/organization/organizationApiTypes';
import { useOrganization } from 'modules/organization/useOrganization';

import { CreateAdvertiserModal } from './CreateAdvertiserModal';
import { EditAdvertisersModal } from './EditAdvertisersModal';

export const AdvertisersSettings = () => {
  const { agencies, currentAgencyId, getAdvertisers, advertisers, isLoading } = useOrganization();
  const [isCreateAdvertiserModalOpen, setIsCreateAdvertiserModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState<Advertiser | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const openCreateAdvertiserModal = () => setIsCreateAdvertiserModalOpen(true);
  const closeCreateAdvertiserModal = () => setIsCreateAdvertiserModalOpen(false);

  const openEditModal = () => setIsEditModalOpen(true);
  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleEditClick = (data: Advertiser) => () => {
    setSelectedAdvertiser(data);
    openEditModal();
  };

  useEffect(() => {
    currentAgencyId &&
      getAdvertisers({
        agencyId: currentAgencyId,
        page: paginationModel.page,
        size: paginationModel.pageSize,
        ...formatSortingParams(sortModel),
      });
  }, [currentAgencyId, paginationModel, sortModel, agencies]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Advertisers',
      flex: 1,
      minWidth: 256,
      valueGetter: (value) => value || '-',
    },
    {
      field: 'creationDate',
      headerName: 'Date created',
      valueGetter: (value) => (value ? format(new Date(value), 'MMMM d, yyy') : '-'),
      flex: 2,
      minWidth: 256,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      sortable: false,
      cellClassName: 'actions',
      getActions: (data) => {
        return [
          <GridActionsCellItem
            icon={<Edit />}
            key={data.id}
            label="Edit"
            onClick={handleEditClick(data.row)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div className="w-full h-full bg-white rounded-xl border border-primary-gray-100 flex-col justify-start items-start flex">
      <div className="p-8 flex-col justify-start items-start inline-flex w-full">
        <div className="w-full flex-col justify-start items-start gap-4 flex">
          <div className="text-secondary-black text-heading-xl font-semibold leading-relaxed">
            Advertisers
          </div>
          <Button variant="primary" size="md" onClick={openCreateAdvertiserModal}>
            Create new advertiser
          </Button>
          <Spin spinning={isLoading}>
            <div className="grid w-full">
              {advertisers ? (
                <Grid
                  className="w-full"
                  columns={columns}
                  rows={advertisers?.data}
                  rowCount={advertisers.totalCount}
                  onPaginationModelChange={setPaginationModel}
                  paginationModel={paginationModel}
                  paginationMode="server"
                  sortingMode="server"
                  onSortModelChange={setSortModel}
                  sortModel={sortModel}
                />
              ) : null}
            </div>
          </Spin>
        </div>
      </div>
      <Modal
        open={isCreateAdvertiserModalOpen}
        footer={null}
        closable={false}
        onCancel={closeCreateAdvertiserModal}
        destroyOnClose
      >
        <CreateAdvertiserModal onClose={closeCreateAdvertiserModal} />
      </Modal>
      <Modal open={isEditModalOpen} footer={null} closable={false} onCancel={closeEditModal}>
        <EditAdvertisersModal onClose={closeEditModal} selectedAdvertiser={selectedAdvertiser} />
      </Modal>
    </div>
  );
};
