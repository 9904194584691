import { PagingRequestValues } from 'modules/organization/organizationApiTypes';

import { api } from 'services/api';

import {
  AUDIENCE_URL,
  INSERTION_ORDER_FULL_URL,
  INSERTION_ORDER_STATUS_URL_LIST,
  INSERTION_ORDER_SYNC_URL,
  INSERTION_ORDER_URL,
  LINE_ITEM_EDIT_URL,
  LINE_ITEM_SYNC_URL,
  LINE_ITEMS_URL,
  XANDR_CONNECTION_URL,
  XANDR_REFRESH_URL,
  XANDR_REMOVE_URL,
  XANDR_STATUS_URL,
} from './constants';
import {
  AudiencesPagingResponse,
  InsertionOrder,
  InsertionOrderEnableRequest,
  LineItem,
  LineItemsPagingResponse,
  XandrConnection,
  XandrStatus,
} from './xandrApiTypes';

const getXandrStatus = (advertiserId: string) => {
  return api.get<XandrStatus>(`${XANDR_STATUS_URL}?advertiserId=${advertiserId}`);
};

const removeXandrConnection = (advertiserId: string) => {
  return api.get<XandrConnection>(`${XANDR_REMOVE_URL}?advertiserId=${advertiserId}`);
};

const refreshXandrConnection = (advertiserId: string) => {
  return api.get<XandrConnection>(`${XANDR_REFRESH_URL}?advertiserId=${advertiserId}`);
};

const getXandrConnection = (advertiserId: string, xandrAdvertiserId: string) => {
  return api.get<XandrConnection>(
    `${XANDR_CONNECTION_URL}?advertiserId=${advertiserId}&xandrAdvertiserId=${xandrAdvertiserId}`,
  );
};

const editLineItem = (data: LineItem, advertiserId: string) => {
  return api.post<LineItem>(`${LINE_ITEM_EDIT_URL}?advertiserId=${advertiserId}`, data);
};

const syncLineItem = (id: number) => {
  return api.patch<LineItem>(`${LINE_ITEM_SYNC_URL}/${id}`);
};

const getInsertionOrders = (advertiserId: string) => {
  return api.get<InsertionOrder[]>(`${INSERTION_ORDER_FULL_URL}?advertiserId=${advertiserId}`);
};

const editInsertionOrder = (data: InsertionOrder, advertiserId: string) => {
  return api.post<InsertionOrder>(`${INSERTION_ORDER_URL}?advertiserId=${advertiserId}`, data);
};

const syncInsertionOrder = (id: number) => {
  return api.patch<InsertionOrder>(`${INSERTION_ORDER_SYNC_URL}/${id}`);
};

const syncAllInsertionOrders = (advertiserId: string) => {
  return api.get<InsertionOrder[]>(`${INSERTION_ORDER_SYNC_URL}?advertiserId=${advertiserId}`);
};

const enableInsertionOrder = (data: InsertionOrderEnableRequest) => {
  return api.patch<InsertionOrder[]>(
    `${INSERTION_ORDER_STATUS_URL_LIST}?id=${data.id}&enabled=${data.enabled}&advertiserId=${data.advertiserId}`,
  );
};

const getLineItems = (payload: PagingRequestValues) => {
  return api.get<LineItemsPagingResponse>(
    `${LINE_ITEMS_URL}?page=${payload.page}&size=${payload.size}&advertiserId=${payload.advertiserId}`,
  );
};

const getAudiences = (payload: PagingRequestValues) => {
  return api.get<AudiencesPagingResponse>(
    `${AUDIENCE_URL}?page=${payload.page}&size=${payload.size}&advertiserId=${payload.advertiserId}`,
  );
};

export const xandrApi = {
  getXandrStatus,
  removeXandrConnection,
  refreshXandrConnection,
  getXandrConnection,
  editLineItem,
  syncLineItem,
  getInsertionOrders,
  editInsertionOrder,
  syncInsertionOrder,
  syncAllInsertionOrders,
  enableInsertionOrder,
  getLineItems,
  getAudiences,
};
