/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

import { PagingRequestValues } from 'modules/organization/organizationApiTypes';

import {
  InsertionOrder,
  InsertionOrderEditRequest,
  InsertionOrderEnableRequest,
  LineItem,
  LineItemEditRequest,
  XandrConnection,
  XandrConnectionRequest,
  XandrState,
  XandrStatus,
} from './xandrApiTypes';

export const initialState: XandrState = {
  isLoading: false,
  connection: null,
  insertionOrders: null,
  status: null,
  error: null,
  editedLineItem: null,
  editedInsertionOrder: null,
  lineItems: null,
  audiences: null,
};

export const xandrSlice = createSlice({
  name: 'xandr',
  initialState,
  reducers: {
    getXandrConnectionStart(state: XandrState, action: PayloadAction<XandrConnectionRequest>) {
      state.error = null;
      state.isLoading = true;
    },
    getXandrConnectionSuccess(state: XandrState, action: PayloadAction<XandrConnection>) {
      state.isLoading = false;
      state.connection = action.payload;
      state.status = action.payload.xandrAdvertiser;
      state.error = null;
    },
    getXandrConnectionFailure(state: XandrState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.connection = null;
      state.isLoading = false;
    },
    getXandrStatusStart(state: XandrState, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    getXandrStatusSuccess(state: XandrState, action: PayloadAction<XandrStatus>) {
      state.isLoading = false;
      state.status = action.payload;
      state.error = null;
    },
    getXandrStatusFailure(state: XandrState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.connection = null;
      state.isLoading = false;
    },
    removeXandrConnectionStart(state: XandrState, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    removeXandrConnectionSuccess(state: XandrState, action: PayloadAction<XandrConnection>) {
      state.isLoading = false;
      state.connection = null;
      state.status = null;
      state.insertionOrders = null;
      state.error = null;
    },
    removeXandrConnectionFailure(state: XandrState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.connection = null;
      state.isLoading = false;
    },
    refreshXandrConnectionStart(state: XandrState, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    refreshXandrConnectionSuccess(state: XandrState, action: PayloadAction<XandrConnection>) {
      state.isLoading = false;
      state.connection = action.payload;
      state.status = action.payload.xandrAdvertiser;
      state.error = null;
    },
    refreshXandrConnectionFailure(state: XandrState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.connection = null;
      state.isLoading = false;
    },
    editLineItemStart(state: XandrState, action: PayloadAction<LineItemEditRequest>) {
      state.isLoading = true;
    },
    editLineItemSuccess(state: XandrState, action: PayloadAction<InsertionOrder[]>) {
      state.isLoading = false;
      state.insertionOrders = action.payload;
      state.error = null;
    },
    editLineItemFailure(state: XandrState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    syncLineItemStart(state: XandrState, action: PayloadAction<number>) {
      state.isLoading = true;
    },
    syncLineItemSuccess(state: XandrState, action: PayloadAction<LineItem>) {
      state.isLoading = false;
      state.editedLineItem = action.payload;
      state.error = null;
    },
    syncLineItemFailure(state: XandrState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getInsertionOrdersStart(state: XandrState, action: PayloadAction<string>) {
      state.isLoading = true;
      state.insertionOrders = null;
    },
    getInsertionOrdersSuccess(state: XandrState, action: PayloadAction<InsertionOrder[]>) {
      state.isLoading = false;
      state.insertionOrders = action.payload;
      state.error = null;
    },
    getInsertionOrdersFailure(state: XandrState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    editInsertionOrderStart(state: XandrState, action: PayloadAction<InsertionOrderEditRequest>) {
      state.isLoading = true;
    },
    editInsertionOrderSuccess(state: XandrState, action: PayloadAction<InsertionOrder[]>) {
      state.isLoading = false;
      state.insertionOrders = action.payload;
      state.error = null;
    },
    editInsertionOrderFailure(state: XandrState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    syncInsertionOrderStart(state: XandrState, action: PayloadAction<number>) {
      state.isLoading = true;
    },
    syncInsertionOrderSuccess(state: XandrState, action: PayloadAction<InsertionOrder>) {
      state.isLoading = false;
      state.editedInsertionOrder = action.payload;
      state.error = null;
    },
    syncInsertionOrderFailure(state: XandrState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    syncAllInsertionOrdersStart(state: XandrState, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    syncAllInsertionOrdersSuccess(state: XandrState, action: PayloadAction<InsertionOrder[]>) {
      state.isLoading = false;
      state.insertionOrders = action.payload;
      state.error = null;
    },
    syncAllInsertionOrdersFailure(state: XandrState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    enableInsertionOrderStart(
      state: XandrState,
      action: PayloadAction<InsertionOrderEnableRequest>,
    ) {
      state.isLoading = true;
    },
    enableInsertionOrderSuccess(state: XandrState, action: PayloadAction<InsertionOrder[]>) {
      state.isLoading = false;
      state.insertionOrders = action.payload;
      state.error = null;
    },
    enableInsertionOrderFailure(state: XandrState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getLineItemsStart(state: XandrState, action: PayloadAction<PagingRequestValues>) {
      state.isLoading = true;
    },
    getLineItemsSuccess(state: XandrState, action: PayloadAction<LineItem[]>) {
      state.isLoading = false;
      state.lineItems = action.payload;
      state.error = null;
    },
    getLineItemsFailure(state: XandrState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getAudiencesStart(state: XandrState, action: PayloadAction<PagingRequestValues>) {
      state.isLoading = true;
    },
    getAudiencesSuccess(state: XandrState, action: PayloadAction<string[]>) {
      state.isLoading = false;
      state.audiences = action.payload;
      state.error = null;
    },
    getAudiencesFailure(state: XandrState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const getXandrState = ({ xandr }: AppState) => xandr;
export const getIsXandrLoading = (state: AppState) => getXandrState(state).isLoading;
export const getError = (state: AppState) => getXandrState(state).error;

export const {
  getXandrConnectionStart,
  getXandrConnectionSuccess,
  getXandrConnectionFailure,
  getXandrStatusStart,
  getXandrStatusSuccess,
  getXandrStatusFailure,
  removeXandrConnectionStart,
  removeXandrConnectionSuccess,
  removeXandrConnectionFailure,
  refreshXandrConnectionStart,
  refreshXandrConnectionSuccess,
  refreshXandrConnectionFailure,
  getInsertionOrdersStart,
  getInsertionOrdersSuccess,
  getInsertionOrdersFailure,
  editLineItemStart,
  editLineItemSuccess,
  editLineItemFailure,
  syncLineItemStart,
  syncLineItemSuccess,
  syncLineItemFailure,
  editInsertionOrderStart,
  editInsertionOrderSuccess,
  editInsertionOrderFailure,
  syncInsertionOrderStart,
  syncInsertionOrderSuccess,
  syncInsertionOrderFailure,
  syncAllInsertionOrdersStart,
  syncAllInsertionOrdersSuccess,
  syncAllInsertionOrdersFailure,
  enableInsertionOrderStart,
  enableInsertionOrderSuccess,
  enableInsertionOrderFailure,
  getLineItemsFailure,
  getLineItemsStart,
  getLineItemsSuccess,
  getAudiencesFailure,
  getAudiencesStart,
  getAudiencesSuccess,
} = xandrSlice.actions;
export const xandr = xandrSlice.reducer;
