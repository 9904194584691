/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

import { Constants, DeviceTypes, MediaTypes, MiscState } from './miscApiTypes';

export const initialState: MiscState = {
  isLoading: false,
  error: null,
  constants: null,
  mediaTypes: null,
  deviceTypes: null,
};

export const miscSlice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
    getConstantsStart(state: MiscState) {
      state.isLoading = true;
    },
    getConstantsSuccess(state: MiscState, action: PayloadAction<Constants>) {
      state.isLoading = false;
      state.constants = action.payload;
      state.error = null;
    },
    getConstantsFailure(state: MiscState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getMediaTypesStart(state: MiscState) {
      state.isLoading = true;
    },
    getMediaTypesSuccess(state: MiscState, action: PayloadAction<MediaTypes>) {
      state.isLoading = false;
      state.mediaTypes = action.payload;
      state.error = null;
    },
    getMediaTypesFailure(state: MiscState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getDeviceTypesStart(state: MiscState) {
      state.isLoading = true;
    },
    getDeviceTypesSuccess(state: MiscState, action: PayloadAction<DeviceTypes>) {
      state.isLoading = false;
      state.deviceTypes = action.payload;
      state.error = null;
    },
    getDeviceTypesFailure(state: MiscState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const getMiscState = ({ misc }: AppState) => misc;
export const getIsMiscLoading = (state: AppState) => getMiscState(state).isLoading;
export const getError = (state: AppState) => getMiscState(state).error;
export const getConstants = (state: AppState) => getMiscState(state).constants;
export const getMetrics = (state: AppState) => getMiscState(state).constants?.Metrics;

export const {
  getConstantsStart,
  getConstantsSuccess,
  getConstantsFailure,
  getDeviceTypesFailure,
  getDeviceTypesStart,
  getDeviceTypesSuccess,
  getMediaTypesFailure,
  getMediaTypesStart,
  getMediaTypesSuccess,
} = miscSlice.actions;
export const misc = miscSlice.reducer;
