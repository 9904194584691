import classNames from 'classnames';
import { AlertCircle } from 'feather-icons-react';
import { tv } from 'tailwind-variants';

import { ctvMergeConfig } from 'services/twMerge/ctvTwMerge';

interface IndicatorProps extends React.HTMLAttributes<HTMLDivElement> {
  variant: 'success' | 'warning' | 'danger' | 'gray' | 'black';
  size?: 'sm' | 'md' | 'lg';
}

export const indicatorVariants = tv(
  {
    base: 'w-full min-h-10 px-3 py-1.5 rounded-lg justify-start items-center gap-1.5 inline-flex font-medium',
    variants: {
      color: {
        success: 'bg-system-success-75 text-system-success-500',
        warning: 'text-system-warning-500 bg-system-warning-75',
        danger: 'bg-system-danger-75 text-system-danger-500',
        gray: 'bg-primary-gray-75 text-primary-gray-500 ',
        black: 'bg-secondary-black text-secondary-white ',
      },
      size: {
        sm: 'min-h-8 text-base-sm',
        md: 'min-h-10 text-base-sm',
        lg: 'min-h-12 text-base-md',
      },
    },
    defaultVariants: {
      color: 'gray',
      size: 'md',
    },
  },
  {
    twMergeConfig: ctvMergeConfig,
  },
);

export const Indicator = ({ className, children, variant, size, ...rest }: IndicatorProps) => (
  <div className={classNames(indicatorVariants({ color: variant, size }), className)} {...rest}>
    <div className="w-5 h-5">
      <AlertCircle className="w-5 h-5" />
    </div>
    {children}
  </div>
);

Indicator.displayName = 'Indicator';
