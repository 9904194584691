import { tv } from 'tailwind-variants';

export const buttonVariants = tv({
  slots: {
    buttonBody: [
      'shadow-none',
      'rounded-full',
      'border-0',
      'justify-center',
      'items-center',
      'inline-flex',
      'leading-snug',
      'text-base-md',
      'px-5',
      'opacity-100',
    ],
    text: 'font-medium',
    icon: '',
  },
  variants: {
    color: {
      primary: {
        buttonBody: [
          'bg-gradient-to-b',
          'from-primary-electric-500',
          'to-primary-electric-700',
          'text-white',
          'shadow-inner',
          'hover:from-primary-electric-700',
          'hover:to-primary-electric-900',
          'disabled:bg-primary-electric-200/100',
          'disabled:bg-none',
          'disabled:text-primary-electric-100',
        ],
        text: '',
        icon: '',
      },
      secondary: {
        buttonBody: [
          'bg-primary-gray-50',
          'text-secondary-black',
          'hover:bg-primary-gray-100',
          'disabled:bg-primary-gray-25',
          'disabled:text-primary-gray-200',
        ],
        text: '',
        icon: '',
      },
      tertiary: {
        buttonBody: [
          'text-secondary-black',
          'hover:bg-primary-gray-50',
          'disabled:text-primary-gray-200',
          'disabled:bg-white',
        ],
        text: '',
        icon: '',
      },
      outline: {
        buttonBody: [
          'border',
          'border-secondary-black',
          'text-secondary-black',
          'hover:border-primary-electric-500',
          'hover:text-primary-electric-500',
          'disabled:text-primary-gray-200',
          'disabled:border-primary-gray-200',
        ],
        text: '',
        icon: '',
      },
      danger: {
        buttonBody: [
          'bg-system-danger-500',
          'text-white',
          'hover:bg-system-danger-700',
          'disabled:bg-system-danger-200',
          'disabled:text-system-danger-100',
        ],
        text: '',
        icon: '',
      },
      link: {
        buttonBody: [
          'bg-transparent',
          'disabled:text-primary-gray-200',
          'text-primary-electric-500',
          'hover:text-primary-electric-300',
          'underline',
          'px-0',
          'min-w-min',
        ],
        text: '',
        icon: '',
      },
    },
    size: {
      sm: {
        buttonBody: 'min-w-20 h-8',
        text: 'text-base-sm',
        icon: 'w-5 h-5',
      },
      md: {
        buttonBody: 'min-w-20 h-10',
        text: 'text-base-sm',
        icon: 'w-5 h-5',
      },
      lg: {
        buttonBody: 'min-w-20 h-12 py-3',
        text: 'text-base-md',
        icon: 'w-6 h-6 [&>*]:w-6 [&>*]:h-6',
      },
    },
  },
  compoundVariants: [],
  defaultVariants: {
    size: 'md',
    color: 'primary',
  },
});

export const iconButtonVariants = tv({
  extend: buttonVariants,
  variants: {
    size: {
      sm: {
        buttonBody: 'min-w-8 w-8 h-8 px-0',
        icon: 'w-5 h-5 [&>*]:w-5 [&>*]:h-5',
      },
      md: {
        buttonBody: 'min-w-10 w-10 h-10 px-0',
        icon: 'w-5 h-5 [&>*]:w-5 [&>*]:h-5',
      },
      lg: {
        buttonBody: 'min-w-12 w-12 h-12 py-3',
        icon: 'w-6 h-6 [&>*]:w-6 [&>*]:h-6',
      },
    },
  },
  defaultVariants: {
    size: 'md',
    color: 'primary',
  },
});
