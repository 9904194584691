import { Link } from 'react-router-dom';

import { Paragraph, Title } from 'components/common/typography';

export const NotFoundPage = () => {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="relative flex flex-col items-center">
        <Title>404</Title>
        <Title>Not Found Title</Title>
        <Paragraph>Contact Us</Paragraph>
        <Link to="/">Button to HomePage</Link>
      </div>
    </div>
  );
};
