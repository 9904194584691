import { SmileOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import { Calendar } from 'feather-icons-react';

import {
  Button,
  IconButton,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  Switch,
  Indicator,
  Tooltip,
  InfoTooltip,
  QuestionTooltip,
  Tabs,
  Chip,
  Wordmark,
  Progress,
  WordmarkIcon,
  Segmented,
} from 'components/common';
import { Input, PasswordInput, TextArea } from 'components/common/input/input';
import { Select, Option } from 'components/common/select';

function UIKit() {
  const checkboxOptions = [
    { label: 'Apple', value: 'Apple' },
    { label: 'Pear', value: 'Pear' },
    { label: 'Orange', value: 'Orange', disabled: true },
    { label: 'Banana', value: 'Banana', disabled: true },
  ];

  const tabItems = [
    {
      key: '1',
      label: 'Tab Item 1',
      children: 'Content of Tab Pane 1',
    },
    {
      key: '2',
      label: 'Tab Item 2',
      children: 'Content of Tab Pane 2',
    },
    {
      key: '3',
      label: 'Tab Item 3',
      children: 'Content of Tab Pane 3',
    },
    {
      key: '4',
      label: 'Tab Item Disabled',
      children: 'Content of Tab Pane 3',
      disabled: true,
    },
  ];

  return (
    <>
      <Flex gap="small">
        <Flex vertical gap="small">
          <h1>INPUTS</h1>
          <div
            style={{
              width: '300px',
              padding: '30px',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <Input placeholder="Enter..." />
            <Input placeholder="Enter..." value={'Filled text'} />
            <Input placeholder="Enter..." />
            <Input placeholder="Enter..." invalid />
            <Input placeholder="Enter..." disabled />
          </div>
          <div
            style={{
              width: '300px',
              padding: '30px',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <PasswordInput placeholder="Enter..." />
            <PasswordInput placeholder="Enter..." value={'password'} />
            <PasswordInput placeholder="Enter..." value={'password'} invalid />
          </div>
          <div
            style={{
              width: '300px',
              padding: '30px',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <TextArea placeholder="Enter..." />
            <TextArea placeholder="Enter..." value={'password'} />
            <TextArea placeholder="Enter..." value={'password'} invalid />
          </div>
        </Flex>
        <h1>BUTTONS</h1>
        <Flex vertical gap="small">
          <Flex vertical gap="small">
            <Flex wrap gap="small">
              <Button size="lg" variant="primary" Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="lg" variant="secondary" Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="lg" variant="tertiary" Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="lg" variant="outline" Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="lg" variant="danger" Icon={SmileOutlined}>
                Button Text
              </Button>
            </Flex>
            <Flex wrap gap="small">
              <Button size="lg" variant="primary" disabled Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="lg" variant="secondary" disabled Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="lg" variant="tertiary" disabled Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="lg" variant="outline" disabled Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="lg" variant="danger" disabled Icon={SmileOutlined}>
                Button Text
              </Button>
            </Flex>
            <Flex wrap gap="small">
              <Button size="lg" variant="primary" isLoading Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="lg" variant="secondary" isLoading Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="lg" variant="tertiary" isLoading Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="lg" variant="outline" isLoading Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="lg" variant="danger" isLoading Icon={SmileOutlined}>
                Button Text
              </Button>
            </Flex>

            <Flex wrap gap="small">
              <Button size="md" variant="primary" Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="md" variant="secondary" Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="md" variant="tertiary">
                Button Text
              </Button>
              <Button size="md" variant="outline" Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="md" variant="danger" Icon={SmileOutlined}>
                Button Text
              </Button>
            </Flex>
            <Flex wrap gap="small">
              <Button size="md" variant="primary" disabled Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="md" variant="secondary" disabled Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="md" variant="tertiary" disabled Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="md" variant="outline" disabled Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="md" variant="danger" disabled Icon={SmileOutlined}>
                Button Text
              </Button>
            </Flex>
            <Flex wrap gap="small">
              <Button size="md" variant="primary" isLoading Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="md" variant="secondary" isLoading Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="md" variant="tertiary" isLoading Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="md" variant="outline" isLoading Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="md" variant="danger" isLoading Icon={SmileOutlined}>
                Button Text
              </Button>
            </Flex>

            <Flex wrap gap="small">
              <Button size="sm" variant="primary" Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="sm" variant="secondary" Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="sm" variant="tertiary" Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="sm" variant="outline" Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="sm" variant="danger" Icon={SmileOutlined}>
                Button Text
              </Button>
            </Flex>
            <Flex wrap gap="small">
              <Button size="sm" variant="primary" disabled Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="sm" variant="secondary" disabled Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="sm" variant="tertiary" disabled Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="sm" variant="outline" disabled Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="sm" variant="danger" disabled Icon={SmileOutlined}>
                Button Text
              </Button>
            </Flex>
            <Flex wrap gap="small">
              <Button size="sm" variant="primary" isLoading Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="sm" variant="secondary" isLoading Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="sm" variant="tertiary" isLoading Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="sm" variant="outline" isLoading Icon={SmileOutlined}>
                Button Text
              </Button>
              <Button size="sm" variant="danger" isLoading>
                Button Text
              </Button>
            </Flex>
          </Flex>
          <div>Icon Buttons</div>
          <Flex vertical gap="small">
            <Flex wrap gap="small">
              <IconButton size="lg" variant="primary" Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="lg" variant="secondary" Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="lg" variant="tertiary" Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="lg" variant="outline" Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="lg" variant="danger" Icon={SmileOutlined}>
                Button Text
              </IconButton>
            </Flex>
            <Flex wrap gap="small">
              <IconButton size="lg" variant="primary" disabled Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="lg" variant="secondary" disabled Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="lg" variant="tertiary" disabled Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="lg" variant="outline" disabled Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="lg" variant="danger" disabled Icon={SmileOutlined}>
                Button Text
              </IconButton>
            </Flex>
          </Flex>
          <Flex vertical gap="small">
            <Flex wrap gap="small">
              <IconButton size="md" variant="primary" Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="md" variant="secondary" Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="md" variant="tertiary" Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="md" variant="outline" Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="md" variant="danger" Icon={SmileOutlined}>
                Button Text
              </IconButton>
            </Flex>
            <Flex wrap gap="small">
              <IconButton size="md" variant="primary" disabled Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="md" variant="secondary" disabled Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="md" variant="tertiary" disabled Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="md" variant="outline" disabled Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="md" variant="danger" disabled Icon={SmileOutlined}>
                Button Text
              </IconButton>
            </Flex>
          </Flex>
          <Flex vertical gap="small">
            <Flex wrap gap="small">
              <IconButton size="sm" variant="primary" Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="sm" variant="secondary" Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="sm" variant="tertiary" Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="sm" variant="outline" Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="sm" variant="danger" Icon={SmileOutlined}>
                Button Text
              </IconButton>
            </Flex>
            <Flex wrap gap="small">
              <IconButton size="sm" variant="primary" disabled Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="sm" variant="secondary" disabled Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="sm" variant="tertiary" disabled Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="sm" variant="outline" disabled Icon={SmileOutlined}>
                Button Text
              </IconButton>
              <IconButton size="sm" variant="danger" disabled Icon={SmileOutlined}>
                Button Text
              </IconButton>
            </Flex>
          </Flex>
          <h1>TABS</h1>
          <Tabs items={tabItems} onChange={() => {}} />
          <Flex vertical gap="small" className="p-2 bg-white">
            <h1>SEGMENTED</h1>
            <Segmented options={['Option 1', 'Option 2', 'Option 3']} onChange={(value) => value} />
          </Flex>
        </Flex>
        <Flex vertical gap="small">
          <h1>RADIO</h1>
          <RadioGroup name="radiogroup" defaultValue={1}>
            <Radio value={1}></Radio>
            <Radio value={2}>text</Radio>
            <Radio value={3} disabled>
              disabled
            </Radio>
            <Radio value={4}>
              <span className="font-bold">text bold</span>
            </Radio>
          </RadioGroup>
          <h1>CHECKBOX</h1>
          <CheckboxGroup
            name="radiogroup"
            defaultValue={['Apple', 'Banana']}
            onChange={() => {}}
            options={checkboxOptions}
          />
          <Checkbox onChange={() => {}}>lonesome checkbox without a group</Checkbox>
          <h1>SWITCH</h1>
          <Switch onChange={() => {}}></Switch>
          <Switch onChange={() => {}}>Regular</Switch>
          <Switch onChange={() => {}} disabled>
            <span className="font-bold">Bold Disabled</span>
          </Switch>
          <Switch onChange={() => {}} disabled defaultChecked>
            <span className="font-bold">Bold Disabled Checked</span>
          </Switch>
          <h1>INDICATOR</h1>
          <Indicator variant="success">some text</Indicator>
          <Indicator variant="warning">some text</Indicator>
          <Indicator variant="danger">some text</Indicator>
          <Indicator variant="gray">some text</Indicator>
          <Indicator variant="black">some text</Indicator>
          <h1>TOOLTIP</h1>
          <Tooltip title="TItle" body="lorem ipsum loresdfg sdfs sdf d d fsdfsdfum ">
            <div className="w-fit">text</div>
          </Tooltip>
          <Tooltip
            title="TItle"
            body="This is a tool tip. This should usually appear when a user hovers over some type of icon or text. Try to keep this limited to a few sentences if possible."
            placement="bottom"
          >
            <div className="w-fit">down</div>
          </Tooltip>
          <InfoTooltip title="TItle" />
          <QuestionTooltip title="question" />
          <h1>CHIPS</h1>
          <Chip onClose={() => {}}>Campaign is ‘BVOD Campaign 2024’</Chip>
          <Chip onClose={() => {}} active>
            Campaign is ‘BVOD Campaign 2024’
          </Chip>
        </Flex>
        <Flex vertical gap="small">
          <h1>SELECT (DROPDOWN)</h1>
          <div
            style={{
              width: '300px',
              padding: '30px',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <Select placeholder="Select...">
              <Option value="jack">Jack</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="disabled" disabled>
                Disabled
              </Option>
              <Option value="Piter">Piter</Option>
            </Select>
            <Select disabled placeholder="Select..."></Select>
            <Select placeholder="Select..." value="jack">
              <Option value="jack">Jack</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="disabled" disabled>
                Disabled
              </Option>
              <Option value="Piter">Piter</Option>
            </Select>
            <Select disabled placeholder="Select..." value="jack">
              <Option value="jack">Jack</Option>
            </Select>
            <Select placeholder="Select..." prefixText="Label:">
              <Option value="jack">Jack</Option>
              <Option value="lucy">Lucy</Option>
            </Select>
            <Select
              placeholder="Select..."
              prefixIcon={<Calendar size="22" strokeWidth="1.5" stroke="#8c8e92" />}
            >
              <Option value="jack">Jack</Option>
              <Option value="lucy">Lucy</Option>
            </Select>
            <h1>PROGRESS</h1>
            <Progress currentStep={2} steps={4}></Progress>
            <Progress currentStep={1} steps={4}></Progress>
            <Progress currentStep={4} steps={4}></Progress>
            <h1>WORDMARK</h1>
            <Wordmark variant="solid-dark"></Wordmark>
            <WordmarkIcon variant="gradient-dark" />
          </div>
        </Flex>
      </Flex>
    </>
  );
}

export default UIKit;
