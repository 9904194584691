import { api } from '../api';
import { FileType, UploadResponse } from './types';

export const FILE_URL = 'file';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function uploadFile(file: any, type: FileType, targetId: string) {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('type', type);
  formData.append('targetId', targetId);

  const response = await uploadToBucket(formData);

  return response;
}

export async function deleteFile(type: FileType, targetId: string) {
  const formData = new FormData();

  formData.append('targetId', targetId);
  formData.append('type', type);

  await deleteFromBucket(formData);
}

const uploadToBucket = async (data: FormData): Promise<UploadResponse> => {
  return api.post(FILE_URL, data);
};

const deleteFromBucket = async (data: FormData) => {
  return api.delete(FILE_URL, { data });
};
