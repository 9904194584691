import { Image, MenuProps } from 'antd';
import React, { memo, ReactNode, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as IconCodeSnippet } from 'assets/images/icons/codeSnippet.svg';
import { ReactComponent as IconFile } from 'assets/images/icons/file.svg';
import { ReactComponent as IconChartUp } from 'assets/images/icons/lineChartUp.svg';
import { ReactComponent as IconSettings } from 'assets/images/icons/settings.svg';

import { Menu, Sider, Wordmark, WordmarkIcon } from 'components/common';

import { useOrganization } from 'modules/organization/useOrganization';

import { Route, ROUTES } from 'routing/constants';

export enum TabKeys {
  DASHBOARD = 'Dashboard',
  REPORT_BUILDER = 'Report builder',
  PIXEL = 'Pixel',
  SETTINGS = 'Settings',
}

export interface NavItem {
  iconName: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  label: string;
  key?: TabKeys;
  linkTo?: Route;
  href?: string;
}

export type MenuItem = Required<MenuProps>['items'][number];

const navItems: NavItem[] = [
  {
    iconName: IconChartUp,
    label: 'Dashboard',
    key: TabKeys.DASHBOARD,
    linkTo: ROUTES.HOME,
  },
  {
    iconName: IconFile,
    label: 'COOL Reports',
    key: TabKeys.REPORT_BUILDER,
    linkTo: ROUTES.REPORTS,
  },
  { iconName: IconCodeSnippet, label: 'Pixel', key: TabKeys.PIXEL, linkTo: ROUTES.PIXEL_CODE },
  {
    iconName: IconSettings,
    label: 'Settings',
    key: TabKeys.SETTINGS,
    linkTo: ROUTES.AGENCY_SETTINGS,
  },
];

export const createNavItem = (
  { iconName, label, linkTo, key, href }: NavItem,
  index: number,
): MenuItem => ({
  key: `${key || String(index)}${href ? '-external' : ''}`,
  icon: React.createElement(iconName),
  label: linkTo ? (
    <Link to={linkTo} className="text-base-sm">
      {label}
    </Link>
  ) : href ? (
    <a href={href} target="_blank" className="text-base-sm" rel="noreferrer">
      {label}
    </a>
  ) : (
    label
  ),
});

export type SidebarProps = {
  activeSidebarTab: TabKeys;
  hasLimitedAccess: boolean;
  onChangeMenu: (menuKey: TabKeys) => void;
  className?: string;
};

const addRouteLink = (route: Route, content: ReactNode) => <Link to={route}>{content}</Link>;

const logoIcons = {
  base: addRouteLink(ROUTES.HOME, <Wordmark variant="gradient-dark" className="w-40 h-10 px-6" />),
  small: addRouteLink(
    ROUTES.HOME,
    <WordmarkIcon variant="gradient-dark" className="w-10 h-10 m-auto" />,
  ),
};

export const Sidebar = memo(
  ({ activeSidebarTab, hasLimitedAccess, onChangeMenu }: SidebarProps) => {
    const [collapsed, setCollapsed] = useState(true);

    const { currentAgency, wordMarkUrl, logoUrl } = useOrganization();

    const configureNavTabsBasedOnRole = useMemo(() => {
      if (hasLimitedAccess) {
        const settingsTab = navItems.find((item) => item?.key === TabKeys.SETTINGS);

        if (settingsTab) {
          settingsTab.linkTo = ROUTES.USER_SETTINGS;
        }

        return navItems.filter((i) => i.key !== TabKeys.PIXEL);
      }

      return navItems;
    }, [hasLimitedAccess]);

    const navItemsConfig: MenuItem[] = configureNavTabsBasedOnRole.map(createNavItem);

    const getLogoIcons = useMemo(() => {
      return currentAgency.enabledCustomLogo
        ? {
            base: (
              <div className="pl-6">
                {addRouteLink(
                  ROUTES.HOME,
                  <Image
                    src={wordMarkUrl}
                    preview={false}
                    className="object-cover"
                    width="152px"
                    height="40px"
                    alt="logo"
                  />,
                )}
              </div>
            ),
            small: (
              <div className="flex justify-center">
                {addRouteLink(
                  ROUTES.HOME,
                  <Image
                    src={logoUrl}
                    preview={false}
                    className="object-cover"
                    width="40px"
                    height="40px"
                    alt="logo"
                  />,
                )}
              </div>
            ),
          }
        : logoIcons;
    }, [currentAgency.enabledCustomLogo, wordMarkUrl, logoUrl]);

    return (
      <Sider
        className="min-h-screen h-full bg-white py-5 border-r border-solid border-primary-gray-100"
        width="256px"
        collapsed={collapsed}
        onMouseEnter={() => setCollapsed(false)}
        onMouseLeave={() => setCollapsed(true)}
      >
        {collapsed ? getLogoIcons.small : getLogoIcons.base}
        <Menu
          mode="vertical"
          className="sidebar-menu"
          items={navItemsConfig}
          selectedKeys={[activeSidebarTab]}
          onClick={(e) => onChangeMenu(e.key as TabKeys)}
        />
      </Sider>
    );
  },
);

Sidebar.displayName = 'Sidebar';
