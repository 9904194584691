import { Flex, Layout } from 'antd';
import { useState } from 'react';

import { HaloEffectFilter } from 'components/HaloEffect/HaloEffectFilter';
import { HaloEffectSidebar } from 'components/HaloEffect/HaloEffectSidebar';
import { HaloEffectTable } from 'components/HaloEffect/HaloEffectTable';
import { Button } from 'components/common';

import { usePerformance } from 'modules/performance/usePerformance';

export const HaloEffect = ({ openGlobalFilter }: { openGlobalFilter: () => void }) => {
  const { haloFilter, pickHaloFilter } = usePerformance();

  const handleFilterSelect = (filter: string) => {
    pickHaloFilter(filter);
  };

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>();

  return (
    <>
      <Flex gap="small" align="center" justify="space-between" className="h-[52px] pl-6 pr-3 pt-3 ">
        <Flex gap="large">
          <div className="justify-start items-center gap-1.5 flex">
            <div className="w-2 h-2 bg-primary-electric-500 rounded-full" />
            <div className="text-base-sm font-medium leading-tight">Exposed to campaign</div>
          </div>
          <div className="justify-start items-center gap-1.5 flex">
            <div className="w-2 h-2 bg-primary-gray-200 rounded-full" />
            <div className="text-base-sm font-medium leading-tight">Not exposed to campaign</div>
          </div>
        </Flex>
        <Flex gap="small">
          <HaloEffectFilter onChange={handleFilterSelect} haloFilter={haloFilter} />
          <Button size="md" className="ml-6 " onClick={openGlobalFilter}>
            Choose new campaign
          </Button>
        </Flex>
      </Flex>
      <Layout className="p-4 bg-white">
        <Layout className="bg-white">
          <HaloEffectTable isSidebarCollapsed={isSidebarCollapsed} />
        </Layout>
        <HaloEffectSidebar onCollapse={setIsSidebarCollapsed} />
      </Layout>
    </>
  );
};
