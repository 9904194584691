/* eslint-disable react-hooks/exhaustive-deps */
import { MenuProps } from 'antd';
import { Check, ChevronDown, ChevronUp } from 'feather-icons-react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ReactComponent as IconSearch } from 'assets/images/icons/search-sm.svg';

import { Dropdown, Button, Input } from 'components/common';

import { Agency, defaultAgencyState } from 'modules/organization/organizationApiTypes';
import { useOrganization } from 'modules/organization/useOrganization';

interface CustomMenuItem {
  key: string;
  label: string;
}

interface CustomMenuGroup {
  key: string;
  label: string;
  children: CustomMenuItem[];
}

export const AccountMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState<string>('');

  const { getAgencies, setCurrentAdvertiserId, currentAdvertiserId, agencies, currentAgency } =
    useOrganization();

  useEffect(() => {
    if (agencies[0] === defaultAgencyState) {
      getAgencies();
    }
  }, [agencies]);

  useEffect(() => {
    if (currentAgency.name) {
      document.title = `COOL Media - ${currentAgency.name}`;
    }
  }, [currentAgency]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleOptionSelect: MenuProps['onClick'] = ({ key }) => {
    if (!key.includes('group')) {
      setCurrentAdvertiserId(key);
      setIsOpen(false);
    }
  };

  const handleMenuOpen = (menuOpen: boolean) => {
    if (!menuOpen) {
      setSearch('');
    }

    setIsOpen(menuOpen);
  };

  const findSelectedLabel = useCallback(
    (key: string | null): string | undefined => {
      if (!key) return '';

      for (const agency of agencies || []) {
        const foundAdvertiser = agency.advertisers.find((advertiser) => advertiser.id === key);

        if (foundAdvertiser) {
          return foundAdvertiser.name;
        }
      }
    },
    [agencies],
  );

  const convertToOption = useCallback((agencies: Agency[]): CustomMenuGroup[] => {
    return agencies
      .filter((agency) => agency.advertisers && agency.advertisers.length > 0)
      .map((agency) => ({
        key: agency.id,
        type: 'group',
        label: agency.name,
        children: agency.advertisers.map((advertiser) => ({
          key: advertiser.id,
          label: advertiser.name,
        })),
      }));
  }, []);

  const filterOptions = useCallback(
    (options: CustomMenuGroup[]) => {
      if (!search) {
        return options;
      }

      return options
        .map((group) => {
          const groupMatches = group.label.toLowerCase().includes(search.toLowerCase());

          const filteredChildren = group.children.filter(
            (child) => groupMatches || child.label.toLowerCase().includes(search.toLowerCase()),
          );

          if (filteredChildren.length > 0) {
            return {
              ...group,
              children: filteredChildren,
            };
          }

          return null;
        })
        .filter((group): group is CustomMenuGroup => group !== null);
    },
    [search],
  );

  const createLabelWithIcon = (child: CustomMenuItem) => ({
    ...child,
    label: (
      <>
        {child.label}
        <Check size="20" className="ml-2" />
      </>
    ),
  });

  const addIconToOption = useCallback(
    (options: CustomMenuGroup[]) => {
      return options.map((group) => {
        const children = group.children.map((child) => {
          return child.key === currentAdvertiserId ? createLabelWithIcon(child) : child;
        });

        return {
          ...group,
          children,
        };
      });
    },
    [currentAdvertiserId],
  );

  const convertedOptions = useMemo(
    () => convertToOption(agencies || []),
    [agencies, convertToOption],
  );
  const filteredOptions = filterOptions(convertedOptions);
  const displayedOptions = addIconToOption(filteredOptions);

  const menu: MenuProps = {
    items: [
      ...(displayedOptions.length!
        ? displayedOptions
        : [
            {
              key: 'placeholder',
              disabled: true,
              label: <span className="placeholder-item">No items available.</span>,
            },
          ]),
    ],
    onClick: handleOptionSelect,
  };

  return agencies ? (
    <Dropdown
      trigger={['click']}
      onOpenChange={(menuOpen) => handleMenuOpen(menuOpen)}
      open={isOpen}
      menu={menu}
      dropdownRender={(menu) => (
        <div className="ctv-account-menu-dropdown w-64 max-h-[454px] bg-white rounded-lg shadow">
          <div className="search-container w-64 h-16 p-3 bg-primary-gray-50 border border-primary-gray-100 rounded-t-[8px] ">
            <Input
              placeholder="Search..."
              prefix={<IconSearch />}
              value={search}
              onChange={handleSearchChange}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          {menu}
        </div>
      )}
    >
      <div>
        <Button
          variant="tertiary"
          className="ctv-user-account-button w-[193px] h-10 px-5 justify-center items-center gap-1.5 inline-flex"
        >
          <div className="flex items-center gap-1.5 text-sm font-medium">
            <span className="max-w-[152px] overflow-hidden whitespace-nowrap text-ellipsis text-base-sm font-medium">
              {findSelectedLabel(currentAdvertiserId)}
            </span>
            {isOpen ? (
              <ChevronUp size="20" strokeWidth="1.5" />
            ) : (
              <ChevronDown size="20" strokeWidth="1.5" />
            )}
          </div>
        </Button>
      </div>
    </Dropdown>
  ) : null;
};
