import Root from 'Root';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AdvertisersSettings } from 'components/AdvertisersSettings';
import { AgencySettings } from 'components/AgencySettings';
import { MembersSettings } from 'components/MembersSettings';
import { PixelCode } from 'components/PixelCode';
import { PixelVerification } from 'components/PixelVerification';
import { Reports } from 'components/Reports';
import { UserSettings } from 'components/UserSettings';
import { XandrSettings } from 'components/XandrSettings';
import { CreateNewPasswordPage } from 'components/pages/CreateNewPasswordPage';
import { ForgotPasswordPage } from 'components/pages/ForgotPasswordPage';
import { HomePage } from 'components/pages/HomePage';
import { LoginPage } from 'components/pages/LoginPage';
import { NotFoundPage } from 'components/pages/NotFoundPage';
import PerformanceDashboardPage from 'components/pages/PerformanceDashboardPage';
import { SignupPage } from 'components/pages/SignupPage';
import UIKit from 'components/pages/UIKit';

import { ProtectedLayout } from './ProtectedLayout';
import { ROUTES } from './constants';

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Root>
        <Routes>
          <Route path={ROUTES.LOGIN} element={<LoginPage />} />
          <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
          <Route path={ROUTES.CREATE_NEW_PASSWORD} element={<CreateNewPasswordPage />} />
          <Route path={ROUTES.SIGNUP} element={<SignupPage />} />
          <Route
            index
            element={
              <ProtectedLayout>
                <HomePage />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.UIKIT}
            element={
              <ProtectedLayout>
                <UIKit />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.PERFORMANCE_DASHBOARD}
            element={
              <ProtectedLayout>
                <PerformanceDashboardPage />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.USER_SETTINGS}
            element={
              <ProtectedLayout>
                <UserSettings />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.MEMBERS_SETTINGS}
            element={
              <ProtectedLayout>
                <MembersSettings />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.ADVERTISER_SETTINGS}
            element={
              <ProtectedLayout>
                <AdvertisersSettings />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.AGENCY_SETTINGS}
            element={
              <ProtectedLayout>
                <AgencySettings />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.XANDR_SETTINGS}
            element={
              <ProtectedLayout>
                <XandrSettings />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.PIXEL_CODE}
            element={
              <ProtectedLayout>
                <PixelCode />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.PIXEL_VERIFICATION}
            element={
              <ProtectedLayout>
                <PixelVerification />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.REPORTS}
            element={
              <ProtectedLayout>
                <Reports />
              </ProtectedLayout>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Root>
    </BrowserRouter>
  );
};
