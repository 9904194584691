import { useState } from 'react';

import { Tabs } from 'components/common';

import { EventsTable } from './EventsTable';
import { PixelTableProps } from './PixelVerificationTypes';
import { TimeStampTable } from './TimeStampTable';

const createTab = (label: string) => <div className="flex gap-1">{label}</div>;

const tabElements = [createTab('Event overview'), createTab('Time stamp')];

export const PixelTable = ({
  events,
  categories,
  setEventsPageSize,
  eventsPageSize,
  eventsPage,
  setEventsPage,
}: PixelTableProps) => {
  const [activeTab, setActiveTab] = useState<string>('0');

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  const getTabContent = (tabKey: number) =>
    tabKey === 0 ? (
      <EventsTable data={categories} />
    ) : (
      <TimeStampTable
        events={events}
        setPageSize={setEventsPageSize}
        pageSize={eventsPageSize}
        page={eventsPage}
        setPage={setEventsPage}
      />
    );

  const tabsConfig = tabElements.map((tab, index) => ({
    key: index.toString(),
    label: tab,
    children: getTabContent(index),
  }));

  return (events && events?.data.length) || (categories && categories.length) ? (
    <div className="w-full flex-col justify-start items-start inline-flex border rounded-xl border-primary-gray-100">
      <div className="self-stretch  justify-start items-center inline-flex ">
        <Tabs items={tabsConfig} activeKey={activeTab} onChange={onTabChange} className="w-full" />
      </div>
    </div>
  ) : null;
};
