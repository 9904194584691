/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';

import { FilterDimension } from 'components/FilterDimension';
import { Dimensions, Metrics } from 'components/Incrementality/incrementalityTypes';

export const HaloEffectFilter = ({
  haloFilter,
  onChange,
}: {
  haloFilter: string;
  onChange: (data: string) => void;
}) => {
  const getOptions = useCallback(
    (items: typeof Dimensions | typeof Metrics) =>
      Object.entries(items).map(([key, { label }]) => ({
        label,
        value: key,
      })),
    [],
  );

  return (
    <FilterDimension
      prefixText="Metric:"
      allOptions={getOptions(Metrics)}
      selectedOption={haloFilter}
      onChange={(value) => onChange(value)}
    />
  );
};
