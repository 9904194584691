import { Table as TableAntd } from 'antd';
import { TableProps as TablePropsAntd } from 'antd/lib';
import classNames from 'classnames';

import { ctvTwMerge } from 'services/twMerge/ctvTwMerge';

export const Table = ({ columns, dataSource, className, ...rest }: TablePropsAntd) => (
  <TableAntd
    columns={columns}
    dataSource={dataSource}
    className={ctvTwMerge(classNames('ctv-table'), className)}
    {...rest}
  />
);
