import { Select, Option } from 'components/common/select';

import { FilterSelectProps, OptionType } from './PixelVerificationTypes';
import { ALL_OPTION } from './utils';

export const FilterSelect = <T extends OptionType>({
  onChange,
  value,
  options,
  prefix,
  getOptionValue,
}: FilterSelectProps<T>) => (
  <Select className="min-w-40 xl:w-64" onChange={onChange} value={value}>
    <Option value={ALL_OPTION}>
      <div className="justify-start items-center gap-1.5 flex">
        <div className="text-primary-gray-500 text-base-xs font-medium leading-none">{prefix}</div>
        <div className="text-secondary-black text-base-sm font-normal leading-tight">All</div>
      </div>
    </Option>
    {options?.map((option, i) => (
      <Option key={`categories-${i}`} value={getOptionValue(option)}>
        <div className="justify-start items-center gap-1.5 flex">
          <div className="text-primary-gray-500 text-base-xs font-medium leading-none hidden xl:block">
            {prefix}
          </div>
          <div className="text-secondary-black text-base-sm font-normal leading-tight">
            {getOptionValue(option)}
          </div>
        </div>
      </Option>
    ))}
  </Select>
);
