import { Switch as SwitchAntd, SwitchProps as SwitchPropsAntd, Flex } from 'antd';
import classNames from 'classnames';
import { ReactNode, memo, useCallback } from 'react';

import { ctvTwMerge } from 'services/twMerge/ctvTwMerge';

interface SwitchProps extends SwitchPropsAntd {
  children?: ReactNode;
  onChange: (value: boolean) => void;
}

export const Switch = memo(
  ({
    children,
    className,
    onChange,
    disabled,
    ...rest
  }: Omit<SwitchPropsAntd, 'onChange'> & SwitchProps) => {
    const handleChange = useCallback((value: boolean) => onChange(value), [onChange]);

    return (
      <Flex gap="small" align="center">
        <SwitchAntd
          className={ctvTwMerge(classNames('ctv-switch'), className)}
          onChange={handleChange}
          disabled={disabled}
          {...rest}
        />
        <label className={ctvTwMerge(classNames({ 'text-primary-gray-200': disabled }))}>
          {children}
        </label>
      </Flex>
    );
  },
);

Switch.displayName = 'Switch';
