import classNames from 'classnames';
import { memo } from 'react';

import { Button } from './Button';
import { ButtonProps } from './types';

export const LinkButton = memo(
  ({
    children,
    disabled,
    isLoading,
    size,
    className,
    ...rest
  }: Omit<ButtonProps, 'type' | 'variant' | 'Icon'>) => (
    <Button
      className={classNames('min-w-min', className)}
      variant="link"
      disabled={disabled}
      size={size}
      isLoading={isLoading}
      {...rest}
    >
      {children}
    </Button>
  ),
);

LinkButton.displayName = 'LinkButton';
