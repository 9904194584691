import { Input as InputAntd, InputRef, InputNumber as InputNumberAntd } from 'antd';
import { InputNumberProps as InputNumberAntdProps } from 'antd/lib';
import {
  InputProps as InputAntdProps,
  PasswordProps as PasswordAntdProps,
  TextAreaProps as TextAreaAntdProps,
} from 'antd/lib/input';
import classNames from 'classnames';
import { Eye, EyeOff } from 'feather-icons-react';
import { forwardRef, ReactElement } from 'react';

import { ctvTwMerge } from 'services/twMerge/ctvTwMerge';

type BaseInputProps = {
  invalid?: boolean;
};

type TextAreaProps = TextAreaAntdProps & BaseInputProps;

interface InputProps extends BaseInputProps, Omit<InputAntdProps, 'addonAfter'> {
  large?: boolean;
}

type LabelProps = {
  text: string;
  className?: string;
  children: ReactElement;
};

export const Input = forwardRef<InputRef, InputProps>(
  ({ className, invalid, large, ...rest }: InputProps, ref) => (
    <InputAntd
      ref={ref}
      className={ctvTwMerge(
        classNames('ctv-input', {
          'ctv-input__invalid': invalid,
          'ctv-input__large': large,
        }),
        className,
      )}
      {...rest}
    />
  ),
);
Input.displayName = 'Input';

export const PasswordInput = forwardRef<InputRef, PasswordAntdProps & InputProps>(
  ({ className, invalid, large, ...rest }: PasswordAntdProps & InputProps, ref) => (
    <InputAntd.Password
      iconRender={(visible) =>
        visible ? (
          <Eye size="14" stroke="#8c8e92" strokeWidth="1.5" />
        ) : (
          <EyeOff size="14" stroke="#8c8e92" strokeWidth="1.5" />
        )
      }
      className={ctvTwMerge(
        classNames('ctv-input', {
          'ctv-input__invalid': invalid,
          'ctv-input__large': large,
        }),
        className,
      )}
      {...rest}
      ref={ref}
    />
  ),
);
PasswordInput.displayName = 'PasswordInput';

export const TextArea = forwardRef<InputRef, TextAreaProps>(
  ({ className, invalid, ...rest }: TextAreaProps, ref) => {
    return (
      <InputAntd.TextArea
        className={ctvTwMerge(
          classNames('ctv-input min-h-[140px]', {
            'ctv-input__invalid': invalid,
          }),
          className,
        )}
        {...rest}
        ref={ref}
        autoSize
      />
    );
  },
);
TextArea.displayName = 'TextArea';

export const Label = ({ text, className, children }: LabelProps) => (
  <label className={classNames('text-secondary-black text-base-sm font-medium w-full', className)}>
    {text}
    {children}
  </label>
);

Label.displayName = 'Label';

interface InputNumberProps extends BaseInputProps, InputNumberAntdProps {
  large?: boolean;
}

export const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
  ({ className, invalid, large, ...rest }: InputNumberProps, ref) => (
    <InputNumberAntd
      className={ctvTwMerge(
        classNames('ctv-input', {
          'ctv-input__invalid': invalid,
          'ctv-input__large': large,
        }),
        className,
      )}
      {...rest}
      ref={ref}
    />
  ),
);

InputNumber.displayName = 'InputNumber';
