import { GridPagination as DataGridPagination, useGridApiContext } from '@mui/x-data-grid';
import * as React from 'react';

import { Grid } from 'components/common/grid';
import { Option, Select } from 'components/common/select';

export const GridPagination = () => {
  const apiRef = useGridApiContext();
  const { state, setPageSize } = apiRef.current;

  const paginationOptions = [10, 25, 50, 100];
  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
  };

  return (
    <>
      <div>
        <Select
          value={`Show ${state.pagination.paginationModel.pageSize} rows`}
          className="pl-6"
          onChange={handlePageSizeChange}
        >
          {paginationOptions.map((size) => (
            <Option key={size} value={size}>
              {size}
            </Option>
          ))}
        </Select>
      </div>
      <DataGridPagination />
    </>
  );
};

Grid.displayName = 'GridPagination';
