/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';

import { updateUserpicStart } from 'modules/auth/authSlice';
import { authStorage } from 'modules/auth/authStorage';

import { deleteFile, uploadFile } from 'services/file/fileService';
import { FileType } from 'services/file/types';

import { UpdateMemberRequestValues, UserInviteRequestValues } from './userApiTypes';
import {
  deleteUserStart,
  getMembersStart,
  getUserState,
  inviteUserStart,
  updateMemberStart,
} from './userSlice';

export const useUser = () => {
  const dispatch = useDispatch();
  const state = useSelector(getUserState);

  function inviteUser({
    agencyId,
    emails,
    role,
    advertiserId,
    campaignIds,
  }: UserInviteRequestValues) {
    dispatch(inviteUserStart({ agencyId, emails, role, advertiserId, campaignIds }));
  }

  function updateMember(payload: UpdateMemberRequestValues) {
    dispatch(updateMemberStart(payload));
  }

  function deleteUser(subId: string) {
    dispatch(deleteUserStart(subId));
  }

  function getMembers(agencyId: string) {
    dispatch(getMembersStart({ page: 0, size: 10000, agencyId: agencyId }));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function uploadUserpic(file: any) {
    const user = await authStorage.getUser();

    const response = await uploadFile(file, FileType.PROFILE, user?.id || '');

    dispatch(updateUserpicStart(response.url));
  }

  async function deleteUserpic() {
    const user = await authStorage.getUser();

    await deleteFile(FileType.PROFILE, user?.id || '');
    dispatch(updateUserpicStart(''));
  }

  const isInvited = state.isInvited;
  const members = state.members;
  const errorText = state.error;

  return {
    getMembers,
    uploadUserpic,
    deleteUserpic,
    inviteUser,
    deleteUser,
    updateMember,
    members,
    isInvited,
    errorText,
  };
};
