/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { formatDate } from 'utils';

import { DateRangePicker, DateRangeValue } from 'components/common';
import { paginationOptions } from 'components/constants';

import { useOrganization } from 'modules/organization/useOrganization';
import { PixelEventCategory, PixelEventSubcategory } from 'modules/pixel/pixelApiTypes';
import { usePixel } from 'modules/pixel/usePixel';

import { FilterSelect } from './FilterSelect';
import { PixelChart } from './PixelChart';
import { PixelTable } from './PixelTable';
import { PixelWidget } from './PixelWidget';
import { ALL_OPTION, createBasePayload, getSubcategories } from './utils';

export const PixelVerification = () => {
  const { currentAdvertiserId } = useOrganization();
  const {
    getPixelEvents,
    getPixelCategories,
    getPixelWidget,
    getPixelDailyEvents,
    events,
    categories,
    widget,
    eventsDaily,
  } = usePixel();

  const [eventsPageSize, setEventsPageSize] = useState(paginationOptions[0]);
  const [eventsPage, setEventsPage] = useState(0);

  const [availableCategories, setAvailableCategories] = useState<PixelEventCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState(ALL_OPTION);

  const [availableSubcategories, setAvailableSubcategories] = useState<PixelEventSubcategory[]>([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(ALL_OPTION);

  const [dateRangeValue, setDateRangeValue] = useState<DateRangeValue>(null);
  const startDate = formatDate(dateRangeValue?.[0]);
  const endDate = formatDate(dateRangeValue?.[1]);

  useEffect(() => {
    if (categories && categories.length && selectedCategory === ALL_OPTION) {
      setAvailableCategories(categories);
    }
  }, [categories]);

  useEffect(() => {
    const subcategories = getSubcategories(selectedCategory, availableCategories);

    setAvailableSubcategories(subcategories);
  }, [availableCategories, selectedCategory]);

  useEffect(() => {
    setSelectedSubcategory(ALL_OPTION);
  }, [selectedCategory]);

  useEffect(() => {
    if (currentAdvertiserId && startDate && endDate) {
      const payload = createBasePayload({
        selectedCategory,
        selectedSubcategory,
        currentAdvertiserId,
        startDate,
        endDate,
      });

      setEventsPage(0);
      const pages = { page: 0, size: eventsPageSize };

      getPixelEvents({ ...payload, ...pages });
      getPixelCategories(payload);
      getPixelWidget(payload);
      getPixelDailyEvents(payload);
    }
  }, [currentAdvertiserId, startDate, endDate, selectedCategory, selectedSubcategory]);

  useEffect(() => {
    if (currentAdvertiserId && startDate && endDate) {
      const payload = createBasePayload({
        selectedCategory,
        selectedSubcategory,
        currentAdvertiserId,
        startDate,
        endDate,
      });
      const pages = { page: eventsPage, size: eventsPageSize };

      getPixelEvents({ ...payload, ...pages });
    }
  }, [eventsPage, eventsPageSize]);

  return (
    <div className="w-full bg-white rounded-xl border border-primary-gray-100 flex-col justify-start items-start flex">
      <div className="p-8 flex-col justify-start items-start gap-3 inline-flex xl:w-256">
        <div className="text-secondary-black text-heading-xl font-semibold leading-relaxed">
          Pixel verification
        </div>
        <div className="h-10 justify-between w-full items-start inline-flex gap-2">
          <div className="justify-start items-start gap-2 flex">
            <FilterSelect<PixelEventCategory>
              onChange={setSelectedCategory}
              value={selectedCategory}
              prefix="Categories:"
              options={availableCategories}
              getOptionValue={(o) => o.category}
            />
            <FilterSelect<PixelEventSubcategory>
              onChange={setSelectedSubcategory}
              value={selectedSubcategory}
              prefix="Sub-categories:"
              options={availableSubcategories}
              getOptionValue={(o) => o.subcategory}
            />
          </div>
          <div className="flex">
            <DateRangePicker
              placement="bottomRight"
              value={dateRangeValue}
              onChange={(dateRange) => {
                setDateRangeValue([dateRange.startDate, dateRange.endDate]);
              }}
            />
          </div>
        </div>

        <div className="w-full flex-col justify-start items-start inline-flex border rounded-xl border-primary-gray-100">
          <div className="self-stretch border-b border-primary-gray-100 justify-start items-start inline-flex">
            <PixelWidget data={widget?.eventsFiredToday}>Events fired today</PixelWidget>
            <PixelWidget data={widget?.averageDailyEvents}>Average daily events</PixelWidget>
            <PixelWidget data={widget?.totalEventsFired} last>
              Total events fired
            </PixelWidget>
          </div>
          <PixelChart data={eventsDaily}>Pixel activity</PixelChart>
        </div>

        <PixelTable
          events={events}
          categories={categories}
          setEventsPageSize={setEventsPageSize}
          eventsPageSize={eventsPageSize}
          eventsPage={eventsPage}
          setEventsPage={setEventsPage}
        />
      </div>
    </div>
  );
};
