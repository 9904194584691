/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from 'react-redux';

import {
  loginStart,
  signupStart,
  forgotPasswordStart,
  getError,
  logout as logoutFlow,
  createNewPasswordStart,
  changePasswordStart,
  getUserId,
  getAuthState,
  updateUserStart,
} from './authSlice';
import {
  ChangePasswordPayload,
  CreateNewPasswordPayload,
  ForgotPasswordPayload,
  SigninPayload,
  SignupPayload,
  UserRequestValues,
} from './types';

export const useAuth = () => {
  const dispatch = useDispatch();
  const errorText = useSelector(getError);
  const state = useSelector(getAuthState);
  const userId = useSelector(getUserId);

  const signInDetailsKey = `CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || ''}.${userId}.signInDetails`;
  const accessTokenKey = `CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || ''}.${userId}.accessToken`;
  const idTokenKey = `CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || ''}.${userId}.idToken`;

  function login({ username, password }: SigninPayload) {
    dispatch(loginStart({ username, password }));
  }

  function signup({ fullname, username, tempPassword, password }: SignupPayload) {
    dispatch(signupStart({ fullname, username, tempPassword, password }));
  }

  function logout() {
    dispatch(logoutFlow());
  }

  function updateUser({ subId, fullName, phone }: UserRequestValues) {
    dispatch(updateUserStart({ subId, fullName, phone }));
  }

  function forgotPassword({ username }: ForgotPasswordPayload) {
    dispatch(forgotPasswordStart({ username }));
  }

  function changePassword({ oldPassword, newPassword }: ChangePasswordPayload): Promise<void> {
    return new Promise((resolve, reject) => {
      dispatch(
        changePasswordStart({
          oldPassword,
          newPassword,
          resolve,
          reject,
        }),
      );
    });
  }

  function createNewPassword({
    newPassword,
    confirmationCode,
    username,
  }: CreateNewPasswordPayload) {
    dispatch(createNewPasswordStart({ newPassword, confirmationCode, username }));
  }

  const isLoggedIn = localStorage.getItem(signInDetailsKey) !== null;
  const idToken = localStorage.getItem(idTokenKey);
  const accessToken = localStorage.getItem(accessTokenKey);
  const email = state.user?.email;
  const id = state.user?.id;
  const advertiserId = state.user?.advertiserId;
  const userpic = state.user?.userpic;
  const fullname = state.user?.fullname;
  const isLoading = state.isLoading;
  const phone = state.user?.phone;
  const role = state.user?.role;

  return {
    login,
    logout,
    signup,
    forgotPassword,
    createNewPassword,
    changePassword,
    updateUser,
    id,
    isLoggedIn,
    errorText,
    idToken,
    accessToken,
    email,
    userpic,
    advertiserId,
    fullname,
    isLoading,
    phone,
    role,
  };
};
