import {
  Upload as UploadAntd,
  UploadFile as UploadFileAntd,
  UploadProps as UploadPropsAntd,
} from 'antd';

export type UploadProps = UploadPropsAntd;
export type UploadFile = UploadFileAntd;

export const UploadButton = (props: UploadProps) => {
  return <UploadAntd beforeUpload={() => false} showUploadList={false} {...props} />;
};
