import { DatePicker as AntdDatePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import { useMemo, useCallback } from 'react';

dayjs.extend(weekday);
dayjs.extend(localeData);
export const DatePicker = ({
  format = 'D MMM YYYY',
  onChange,
  value,
  placeholder,
  disabled,
  disabledBeforeToday,
}: {
  value: string | null;
  format?: string;
  placeholder?: string;
  disabled?: boolean;
  disabledBeforeToday?: boolean;
  onChange: (value: string | null) => void;
}) => {
  const valueObject = useMemo(() => (value !== null ? dayjs(value) : null), [value]);

  const handleChange = (dayjsValue: Dayjs | null) => {
    const value = dayjsValue ? dayjs(dayjsValue).toISOString() : dayjsValue;

    onChange(value);
  };

  const disabledDate: RangePickerProps['disabledDate'] = useCallback(
    (current: dayjs.Dayjs) => {
      return disabledBeforeToday ? current && current < dayjs().endOf('day') : false;
    },
    [disabledBeforeToday],
  );

  return (
    <AntdDatePicker
      disabledDate={disabledDate}
      disabled={disabled}
      showToday={false}
      onChange={handleChange}
      format={format}
      className=""
      popupClassName=""
      suffixIcon={<></>}
      placement="bottomLeft"
      placeholder={placeholder}
      value={valueObject}
    />
  );
};
