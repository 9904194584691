function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;

  return String(error);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const reportError = ({ message }: { message: string }) => {};

export const notifyError = (error: unknown) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  reportError({ message: getErrorMessage(error) });
};
