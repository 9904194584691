import { all } from 'redux-saga/effects';

import { authFlowSagas } from 'modules/auth/authFlowSagas';
import { miscSagas } from 'modules/misc/miscSagas';
import { orgSagas } from 'modules/organization/organizationSagas';
import { performanceSagas } from 'modules/performance/performanceSagas';
import { pixelSagas } from 'modules/pixel/pixelSagas';
import { reportSagas } from 'modules/report/reportSagas';
import { userSagas } from 'modules/user/userSagas';
import { xandrSagas } from 'modules/xandr/xandrSagas';

export function* rootSaga() {
  yield all([
    authFlowSagas(),
    userSagas(),
    orgSagas(),
    xandrSagas(),
    performanceSagas(),
    miscSagas(),
    pixelSagas(),
    reportSagas(),
  ]);
}
