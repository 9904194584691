/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { paginationOptions } from 'components/constants';

import { useOrganization } from 'modules/organization/useOrganization';
import { Report } from 'modules/report/reportApiTypes';
import { useReport } from 'modules/report/useReport';

import { CreateReport } from './CreateReport';
import { ReportsTable } from './ReportsTable';

export const Reports = () => {
  const { currentAdvertiserId } = useOrganization();
  const { getReports } = useReport();
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [refetch, setRefetch] = useState(() => () => {});

  const [pageSize, setPageSize] = useState(paginationOptions[0]);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [sortBy, setSortBy] = useState<string | null>('modificationDate');
  const [sortDirection, setSortDirection] = useState<string | null>('DESC');

  useEffect(() => {
    if (search) setPage(0);
  }, [search]);

  useEffect(() => {
    const payload = { page, size: pageSize, sortBy, direction: sortDirection, search };

    currentAdvertiserId && getReports(payload);
    setRefetch(() => () => getReports(payload));
  }, [currentAdvertiserId, page, pageSize, sortBy, sortDirection, search, setRefetch]);

  return isCreateFormOpen ? (
    <CreateReport
      setIsCreateFormOpen={setIsCreateFormOpen}
      selectedReport={selectedReport}
      refetch={refetch}
    />
  ) : (
    <ReportsTable
      setIsCreateFormOpen={setIsCreateFormOpen}
      selectedReport={selectedReport}
      setSelectedReport={setSelectedReport}
      refetch={refetch}
      pageSize={pageSize}
      setPageSize={setPageSize}
      page={page}
      setPage={setPage}
      search={search}
      setSearch={setSearch}
      setSortBy={setSortBy}
      setSortDirection={setSortDirection}
    />
  );
};
