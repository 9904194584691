import classNames from 'classnames';

import { PixelWidgetProps } from './PixelVerificationTypes';

export const PixelWidget = ({ data, children, last = false }: PixelWidgetProps) => {
  return (
    <div
      className={classNames(
        'grow shrink basis-0 self-stretch p-6 flex-col justify-center items-start gap-4 inline-flex text-secondary-black',
        { 'border-r border-primary-gray-100': !last },
      )}
    >
      <div className="self-stretch text-base-sm font-medium leading-tight">{children}</div>
      <div className="self-stretch text-heading-3xl font-bold leading-9">{data}</div>
    </div>
  );
};
