import { PagingRequestValues } from 'modules/organization/organizationApiTypes';

import { api } from 'services/api';

import {
  ANALYTICS_URL,
  BUDGET_URL,
  CHART_URL,
  CREATIVE_NAMES_URL,
  CREATIVE_URL,
  HALO_URL,
  INCREMENTALITY_URL,
  NESTED_ANALYTICS_URL,
  PUBLISHER_URL,
  VIEW_URL,
  WIDGET_URL,
} from './constants';
import {
  AnalyticsRequest,
  AnalyticsResponse,
  BudgetRequest,
  BudgetResponse,
  ChartRequest,
  CreativeNamesPagingResponse,
  CreativesPagingResponse,
  DeleteViewRequest,
  Halo,
  HaloRequest,
  Incrementality,
  IncrementalityRequest,
  NestedAnalyticsRequest,
  PerformanceResponse,
  PublishersPagingResponse,
  View,
  WidgetRequest,
} from './performanceApiTypes';

const getViews = (advertiserId: string) => {
  return api.get<View[]>(`${VIEW_URL}?advertiserId=${advertiserId}`);
};

const saveView = (view: View) => {
  return api.post<View>(VIEW_URL, view);
};

const deleteView = (request: DeleteViewRequest) => {
  return api.delete(`${VIEW_URL}/${request.viewId}`);
};

const getWidgetData = (payload: WidgetRequest) => {
  return api.post<PerformanceResponse>(WIDGET_URL, payload);
};

const getBudgetData = (payload: BudgetRequest) => {
  return api.post<BudgetResponse>(BUDGET_URL, payload);
};

const getNestedAnalyticsData = (payload: NestedAnalyticsRequest) => {
  return api.post<AnalyticsResponse>(NESTED_ANALYTICS_URL, payload);
};

const getChartData = (payload: ChartRequest) => {
  return api.post<PerformanceResponse>(CHART_URL, payload);
};

const getIncrementalityData = (payload: IncrementalityRequest) => {
  return api.post<Incrementality[]>(INCREMENTALITY_URL, payload);
};

const getHaloData = (payload: HaloRequest) => {
  return api.post<Halo[]>(HALO_URL, payload);
};

const getAnalyticsData = (payload: AnalyticsRequest) => {
  return api.post<AnalyticsResponse>(ANALYTICS_URL, payload);
};

const getPublishers = (payload: PagingRequestValues) => {
  return api.get<PublishersPagingResponse>(
    `${PUBLISHER_URL}?page=${payload.page}&size=${payload.size}`,
  );
};

const getCreatives = (payload: PagingRequestValues) => {
  return api.get<CreativesPagingResponse>(
    `${CREATIVE_URL}?page=${payload.page}&size=${payload.size}`,
  );
};

const getCreativeNames = (payload: PagingRequestValues) => {
  return api.get<CreativeNamesPagingResponse>(
    `${CREATIVE_NAMES_URL}?page=${payload.page}&size=${payload.size}&advertiserId=${payload.advertiserId}`,
  );
};

export const performanceApi = {
  getViews,
  saveView,
  deleteView,
  getWidgetData,
  getNestedAnalyticsData,
  getChartData,
  getAnalyticsData,
  getPublishers,
  getCreatives,
  getCreativeNames,
  getIncrementalityData,
  getHaloData,
  getBudgetData,
};
