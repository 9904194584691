import { yupResolver } from '@hookform/resolvers/yup';
import { Edit } from 'feather-icons-react';
import { useState } from 'react';
import { useForm, FieldValues, FieldPath } from 'react-hook-form';
import { object } from 'yup';

import { Button, Field, IconButton } from 'components/common';

import { FormFieldProps, FormType, DefaultValuesType, ValuesType } from './types';

export const FormField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  name,
  onSubmit,
  fieldSchema,
  value,
  render,
  defaultValue = '',
  onCancel = () => {},
}: FormFieldProps<TFieldValues, TName>) => {
  const [isEditing, setIsEditing] = useState(false);

  const schema = object()
    .shape({ [name]: fieldSchema })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    clearErrors,
    reset,
  } = useForm<FormType<TName>>({
    defaultValues: {
      [name]: defaultValue,
    } as DefaultValuesType<TName>,
    values: {
      [name]: value,
    } as ValuesType<TName>,
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = handleSubmit((data) => {
    onSubmit(data);
    setIsEditing(false);
  });

  const handleOnEdit = () => {
    clearErrors();
    setIsEditing(true);
  };
  const handleCancel = () => {
    onCancel();
    reset();
    setIsEditing(false);
  };

  const readOnly = !isEditing;

  return (
    <form
      onSubmit={handleFormSubmit}
      className="w-full self-stretch justify-start items-start gap-2 inline-flex"
    >
      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex w-full">
        <Field
          label={label}
          render={render(readOnly)}
          control={control}
          name={name}
          error={readOnly ? '' : errors[name]?.message?.toString()}
        />
      </div>
      {isEditing ? (
        <div className="h-16 pt-6 justify-start items-start gap-2 flex flex-row">
          <Button
            size="md"
            variant="primary"
            disabled={!isValid || !isDirty}
            onClick={handleFormSubmit}
          >
            Save
          </Button>
          <Button size="md" variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      ) : (
        <div className="pt-6 justify-start items-start flex">
          <IconButton variant="secondary" size="md" Icon={Edit} onClick={handleOnEdit} />
        </div>
      )}
    </form>
  );
};

FormField.displayName = 'FormField';
