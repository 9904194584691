import { Layout, SiderProps as SiderAntdProps } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';

import { ctvTwMerge } from 'services/twMerge/ctvTwMerge';

const { Sider: SiderAntd } = Layout;

export const Sider = memo(({ children, className, ...rest }: SiderAntdProps) => {
  return (
    <SiderAntd
      className={ctvTwMerge(classNames('ctv-sider'), className)}
      trigger={null}
      collapsible
      {...rest}
    >
      <>{children}</>
    </SiderAntd>
  );
});

Sider.displayName = 'Sider';
