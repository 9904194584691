/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getErrorMessage } from 'utils';

import { getCurrentAdvertiserId } from 'modules/organization/organizationSlice';
import { endDate, startDate } from 'modules/performance/performanceSlice';

import { pixelApi } from './pixelApi';
import {
  PixelEventCategory,
  PixelEventDaily,
  PixelEvents,
  PixelEventsRequest,
  PixelRequest,
  PixelWidget,
} from './pixelApiTypes';
import {
  getPixelCategoriesFailure,
  getPixelCategoriesStart,
  getPixelCategoriesSuccess,
  getPixelDailyEventsFailure,
  getPixelDailyEventsStart,
  getPixelDailyEventsSuccess,
  getPixelEventsFailure,
  getPixelEventsStart,
  getPixelEventsSuccess,
  getPixelExportFailure,
  getPixelExportStart,
  getPixelExportSuccess,
  getPixelWidgetFailure,
  getPixelWidgetStart,
  getPixelWidgetSuccess,
} from './pixelSlice';

function* getPixelWidgetFlow(action: PayloadAction<PixelRequest>) {
  try {
    const response: PixelWidget = yield call(pixelApi.getPixelWidget, action.payload);

    yield put(getPixelWidgetSuccess(response));
  } catch (error) {
    yield put(getPixelWidgetFailure(getErrorMessage(error)));
  }
}

function* getPixelEventsFlow(action: PayloadAction<PixelEventsRequest>) {
  try {
    const response: PixelEvents = yield call(pixelApi.getPixelEvents, action.payload);

    yield put(getPixelEventsSuccess(response));
  } catch (error) {
    yield put(getPixelEventsFailure(getErrorMessage(error)));
  }
}

function* getPixelCategoriesFlow(action: PayloadAction<PixelRequest>) {
  try {
    const response: PixelEventCategory[] = yield call(pixelApi.getPixelCategories, action.payload);

    yield put(getPixelCategoriesSuccess(response));
  } catch (error) {
    yield put(getPixelCategoriesFailure(getErrorMessage(error)));
  }
}

function* getPixelExportFlow() {
  try {
    const selectedAdvertiserId: string = yield select(getCurrentAdvertiserId);
    const selectedStartDate: string = yield select(startDate);
    const selectedEndDate: string = yield select(endDate);

    const request: PixelEventsRequest = {
      advertiserId: selectedAdvertiserId,
      startDateTime: selectedStartDate,
      endDateTime: selectedEndDate,
      page: 0,
      size: 10000,
    };
    const response: unknown = yield call(pixelApi.getPixelExport, request);

    yield put(getPixelExportSuccess(response));
  } catch (error) {
    yield put(getPixelExportFailure(getErrorMessage(error)));
  }
}

function* getPixelDailyEventsFlow(action: PayloadAction<PixelRequest>) {
  try {
    const response: PixelEventDaily[] = yield call(pixelApi.getPixelDailyEvents, action.payload);

    yield put(getPixelDailyEventsSuccess(response));
  } catch (error) {
    yield put(getPixelDailyEventsFailure(getErrorMessage(error)));
  }
}

export function* pixelSagas() {
  yield all([
    takeLatest(getPixelWidgetStart.type, getPixelWidgetFlow),
    takeLatest(getPixelEventsStart.type, getPixelEventsFlow),
    takeLatest(getPixelCategoriesStart.type, getPixelCategoriesFlow),
    takeLatest(getPixelExportStart.type, getPixelExportFlow),
    takeLatest(getPixelDailyEventsStart.type, getPixelDailyEventsFlow),
  ]);
}
