import { Empty, RadioChangeEvent } from 'antd';
import { CheckboxOptionType } from 'antd/lib/checkbox/Group';
import { useEffect, useState } from 'react';

import { RadioOptions } from 'components/GlobalFilter/constants';
import { Button, Card, CheckboxGroup, Radio, RadioGroup, Skeleton } from 'components/common';

interface GlobalFilterTemplateProps {
  label: string;
  checkboxOptions: CheckboxOptionType[];
  selectedOptions: CheckboxOptionType[];
  radioOption: RadioOptions;
  handleDimensionRadioOption?: React.Dispatch<React.SetStateAction<RadioOptions>>;
  handleSubmitAction: (data: {
    radioOption: RadioOptions;
    checkboxOptions: CheckboxOptionType[];
  }) => void;
}

export const GlobalFilterTemplate = ({
  label,
  checkboxOptions,
  handleSubmitAction,
  radioOption,
  handleDimensionRadioOption,
  selectedOptions,
}: GlobalFilterTemplateProps) => {
  const [radioValue, setRadioValue] = useState<RadioOptions>(radioOption);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<CheckboxOptionType[]>(
    selectedOptions || [],
  );

  useEffect(() => {
    setSelectedCheckboxes(selectedOptions);
  }, [selectedOptions]);

  const handleRadioChange = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
    handleDimensionRadioOption && handleDimensionRadioOption(e.target.value);
  };

  const handleCheckboxChange = (checkedValues: CheckboxOptionType[]) => {
    setSelectedCheckboxes(checkedValues);
  };

  return (
    <Card style={{ width: 384 }} className="filter-container p-6">
      <div className="pb-1.5 font-medium">{label}</div>
      <div className="pb-1.5 justify-start items-start gap-6 inline-flex">
        <RadioGroup onChange={handleRadioChange} value={radioValue}>
          <Radio value={RadioOptions.INCLUDE}>is</Radio>
          <Radio value={RadioOptions.EXCLUDE}>is not</Radio>
        </RadioGroup>
      </div>
      <Card className="filter-card max-h-[212px] p-1.5 bg-primary-gray-50 border border-primary-gray-200 overflow-auto">
        {checkboxOptions ? (
          checkboxOptions.length > 0 ? (
            <CheckboxGroup
              options={checkboxOptions}
              onChange={handleCheckboxChange}
              value={selectedCheckboxes}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data to show" />
          )
        ) : (
          <Skeleton loading={true} />
        )}
      </Card>
      <Button
        size="md"
        variant="primary"
        className="w-[336px] mt-1.5"
        disabled={!selectedCheckboxes?.length}
        onClick={() =>
          handleSubmitAction({
            radioOption: radioValue,
            checkboxOptions: selectedCheckboxes,
          })
        }
      >
        Apply
      </Button>
    </Card>
  );
};
