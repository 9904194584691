import { Tabs as TabsAntd, TabsProps } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';

export const Tabs = memo(({ className, ...rest }: TabsProps) => (
  <TabsAntd
    defaultActiveKey="1"
    type="card"
    className={classNames('ctv-tabs', className)}
    {...rest}
  />
));

Tabs.displayName = 'Tabs';
