import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Button, CheckboxGroup, Field, Indicator, Input, InputNumber } from 'components/common';
import { Select, Option } from 'components/common/select';

import { useDeviceTypesQuery, useMediaTypesQuery } from 'modules/apiData/dataApiSlice';
import { useXandr } from 'modules/xandr/useXandr';
import { PriceType } from 'modules/xandr/xandrApiTypes';

import {
  XandrEditLineItemFormInputs,
  EditLineItemModalProps,
  lineItemEditSchema,
} from './XandrConnectionTypes';
import { priceTypes, currencyFormatter, currencyParser } from './utils';

export const EditLineItemModal = ({ onClose, selectedLineItem }: EditLineItemModalProps) => {
  const { isLoading, errorText, editLineItem } = useXandr();
  const { data: mediaTypes } = useMediaTypesQuery(null);
  const { data: deviceTypes } = useDeviceTypesQuery(null);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm<XandrEditLineItemFormInputs>({
    resolver: yupResolver(lineItemEditSchema),
    values: {
      id: selectedLineItem?.id,
      name: selectedLineItem?.name || '',
      displayName: selectedLineItem?.displayName || '',
      priceType: selectedLineItem?.priceType || PriceType.ADDED,
      audienceName: selectedLineItem?.audienceName || '',
      cpm: selectedLineItem?.cpm || 0,
      budgetCap: selectedLineItem?.budgetCap || 0,
      mediaType: selectedLineItem?.mediaType?.id || 0,
      devices: selectedLineItem?.devices?.map((d) => d.id) || [],
    },
  });

  const ids = watch('devices');
  const priceTypeIsAdded = watch('priceType') === PriceType.ADDED;

  const handleFormSubmit = handleSubmit(async (data) => {
    try {
      await editLineItem(data);
      onClose();
    } catch (error) {
      //setErrorText(error);
    }
  });

  const handlePriceTypeChange = (v: PriceType) => {
    if (v === PriceType.ADDED) {
      setValue('cpm', 0);
      setValue('budgetCap', 0);
    }
  };

  const devicesLabelRender = () =>
    ids
      ? deviceTypes
          ?.filter((dev) => ids.some((id) => id === dev.id))
          .map(({ name }) => name)
          .join(', ')
      : null;

  return (
    <div className="w-144 bg-white rounded-xl shadow  flex-col justify-start items-start inline-flex">
      <div className="self-stretch px-6 justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 py-5 flex-col justify-start items-start gap-2 inline-flex">
          <h1 className="self-stretch text-base-md font-medium leading-snug text-secondary-black">
            Edit line item connection
          </h1>
        </div>
      </div>
      <div className="px-6 pt-3 pb-6 gap-6 stretch-to-form">
        <form
          onSubmit={handleFormSubmit}
          className="w-full gap-4 flex flex-col justify-start items-start"
        >
          <div className="self-stretch justify-start items-start gap-2 inline-flex">
            <Field
              label={'Xandr line item'}
              render={({ field, fieldState: { invalid } }) => (
                <Input {...field} invalid={invalid} disabled />
              )}
              control={control}
              name={'name'}
              error={errors.name?.message}
            />
            <Field
              label={'Line item ID'}
              render={({ field, fieldState: { invalid } }) => (
                <Input {...field} invalid={invalid} disabled />
              )}
              control={control}
              name={'id'}
              error={errors.id?.message}
            />
          </div>
          <Field
            label={'Ad group name'}
            render={({ field, fieldState: { invalid } }) => <Input {...field} invalid={invalid} />}
            control={control}
            name={'displayName'}
            error={errors.displayName?.message}
          />

          <Field
            label={'Audience name'}
            render={({ field, fieldState: { invalid } }) => <Input {...field} invalid={invalid} />}
            control={control}
            name={'audienceName'}
            error={errors.audienceName?.message}
          />
          <div className="self-stretch justify-start items-start gap-2 inline-flex">
            <Field
              label={'Price type'}
              render={({ field }) => (
                <Select
                  className="w-full"
                  placeholder="Select Price type..."
                  {...field}
                  onChange={(value) => {
                    handlePriceTypeChange(value);
                    field.onChange(value);
                  }}
                >
                  {Object.values(priceTypes).map(({ value, label }, i) => (
                    <Option key={`price-types-${i}`} value={value} className="ctv-option">
                      {label}
                    </Option>
                  ))}
                </Select>
              )}
              control={control}
              name={'priceType'}
              error={errors.priceType?.message}
            />
            <Field
              label={'CPM'}
              render={({ field, fieldState: { invalid } }) => (
                <InputNumber
                  {...field}
                  className="w-full"
                  formatter={currencyFormatter}
                  controls={false}
                  parser={currencyParser}
                  invalid={invalid}
                  disabled={priceTypeIsAdded}
                />
              )}
              control={control}
              name={'cpm'}
              error={errors.cpm?.message}
            />
          </div>
          <Field
            label={'Budget cap'}
            render={({ field, fieldState: { invalid } }) => (
              <InputNumber
                {...field}
                className="w-full"
                formatter={currencyFormatter}
                controls={false}
                parser={currencyParser}
                invalid={invalid}
                disabled={priceTypeIsAdded}
              />
            )}
            control={control}
            name={'budgetCap'}
            error={errors.budgetCap?.message}
          />
          <div className="self-stretch justify-start items-start gap-2 inline-flex">
            <Field
              label={'Media type'}
              render={({ field }) => (
                <Select className="w-full" placeholder="Select Media type..." {...field}>
                  {mediaTypes?.map(({ id, name }, i) => (
                    <Option key={`price-types-${i}`} value={id} className="ctv-option">
                      {name}
                    </Option>
                  ))}
                </Select>
              )}
              control={control}
              name={'mediaType'}
              error={errors.mediaType?.message}
            />
            <Field
              label={'Device type'}
              render={({ field }) => (
                <Select
                  className="w-full"
                  {...field}
                  placeholder="Select devices..."
                  labelRender={devicesLabelRender}
                  dropdownRender={() => (
                    <CheckboxGroup
                      {...field}
                      options={
                        deviceTypes?.map(({ name, id }) => ({
                          label: name,
                          value: id,
                        })) || []
                      }
                      column
                      className="gap-3 ctv-option"
                    />
                  )}
                />
              )}
              control={control}
              name={'devices'}
              error={errors.devices?.message}
            />
          </div>
          <div className="stretch-to-form gap-3">
            {errorText && (
              <Indicator variant="danger" size="lg">
                {errorText}
              </Indicator>
            )}
          </div>
        </form>
      </div>
      <div className="self-stretch h-16 px-6 border-t border-primary-gray-100 justify-end items-center gap-2 inline-flex">
        <div className="justify-end items-center gap-2 flex">
          <Button
            size="md"
            variant="secondary"
            onClick={onClose}
            className="w-20"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            size="md"
            variant="primary"
            disabled={!isValid || !isDirty}
            onClick={handleFormSubmit}
            isLoading={isLoading}
          >
            Save changes
          </Button>
        </div>
      </div>
    </div>
  );
};
