import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'services/api';

export const ctvApi = createApi({
  reducerPath: 'ctvApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ApiData'],
  endpoints: () => ({}),
});
