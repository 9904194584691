import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getErrorMessage } from 'utils';

import { PagingRequestValues } from 'modules/organization/organizationApiTypes';
import { invalidatePerformanceCache } from 'modules/performance/performanceSlice';

import { xandrApi } from './xandrApi';
import {
  AudiencesPagingResponse,
  InsertionOrder,
  InsertionOrderEditRequest,
  InsertionOrderEnableRequest,
  LineItem,
  LineItemEditRequest,
  LineItemsPagingResponse,
  XandrConnection,
  XandrConnectionRequest,
  XandrStatus,
} from './xandrApiTypes';
import {
  editInsertionOrderFailure,
  editInsertionOrderStart,
  editInsertionOrderSuccess,
  editLineItemFailure,
  editLineItemSuccess,
  editLineItemStart,
  enableInsertionOrderFailure,
  enableInsertionOrderStart,
  enableInsertionOrderSuccess,
  getInsertionOrdersFailure,
  getInsertionOrdersStart,
  getInsertionOrdersSuccess,
  getXandrConnectionFailure,
  getXandrConnectionStart,
  getXandrConnectionSuccess,
  getXandrStatusFailure,
  getXandrStatusStart,
  getXandrStatusSuccess,
  refreshXandrConnectionFailure,
  refreshXandrConnectionStart,
  refreshXandrConnectionSuccess,
  removeXandrConnectionFailure,
  removeXandrConnectionStart,
  removeXandrConnectionSuccess,
  syncInsertionOrderFailure,
  syncInsertionOrderStart,
  syncInsertionOrderSuccess,
  syncAllInsertionOrdersStart,
  syncAllInsertionOrdersSuccess,
  syncAllInsertionOrdersFailure,
  syncLineItemFailure,
  syncLineItemSuccess,
  syncLineItemStart,
  getLineItemsSuccess,
  getLineItemsFailure,
  getLineItemsStart,
  getAudiencesStart,
  getAudiencesSuccess,
  getAudiencesFailure,
} from './xandrSlice';

function* getXandrConnectionFlow(action: PayloadAction<XandrConnectionRequest>) {
  try {
    const response: XandrConnection = yield call(
      xandrApi.getXandrConnection,
      action.payload.advertiserId,
      action.payload.xandrAdvertiserId,
    );

    yield put(getXandrConnectionSuccess(response));
  } catch (error) {
    yield put(getXandrConnectionFailure(getErrorMessage(error)));
  }
}

function* getXandrStatusFlow(action: PayloadAction<string>) {
  try {
    const response: XandrStatus = yield call(xandrApi.getXandrStatus, action.payload);

    yield put(getXandrStatusSuccess(response));
  } catch (error) {
    yield put(getXandrStatusFailure(getErrorMessage(error)));
  }
}

function* refreshXandrConnectionFlow(action: PayloadAction<string>) {
  try {
    const response: XandrConnection = yield call(xandrApi.refreshXandrConnection, action.payload);

    yield put(refreshXandrConnectionSuccess(response));
    yield put(invalidatePerformanceCache());
  } catch (error) {
    yield put(refreshXandrConnectionFailure(getErrorMessage(error)));
  }
}

function* removeXandrConnectionFlow(action: PayloadAction<string>) {
  try {
    const response: XandrConnection = yield call(xandrApi.removeXandrConnection, action.payload);

    yield put(removeXandrConnectionSuccess(response));
    yield put(invalidatePerformanceCache());
  } catch (error) {
    yield put(removeXandrConnectionFailure(getErrorMessage(error)));
  }
}

function* editLineItemFlow(
  action: PayloadAction<
    LineItemEditRequest & { resolve: () => void; reject: (error: string) => void }
  >,
) {
  try {
    const response: InsertionOrder[] = yield call(
      xandrApi.editLineItem,
      action.payload.lineItem,
      action.payload.advertiserId,
    );

    yield put(editLineItemSuccess(response));
    yield put(invalidatePerformanceCache());
    action.payload.resolve();
  } catch (error) {
    const message = getErrorMessage(error);

    yield put(editLineItemFailure(message));
    action.payload.reject(message);
  }
}

function* syncLineItemFlow(action: PayloadAction<number>) {
  try {
    const response: LineItem = yield call(xandrApi.syncLineItem, action.payload);

    yield put(syncLineItemSuccess(response));
    yield put(invalidatePerformanceCache());
  } catch (error) {
    yield put(syncLineItemFailure(getErrorMessage(error)));
  }
}

function* getInsertionOrdersFlow(action: PayloadAction<string>) {
  try {
    const response: InsertionOrder[] = yield call(xandrApi.getInsertionOrders, action.payload);

    yield put(getInsertionOrdersSuccess(response));
  } catch (error) {
    yield put(getInsertionOrdersFailure(getErrorMessage(error)));
  }
}

function* editInsertionOrderFlow(
  action: PayloadAction<
    InsertionOrderEditRequest & { resolve: () => void; reject: (error: string) => void }
  >,
) {
  try {
    const response: InsertionOrder[] = yield call(
      xandrApi.editInsertionOrder,
      action.payload.insertionOrder,
      action.payload.advertiserId,
    );

    yield put(editInsertionOrderSuccess(response));
    yield put(invalidatePerformanceCache());
    action.payload.resolve();
  } catch (error) {
    const message = getErrorMessage(error);

    yield put(editInsertionOrderFailure(message));
    action.payload.reject(message);
  }
}

function* syncInsertionOrderFlow(action: PayloadAction<number>) {
  try {
    const response: InsertionOrder = yield call(xandrApi.syncInsertionOrder, action.payload);

    yield put(syncInsertionOrderSuccess(response));
    yield put(invalidatePerformanceCache());
  } catch (error) {
    yield put(syncInsertionOrderFailure(getErrorMessage(error)));
  }
}

function* syncAllInsertionOrdersFlow(action: PayloadAction<string>) {
  try {
    const response: InsertionOrder[] = yield call(xandrApi.syncAllInsertionOrders, action.payload);

    yield put(syncAllInsertionOrdersSuccess(response));
    yield put(invalidatePerformanceCache());
  } catch (error) {
    yield put(syncAllInsertionOrdersFailure(getErrorMessage(error)));
  }
}

function* enableInsertionOrderFlow(action: PayloadAction<InsertionOrderEnableRequest>) {
  try {
    const response: InsertionOrder[] = yield call(xandrApi.enableInsertionOrder, action.payload);

    yield put(enableInsertionOrderSuccess(response));
    yield put(invalidatePerformanceCache());
  } catch (error) {
    yield put(enableInsertionOrderFailure(getErrorMessage(error)));
  }
}

function* getLineItemsFlow(action: PayloadAction<PagingRequestValues>) {
  try {
    const response: LineItemsPagingResponse = yield call(xandrApi.getLineItems, action.payload);

    yield put(getLineItemsSuccess(response.data));
  } catch (error) {
    yield put(getLineItemsFailure(getErrorMessage(error)));
  }
}

function* getAudiencesFlow(action: PayloadAction<PagingRequestValues>) {
  try {
    const response: AudiencesPagingResponse = yield call(xandrApi.getAudiences, action.payload);

    yield put(getAudiencesSuccess(response.data));
  } catch (error) {
    yield put(getAudiencesFailure(getErrorMessage(error)));
  }
}

export function* xandrSagas() {
  yield all([
    takeLatest(getXandrConnectionStart.type, getXandrConnectionFlow),
    takeLatest(refreshXandrConnectionStart.type, refreshXandrConnectionFlow),
    takeLatest(removeXandrConnectionStart.type, removeXandrConnectionFlow),
    takeLatest(getXandrStatusStart.type, getXandrStatusFlow),
    takeLatest(getInsertionOrdersStart.type, getInsertionOrdersFlow),
    takeLatest(editLineItemStart.type, editLineItemFlow),
    takeLatest(syncLineItemStart.type, syncLineItemFlow),
    takeLatest(editInsertionOrderStart.type, editInsertionOrderFlow),
    takeLatest(syncInsertionOrderStart.type, syncInsertionOrderFlow),
    takeLatest(syncAllInsertionOrdersStart.type, syncAllInsertionOrdersFlow),
    takeLatest(enableInsertionOrderStart.type, enableInsertionOrderFlow),
    takeLatest(getLineItemsStart.type, getLineItemsFlow),
    takeLatest(getAudiencesStart.type, getAudiencesFlow),
  ]);
}
