import { X } from 'feather-icons-react';
import { useEffect, useMemo, useState } from 'react';
import { expandConstants } from 'utils';

import { Button, Checkbox } from 'components/common';
import SortableList, { DragHandle } from 'components/common/sortableList';

import { useConstantsQuery } from 'modules/apiData/dataApiSlice';
import { usePerformance } from 'modules/performance/usePerformance';

type EditColumnsModalProps = {
  onClose: () => void;
};

export const EditColumnsModal = ({ onClose }: EditColumnsModalProps) => {
  const [selectedMetrics, setSelectedMetrics] = useState<string[]>([]);
  const { selectedColumns, pickSelectedColumns, views, selectedView } = usePerformance();
  const { data: constants } = useConstantsQuery(null);
  const allMetrics = useMemo(
    () => expandConstants((constants && constants.Metrics) || {}),
    [constants],
  );
  const viewMetrics = useMemo(
    () => views?.find((view) => view.viewId === selectedView)?.metrics || [],
    [views, selectedView],
  );

  useEffect(() => {
    setSelectedMetrics((selectedColumns?.length && selectedColumns) || viewMetrics);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMetrics]);

  const handleEditColumns = () => {
    pickSelectedColumns(selectedMetrics);
    onClose();
  };

  const handleCancel = () => {
    setSelectedMetrics((selectedColumns?.length && selectedColumns) || viewMetrics);
    onClose();
  };

  const handleMetricChange = (metric: string, isChecked: boolean) => {
    setSelectedMetrics((prev) => {
      if (isChecked) {
        return [...prev, metric];
      }
      const selectedMetrics = prev.filter((m) => m !== metric);

      // Ensure at least one column is selected
      if (selectedMetrics.length === 0) {
        return prev;
      }

      return selectedMetrics;
    });
  };

  return (
    <div className="w-[684px] bg-white rounded-xl shadow  flex-col justify-start items-start inline-flex">
      <div className="self-stretch px-6 justify-start items-start gap-6 inline-flex border-b border-primary-gray-100">
        <div className="grow shrink basis-0 py-5 flex-col justify-start items-start gap-2 inline-flex">
          <h1 className="self-stretch text-base-md font-medium leading-snug text-secondary-black">
            Edit columns
          </h1>
        </div>
      </div>
      <div className="max-h-[753px] flex flex-row gap-6 stretch-to-form overflow-y-auto">
        <div className="bg-primary-gray-50 pl-6 py-4 flex-1 border-r border-primary-gray-100">
          <div className="self-stretch pb-2 font-medium leading-snug">Library</div>
          {Object.values(allMetrics).map(({ label, value }, index) => (
            <Checkbox
              key={index}
              className="pb-3"
              checked={selectedMetrics?.includes(value)}
              onChange={(e) => handleMetricChange(value, e.target.checked)}
            >
              <span className="text-base-sm font-normal leading-tight">{label.shownName}</span>
            </Checkbox>
          ))}
        </div>
        <div className="pr-6 py-4 flex-1">
          <div className="text-secondary-black text-base-md font-medium  leading-snug mb-2">
            Active metrics
          </div>
          <div className="w-full">
            <SortableList
              items={selectedMetrics.map((v) => allMetrics[v])}
              setItems={setSelectedMetrics}
              renderItem={(item) => (
                <div className="h-10 w-full px-2 bg-primary-gray-50 rounded-lg justify-between items-center inline-flex">
                  <div className="justify-start items-center gap-1.5 flex">
                    <DragHandle />
                    <div className="text-secondary-black text-base-sm font-normal ">
                      {item.label.shownName}
                    </div>
                  </div>
                  <X
                    className="w-5 h-5 text-secondary-black cursor-pointer hover:text-primary-gray-500"
                    onClick={() => handleMetricChange(item.value, false)}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <div className="self-stretch h-16 px-6 border-t border-primary-gray-100 justify-end items-center gap-2 inline-flex">
        <div className="justify-end items-center gap-2 flex">
          <Button size="md" variant="secondary" onClick={handleCancel} className="w-20">
            Cancel
          </Button>
          <Button size="md" variant="primary" onClick={handleEditColumns}>
            Save changes
          </Button>
        </div>
      </div>
    </div>
  );
};
