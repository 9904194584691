import { LoadingOutlined } from '@ant-design/icons';
import { formatNumber } from 'utils';

import { ReactComponent as IconChevronSort } from 'assets/images/icons/chevron-selector-vertical.svg';

import { Metrics } from 'components/Incrementality/incrementalityTypes';
import { Chart, ChartOption } from 'components/common';

import { ChannelHalo, Halo, sortDirections } from 'modules/performance/performanceApiTypes';
import { usePerformance } from 'modules/performance/usePerformance';

const getTooltipContent = ({ exposedConversions, controlConversions }: ChannelHalo) => `
    <div style="width: 100%; height: 20px; padding-left: 12px; border-left: 2px solid #6d49d4; margin-top: 8px;">
      <div style="font-size: 14px; font-weight: normal; line-height: normal; padding-bottom: 4px">
        Exposed сonversions: ${exposedConversions}
      </div>
    </div>
        <div style="width: 100%; height: 20px; padding-left: 12px; border-left: 2px solid #cacbcd; margin-top: 8px;">
      <div style="font-size: 14px; font-weight: normal;  line-height: normal;">
        Controlled conversions: ${controlConversions}
      </div>
    </div>
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const HaloEffectTable = ({ isSidebarCollapsed }: { isSidebarCollapsed?: boolean }) => {
  const { haloOrder, pickHaloOrder, isHaloLoading, halo, haloFilter } = usePerformance();

  const chartOption = (halo: Halo) => ({
    tooltip: {
      trigger: 'axis',
      appendToBody: true,
      axisPointer: {
        type: 'none',
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      formatter: (params: any) => {
        const index = params[0].dataIndex;
        const item = halo.haloByChannels[index];

        return getTooltipContent(item);
      },
    },
    legend: {},
    grid: {
      left: '-1px',
      top: 0,
      right: '4%',
      bottom: 0,
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'category',
      data: halo?.haloByChannels.map((elem) => elem.channel),
      inverse: true,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    series: [
      {
        type: 'bar',
        color: '#6D49D4',
        data: halo?.haloByChannels.map((elem) => elem.exposedConversions),
        barGap: '0%',
        barWidth: 25,
        itemStyle: {
          color: '#6D49D4',
        },
        label: {
          show: true,
          position: 'right',
          color: '#6D49D4',
        },
      },
      {
        type: 'bar',
        color: '#CACBCD',
        barWidth: 25,
        data: halo?.haloByChannels.map((elem) => elem.controlConversions),
        itemStyle: {
          color: '#CACBCD',
        },
        label: {
          show: true,
          position: 'right',
          color: '#16171A',
        },
      },
    ],
  });

  const createOption = (halo: Halo): ChartOption => {
    return {
      legend: {},
      grid: {
        left: '0%',
        right: '4%',
        bottom: '0%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        splitLine: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value: string) => (!value ? '' : formatNumber(parseInt(value), 0)),
          show: true,
        },
      },
      yAxis: {
        type: 'category',
        data: [''],
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      tooltip: {
        axisPointer: {
          type: 'none',
        },
        show: false,
      },
      series: [
        {
          type: 'bar',
          color: '#6D49D4',
          data: halo?.haloByChannels.map((elem) => elem.exposedConversions),
          label: {
            show: false,
          },
          barWidth: '-1px',
          barGap: '0%',
          itemStyle: {
            color: '#6D49D4',
            opacity: 0,
          },
        },
        {
          type: 'bar',
          color: '#CACBCD',
          data: halo?.haloByChannels.map((elem) => elem.controlConversions),
          label: {
            show: false,
          },
          barWidth: '-1px',
          itemStyle: {
            color: '#CACBCD',
            opacity: 0,
          },
        },
      ],
    };
  };

  const BAR_HEIGHT = 80;
  const height = halo && BAR_HEIGHT * halo.haloByChannels.length;

  const onSort = () => {
    pickHaloOrder(
      haloOrder === sortDirections.ascend ? sortDirections.descend : sortDirections.ascend,
    );
  };

  return (
    <div className="px-2 w-full">
      <div className="header bg-primary-gray-50 rounded">
        <div className="w-64 h-8 px-3 py-2 border-r border-primary-gray-100 justify-start items-center gap-1 inline-flex">
          <div className="justify-start items-center gap-1 flex">
            <div className="text-primary-gray-500 text-base-xs font-medium">Channel</div>
            <IconChevronSort
              width={20}
              height={20}
              className="text-primary-gray-500 cursor-pointer"
              onClick={onSort}
            />
          </div>
        </div>
        <div className="h-8 px-3 py-2 justify-start items-center gap-1 inline-flex">
          <div className="justify-start items-center gap-1 flex">
            <div className="text-primary-gray-500 text-base-xs font-medium">
              {Metrics[haloFilter as keyof typeof Metrics].label}
            </div>
          </div>
        </div>
      </div>
      {isHaloLoading ? (
        <div className="flex h-128 justify-center text-primary-gray-600">
          <LoadingOutlined spin />
        </div>
      ) : (
        <>
          <div className="h-128 inline-flex w-full justify-between align-middle border-b border-primary-gray-100 overflow-auto">
            <div className="border-r border-primary-gray-100">
              {halo?.haloByChannels.map((elem, index) => (
                <div
                  key={index}
                  className="w-[255px] h-20 px-3 flex items-center text-base-sm font-normal leading-tight"
                >
                  {elem.channel}
                </div>
              ))}
            </div>
            <div className="w-full">
              <Chart
                option={chartOption(halo!) as ChartOption}
                className="border-l border-primary-gray-100"
                style={{
                  height: height || 0,
                  width: '100%',
                }}
              />
            </div>
          </div>
          <div className="mr-5 ml-[256px] relative">
            <Chart
              style={{ height: '20px' }}
              option={createOption(halo!)}
              {...(halo &&
                halo?.haloByChannels.length > 1 && {
                  style: { height: '30px' },
                })}
            />
          </div>
        </>
      )}
    </div>
  );
};
