import { format } from 'date-fns';
import { useMemo } from 'react';

import { Chart, ChartOption } from 'components/common';

import { PixelChartProps } from './PixelVerificationTypes';

export const PixelChart = ({ data, children }: PixelChartProps) => {
  const option: ChartOption | null = useMemo(() => {
    const filtered = data?.filter((i) => i.date);

    return filtered
      ? {
          xAxis: {
            axisLabel: {
              formatter: (date: string) => format(new Date(date), 'MM/dd/yy'),
              showMinLabel: false,
              showMaxLabel: false,
            },
            data: filtered?.map((e) => e.date),
          },
          grid: {
            left: 0,
            top: '24px',
            right: 0,
            bottom: '32px',
          },
          tooltip: {
            axisPointer: {
              label: {
                formatter: ({ value }: { value: string }) => format(new Date(value), 'MMM d, y'),
              },
            },
            className: 'ctv-chart-tooltip',
          },
          series: [
            {
              name: 'Pixel fires:',
              color: '#6D49D4',
              data: filtered?.map((e) => e.events) || ['-'],
            },
          ],
        }
      : null;
  }, [data]);

  return (
    <div className="p-6 w-full">
      <div className="text-secondary-black text-base-sm font-semibold leading-snug">{children}</div>
      {option && <Chart option={option} />}
    </div>
  );
};
