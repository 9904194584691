export const Dimensions = {
  OVERALL: {
    label: 'Overall',
    value: 'OVERALL',
  },
  CREATIVE: {
    label: 'Creative',
    value: 'CREATIVE',
  },
  AUDIENCE: {
    label: 'Audience',
    value: 'AUDIENCE',
  },
  LINE_ITEM: {
    label: 'Ad group',
    value: 'LINE_ITEM',
  },
  MEDIA_TYPE: {
    label: 'Media type',
    value: 'MEDIA_TYPE',
  },
} as const;

export const Metrics = {
  LEADS: {
    label: 'Leads',
    value: 'LEADS',
  },
  CHECKOUTS: {
    label: 'Checkouts',
    value: 'CHECKOUTS',
  },
  PAGE_VISITS: {
    label: 'Page visits',
    value: 'PAGE_VISITS',
  },
} as const;
