import { Collapse as CollapseAntd, CollapseProps } from 'antd';
import classNames from 'classnames';
import { memo, ReactNode } from 'react';

import { Checkbox } from '../checkbox';

export const Collapse = memo(
  ({
    items,
    accordion,
    defaultActiveKey,
  }: Pick<CollapseProps, 'items' | 'accordion' | 'defaultActiveKey'>) => {
    return (
      <CollapseAntd
        defaultActiveKey={defaultActiveKey}
        expandIconPosition="end"
        expandIcon={() => <></>}
        className={classNames('')}
        items={items}
        accordion={accordion}
      />
    );
  },
);

Collapse.displayName = 'Collapse';

export const CollapseCheckboxLabel = memo(({ children }: { children: ReactNode }) => (
  <span onClick={(e) => e.stopPropagation()}>
    <Checkbox>{children}</Checkbox>
  </span>
));

CollapseCheckboxLabel.displayName = 'CollapseCheckboxLabel';
