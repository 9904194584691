// empty ${''} is added to avoid prettier formatting to format with doublequotes
// hardcoded keys and values should be wrapped in singlequotes
export const exampleValues = [
  { name: 'Order', value: `'order_number':${''} 'ORDER_NUMBER'` },
  { name: 'Amount', value: `'amount':${''} 'ORDER_AMOUNT'` },
  { name: 'Currency', value: `'currency':${''} 'CURRENCY'` },
];

export const categories = {
  pagevisit: {
    name: 'pagevisit',
    subcategories: ['homepage', 'productpages', 'findastore'],
  },
  activity: {
    name: 'activity',
    subcategories: ['login', 'coupondownload'],
  },
  leads: {
    name: 'leads',
    subcategories: ['signup', 'freetrial', 'registration', 'newslettersignup', 'contactus', 'demo'],
  },
  checkout: {
    name: 'checkout',
    subcategories: [],
  },
};
