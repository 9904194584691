export type Advertiser = {
  id: string;
  xandrId: string;
  name: string;
  industry: string;
  pixelCode: string;
  persons: string[];
  xandrAdvertiser: string;
  creationDate: string;
  modificationDate: string;
};

export type Agency = {
  id: string;
  name: string;
  timeZone: string;
  enabledCustomLogo: boolean;
  logoUrl: string;
  wordMarkUrl: string;
  advertisers: Advertiser[];
  creationDate: string;
  modificationDate: string;
};

export type PagingRequestValues = {
  page: number;
  size: number;
  sortBy?: string | null;
  direction?: string | null; // ASC, DESC
  advertiserId?: string | null;
  search?: string | null;
};

export type AdvertisersPagingRequestValues = {
  page: number;
  size: number;
  sortBy?: string | null;
  direction?: string | null; // ASC, DESC
  agencyId: string | null;
  search?: string | null;
};

export type AgenciesPagingResponse = {
  data: Agency[];
  totalCount: number;
};

export type AdvertisersPagingResponse = {
  data: Advertiser[];
  totalCount: number;
  totalPages: number;
};

export type CreateAdvertiserRequest = {
  name: string;
  industry?: string | null;
  agencyId: string;
};

export type UpdateAdvertiserRequest = {
  name: string;
  industry?: string | null;
  advertiserId: string;
};

export type CreateAgencyRequest = {
  name: string;
  timeZone: string;
};

export type UpdateAgencyRequest = {
  agencyId: string;
  name?: string | null;
  timeZone?: string | null;
  enabledCustomLogo?: boolean | null;
  logoUrl?: string | null;
  wordMark?: string | null;
};

export type Campaign = {
  advertiser: string | null;
  creationDate: string;
  displayName: string | null;
  draftIO: string | null;
  enabled: boolean;
  id: number;
  lineItems: string | null;
  modificationDate: string | null;
  name: string;
  new: boolean;
  synced: boolean;
};

export type OrganizationState = {
  agencies: Agency[];
  campaigns: Campaign[] | null;
  currentAgencyId: string;
  currentAgency: Agency;
  currentAdvertiserId: string;
  currentAdvertiser: Advertiser;
  isLoading: boolean;
  error: string | null;
  createdAgency: Agency | null;
  advertisers: AdvertisersPagingResponse | null;
};

export const defaultAdvertiserState: Advertiser = {
  id: '',
  xandrId: '',
  name: '',
  industry: '',
  pixelCode: '',
  persons: [],
  xandrAdvertiser: '',
  creationDate: '',
  modificationDate: '',
};

export const defaultAgencyState: Agency = {
  id: '',
  name: '',
  timeZone: '',
  enabledCustomLogo: false,
  logoUrl: '',
  wordMarkUrl: '',
  advertisers: [defaultAdvertiserState],
  creationDate: '',
  modificationDate: '',
};

export const findAgencyIdByAdvertiserId = (
  agencies: Agency[] | null,
  advertiserId: string,
): string => {
  if (agencies === null) return '';
  for (const agency of agencies) {
    for (const advertiser of agency.advertisers) {
      if (advertiser.id === advertiserId) {
        return agency.id;
      }
    }
  }

  return '';
};

export const findAgencyByAdvertiserId = (
  agencies: Agency[] | null,
  advertiserId: string,
): Agency => {
  if (agencies === null) throw Error('Agencies are empty');
  for (const agency of agencies) {
    for (const advertiser of agency.advertisers) {
      if (advertiser.id === advertiserId) {
        return agency;
      }
    }
  }

  throw new Error('Agency not found');
};

export const findAdvertiserByAdvertiserId = (
  agencies: Agency[] | null,
  advertiserId: string,
): Advertiser | null => {
  if (agencies === null) return null;
  for (const agency of agencies) {
    for (const advertiser of agency.advertisers) {
      if (advertiser.id === advertiserId) {
        return advertiser;
      }
    }
  }

  return null;
};
