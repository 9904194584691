import { format } from 'date-fns';

import { PixelEventCategory, PixelEventSubcategory } from 'modules/pixel/pixelApiTypes';

export const ALL_OPTION = 'all';

export type BasePayloadParams = {
  selectedCategory: string;
  selectedSubcategory: string;
  currentAdvertiserId: string;
  startDate: string;
  endDate: string;
};

export const createBasePayload = ({
  selectedCategory,
  selectedSubcategory,
  currentAdvertiserId,
  startDate,
  endDate,
}: BasePayloadParams) => {
  const category = selectedCategory !== ALL_OPTION ? { category: selectedCategory } : {};
  const subcategory =
    selectedSubcategory !== ALL_OPTION ? { subcategory: selectedSubcategory } : {};

  return {
    advertiserId: currentAdvertiserId,
    startDateTime: startDate,
    endDateTime: endDate,
    ...category,
    ...subcategory,
  };
};

export const getSubcategories = (
  selectedCategory: string,
  availableCategories: PixelEventCategory[],
) =>
  selectedCategory === ALL_OPTION
    ? availableCategories.flatMap((c) => c.subcategories)
    : availableCategories.find((c) => c.category === selectedCategory)?.subcategories || [];

export const formatSubcategories = (data: PixelEventSubcategory[]) =>
  data.map((sc) => ({ ...sc, category: sc?.subcategory }));

export const createEventRows = (data: PixelEventCategory[]) =>
  data.map((e) => ({ ...e, children: formatSubcategories(e?.subcategories) }));

export const formatTimeStamp = (date: string) => format(new Date(date), 'y-MM-dd HH:mm:ss');
export const valueOrDashFormatter = (value: string) => (value ? value : '-');
