/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

import {
  MembersPagingRequestValue,
  UpdateMemberRequestValues,
  User,
  UserInviteRequestValues,
  UserState,
} from './userApiTypes';

export const initialState: UserState = {
  isLoading: false,
  isInvited: false,
  error: null,
  members: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    inviteUserStart(state: UserState, action: PayloadAction<UserInviteRequestValues>) {
      state.isLoading = true;
      state.isInvited = false;
    },
    inviteUserSuccess(state: UserState) {
      state.isLoading = false;
      state.isInvited = true;
      state.error = null;
    },
    inviteUserFailure(state: UserState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    updateMemberStart(state: UserState, action: PayloadAction<UpdateMemberRequestValues>) {
      state.isLoading = true;
    },
    updateMemberSuccess(state: UserState, action: PayloadAction<User[]>) {
      state.isLoading = false;
      state.error = null;
      state.members = action.payload;
    },
    updateMemberFailure(state: UserState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    deleteUserStart(state: UserState, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    deleteUserSuccess(state: UserState, action: PayloadAction<User[]>) {
      state.isLoading = false;
      state.error = null;
      state.members = action.payload;
    },
    deleteUserFailure(state: UserState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getMembersStart(state: UserState, action: PayloadAction<MembersPagingRequestValue>) {
      state.isLoading = true;
    },
    getMembersSuccess(state: UserState, action: PayloadAction<User[]>) {
      state.isLoading = false;
      state.members = action.payload;
      state.error = null;
    },
    getMembersFailure(state: UserState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const getUserState = ({ user }: AppState) => user;
export const getIsUserLoading = (state: AppState) => getUserState(state).isLoading;
export const getError = (state: AppState) => getUserState(state).error;

export const {
  inviteUserStart,
  inviteUserSuccess,
  inviteUserFailure,
  updateMemberStart,
  updateMemberSuccess,
  updateMemberFailure,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
  getMembersStart,
  getMembersSuccess,
  getMembersFailure,
} = userSlice.actions;
export const user = userSlice.reducer;
