import { PayloadAction } from '@reduxjs/toolkit';
import { FetchUserAttributesOutput, ResetPasswordOutput, SignInOutput } from 'aws-amplify/auth';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getErrorMessage } from 'utils';

import { clearCurrentAdvertiser } from 'modules/organization/organizationSlice';
import { clearPerformanceState } from 'modules/performance/performanceSlice';
import { userApi } from 'modules/user/userApi';
import { User } from 'modules/user/userApiTypes';

import { localStorageService } from 'services/localStorage';
import { ADVERTISER_ID } from 'services/localStorage/constants';

import { authApi } from './authApi';
import {
  loginStart,
  loginFailure,
  loginSuccess,
  logout,
  signupFailure,
  signupStart,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  createNewPasswordStart,
  createNewPasswordFailure,
  updateUserpicStart,
  updateUserpicSuccess,
  updateUserpicFailure,
  updateUserSuccess,
  updateUserFailure,
  updateUserStart,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
} from './authSlice';
import { authStorage } from './authStorage';
import {
  AuthMessages,
  AuthResponse,
  AuthStepStatuses,
  ChangePasswordPayload,
  CreateNewPasswordPayload,
  ForgotPasswordPayload,
  Profile,
  SigninPayload,
  SignupPayload,
  UserRequestValues,
} from './types';

function* loginFlow(action: PayloadAction<SigninPayload>) {
  try {
    yield call(authApi.login, action.payload);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const profile: Profile = yield call(userApi.getUser);

    const attributes: FetchUserAttributesOutput = yield call(authApi.fetchAttributes);
    const authToken: string = yield call(authStorage.getIdToken);
    const userpic = `${attributes['custom:picture_url']}&idToken=${authToken}`;
    const response: AuthResponse = {
      user: {
        advertiserId: profile.advertiser,
        email: attributes.email,
        fullname: attributes['custom:full_name'],
        phone: attributes['custom:phone'],
        role: attributes['custom:role'],
        userpic: userpic,
        id: attributes.sub,
      },
    };

    yield put(loginSuccess(response));
    window.location.replace('/');
  } catch (err) {
    yield put(loginFailure(getErrorMessage(err)));
  }
}

function* signupFlow(action: PayloadAction<SignupPayload>) {
  try {
    const output: SignInOutput = yield call(authApi.login, {
      username: action.payload.username,
      password: action.payload.tempPassword,
    });

    if (!output.isSignedIn && output.nextStep.signInStep === AuthStepStatuses.CONFIRM_SIGN_IN) {
      yield call(authApi.confirmSignin, action.payload);
      const user: User = yield call(authStorage.getUser);

      yield call(userApi.updateUser, {
        subId: user.id || '',
        fullName: action.payload.fullname,
      });

      window.location.replace('/');
    } else {
      yield put(signupFailure(AuthMessages.NO_REGISTERED_USER));
    }
  } catch (err) {
    yield put(signupFailure(getErrorMessage(err)));
  }
}

function* forgotPasswordFlow(action: PayloadAction<ForgotPasswordPayload>) {
  try {
    const output: ResetPasswordOutput = yield call(authApi.forgotPassword, action.payload);

    if (output.nextStep.resetPasswordStep === AuthStepStatuses.CONFIRM_RESET_PASSWORD) {
      yield put(forgotPasswordSuccess());
    } else {
      yield put(forgotPasswordFailure(AuthMessages.NOT_SENT));
    }
  } catch (err) {
    yield put(forgotPasswordFailure(getErrorMessage(err)));
  }
}

function* createNewPasswordFlow(action: PayloadAction<CreateNewPasswordPayload>) {
  try {
    yield call(authApi.createNewPassword, action.payload);
    const loginPayload = {
      username: action.payload.username,
      password: action.payload.newPassword,
    };

    yield call(authApi.login, loginPayload);
    window.location.replace('/');
  } catch (err) {
    yield put(createNewPasswordFailure(getErrorMessage(err)));
  }
}

function* changePasswordFlow(
  action: PayloadAction<
    ChangePasswordPayload & { resolve: () => void; reject: (error: string) => void }
  >,
) {
  try {
    yield call(authApi.changePassword, action.payload);

    yield put(changePasswordSuccess());
    action.payload.resolve();
  } catch (err) {
    const message = getErrorMessage(err);

    yield put(changePasswordFailure(message));
    action.payload.reject(message);
  }
}

function* logoutFlow() {
  try {
    yield call(authApi.logout);
    yield put(clearPerformanceState());
    yield put(clearCurrentAdvertiser());
    localStorageService.removeItem(ADVERTISER_ID);
  } catch (err) {
    yield put(loginFailure(getErrorMessage(err)));
  }
}

function* updateUserpicFlow(action: PayloadAction<string>) {
  try {
    let userpic = action.payload;

    if (userpic) {
      const authToken: string = yield call(authStorage.getIdToken);

      userpic = `${action.payload}&idToken=${authToken}&timestamp=${Date.now()}`;
    }

    yield put(updateUserpicSuccess(userpic));
  } catch (err) {
    yield put(updateUserpicFailure(getErrorMessage(err)));
  }
}

function* updateUserFlow(action: PayloadAction<UserRequestValues>) {
  try {
    const user: User = yield call(userApi.updateUser, action.payload);

    yield put(updateUserSuccess(user));
  } catch (error) {
    yield put(updateUserFailure(getErrorMessage(error)));
  }
}

export function* authFlowSagas() {
  yield all([
    takeLatest(loginStart.type, loginFlow),
    takeLatest(logout.type, logoutFlow),
    takeLatest(signupStart.type, signupFlow),
    takeLatest(forgotPasswordStart.type, forgotPasswordFlow),
    takeLatest(updateUserStart.type, updateUserFlow),
    takeLatest(createNewPasswordStart.type, createNewPasswordFlow),
    takeLatest(updateUserpicStart.type, updateUserpicFlow),
    takeLatest(changePasswordStart.type, changePasswordFlow),
  ]);
}
