import { Navigate } from 'react-router';

import { useAuth } from 'modules/auth/useAuth';

import { ROUTES } from 'routing/constants';

type ProtectedRouteProps = {
  children: JSX.Element;
};

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <Navigate replace to={ROUTES.LOGIN} />;
  }

  return children;
};
