/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'redux/store/store';

import {
  Advertiser,
  AdvertisersPagingRequestValues,
  AdvertisersPagingResponse,
  Agency,
  Campaign,
  CreateAdvertiserRequest,
  CreateAgencyRequest,
  defaultAdvertiserState,
  defaultAgencyState,
  findAdvertiserByAdvertiserId,
  findAgencyIdByAdvertiserId,
  OrganizationState,
  UpdateAdvertiserRequest,
  UpdateAgencyRequest,
} from './organizationApiTypes';

export const initialState: OrganizationState = {
  agencies: [defaultAgencyState],
  campaigns: null,
  currentAgencyId: '',
  currentAgency: defaultAgencyState,
  currentAdvertiserId: '',
  currentAdvertiser: defaultAdvertiserState,
  isLoading: false,
  error: null,
  createdAgency: null,
  advertisers: null,
};

export const organizationSlice = createSlice({
  name: 'org',
  initialState,
  reducers: {
    getAgenciesStart(state: OrganizationState) {
      state.isLoading = true;
    },
    getAgenciesSuccess(state: OrganizationState, action: PayloadAction<Agency[]>) {
      state.isLoading = false;
      state.error = null;
      state.agencies = action.payload;
      if (!state.currentAgencyId && state.currentAdvertiserId) {
        state.currentAgencyId = findAgencyIdByAdvertiserId(
          action.payload,
          state.currentAdvertiserId,
        );
      }
    },
    getAgenciesFailure(state: OrganizationState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getCurrentAgencyStart(state: OrganizationState, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    getCurrentAgencySuccess(state: OrganizationState, action: PayloadAction<Agency>) {
      state.isLoading = false;
      state.error = null;
      state.currentAgency = action.payload;
    },
    getCurrentAgencyFailure(state: OrganizationState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getCurrentAdvertiserStart(state: OrganizationState, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    getCurrentAdvertiserSuccess(state: OrganizationState, action: PayloadAction<Advertiser>) {
      state.isLoading = false;
      state.error = null;
      state.currentAdvertiser = action.payload;
    },
    getCurrentAdvertiserFailure(state: OrganizationState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    createAdvertiserStart(
      state: OrganizationState,
      action: PayloadAction<CreateAdvertiserRequest>,
    ) {
      state.isLoading = true;
    },
    createAdvertiserSuccess(state: OrganizationState, action: PayloadAction<Agency[]>) {
      state.isLoading = false;
      state.error = null;
      state.agencies = action.payload;
    },
    createAdvertiserFailure(state: OrganizationState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    updateAdvertiserStart(
      state: OrganizationState,
      action: PayloadAction<UpdateAdvertiserRequest>,
    ) {
      state.isLoading = true;
    },
    updateAdvertiserSuccess(state: OrganizationState, action: PayloadAction<Agency[]>) {
      state.isLoading = false;
      state.error = null;
      state.agencies = action.payload;
    },
    updateAdvertiserFailure(state: OrganizationState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    getCampaignsStart(state: OrganizationState, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    getCampaignsSuccess(state: OrganizationState, action: PayloadAction<Campaign[]>) {
      state.isLoading = false;
      state.error = null;
      state.campaigns = action.payload;
    },
    getCampaignsFailure(state: OrganizationState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    createAgencyStart(state: OrganizationState, action: PayloadAction<CreateAgencyRequest>) {
      state.isLoading = true;
    },
    createAgencySuccess(state: OrganizationState, action: PayloadAction<Agency>) {
      state.isLoading = false;
      state.error = null;
      state.agencies = state.agencies.concat(action.payload);
      state.createdAgency = action.payload;
    },
    createAgencyFailure(state: OrganizationState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    updateAgencyStart(state: OrganizationState, action: PayloadAction<UpdateAgencyRequest>) {
      state.isLoading = true;
    },
    updateAgencySuccess(state: OrganizationState, action: PayloadAction<Agency[]>) {
      state.isLoading = false;
      state.error = null;
      state.agencies = action.payload;
    },
    updateAgencyFailure(state: OrganizationState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    setCurrentAdvertiser(state: OrganizationState, action: PayloadAction<string>) {
      state.currentAdvertiserId = action.payload;
      state.currentAgencyId = findAgencyIdByAdvertiserId(state.agencies, action.payload);
    },
    clearCurrentAdvertiser(state: OrganizationState) {
      state.currentAdvertiserId = '';
      state.currentAgencyId = '';
    },
    deleteCreatedAgency(state: OrganizationState) {
      state.createdAgency = null;
    },
    getAdvertisersStart(
      state: OrganizationState,
      action: PayloadAction<AdvertisersPagingRequestValues>,
    ) {
      state.isLoading = true;
    },
    getAdvertisersSuccess(
      state: OrganizationState,
      action: PayloadAction<AdvertisersPagingResponse>,
    ) {
      state.isLoading = false;
      state.error = null;
      state.advertisers = action.payload;
    },
    getAdvertisersFailure(state: OrganizationState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const getOrganizationState = ({ org }: AppState) => org;
export const getCurrentAgency = (state: AppState) => getOrganizationState(state).currentAgency;
export const getIsOrgLoading = (state: AppState) => getOrganizationState(state).isLoading;
export const getError = (state: AppState) => getOrganizationState(state).error;
export const getCurrentAgencyId = (state: AppState) => getOrganizationState(state).currentAgencyId;
export const getCurrentAdvertiserId = (state: AppState) =>
  getOrganizationState(state).currentAdvertiserId;
export const getCreatedAgency = (state: AppState) => getOrganizationState(state).createdAgency;
export const getCurrentAdvertiser = (state: AppState) =>
  findAdvertiserByAdvertiserId(
    getOrganizationState(state).agencies,
    getOrganizationState(state).currentAdvertiserId,
  );
export const getAdvertiserSelector = (state: AppState) => getOrganizationState(state).advertisers;

export const {
  getAgenciesStart,
  getAgenciesSuccess,
  getAgenciesFailure,
  getCurrentAgencyStart,
  getCurrentAgencySuccess,
  getCurrentAgencyFailure,
  getCurrentAdvertiserStart,
  getCurrentAdvertiserSuccess,
  getCurrentAdvertiserFailure,
  getCampaignsStart,
  getCampaignsSuccess,
  getCampaignsFailure,
  updateAdvertiserStart,
  updateAdvertiserSuccess,
  updateAdvertiserFailure,
  createAdvertiserStart,
  createAdvertiserSuccess,
  createAdvertiserFailure,
  createAgencyStart,
  createAgencySuccess,
  createAgencyFailure,
  updateAgencyStart,
  updateAgencySuccess,
  updateAgencyFailure,
  setCurrentAdvertiser,
  deleteCreatedAgency,
  getAdvertisersStart,
  getAdvertisersSuccess,
  getAdvertisersFailure,
  clearCurrentAdvertiser,
} = organizationSlice.actions;
export const org = organizationSlice.reducer;
