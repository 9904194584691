import { useState, useCallback, useRef } from 'react';

export const usePolling = <T,>(
  interval: number,
  stopCondition: (result: T) => boolean,
  maxRetries: number = 50,
) => {
  const [isPolling, setIsPolling] = useState(false);
  const pollControllerRef = useRef<{ stop: () => void } | null>(null);

  const sleep = () => new Promise((resolve) => setTimeout(resolve, interval));

  const poll = async (fn: () => Promise<T>, shouldContinue: () => boolean) => {
    let attemts = 0;

    while (shouldContinue()) {
      try {
        const result = await fn();

        if (stopCondition(result) || attemts >= maxRetries) {
          stopPolling();

          return result;
        }
        attemts++;
      } catch (error) {
        // console.error(error);
      }

      await sleep();
    }
  };

  const startPolling = useCallback(
    (fn: () => Promise<T>) => {
      if (isPolling) return;

      let continuePolling = true;

      setIsPolling(true);

      pollControllerRef.current = {
        stop: () => {
          continuePolling = false;
          setIsPolling(false);
        },
      };

      return poll(fn, () => continuePolling);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [interval, isPolling, stopCondition],
  );

  const stopPolling = useCallback(() => {
    if (pollControllerRef.current) {
      pollControllerRef.current.stop();
    }
  }, []);

  return { isPolling, startPolling, stopPolling };
};
