import { keyPrefix } from './constants';

class LocalStorage {
  public setItem(key: string, value: unknown): void {
    localStorage.setItem(composeKeyName(key), JSON.stringify(value));
  }

  getItem<T>(key: string): T | null;
  getItem<T>(key: string, defaultValue: T): T;
  getItem<T>(key: string, defaultValue?: T): T | null {
    const data: string | null = localStorage.getItem(composeKeyName(key));

    if (data !== null) {
      return JSON.parse(data);
    }

    if (defaultValue) {
      return defaultValue;
    }

    return null;
  }
  removeItem(key: string): void {
    localStorage.removeItem(composeKeyName(key));
  }
  removeAll(): void {
    localStorage.clear();
  }
}

export const composeKeyName = (key: string) => `${keyPrefix}_${key}`;

export const localStorageService = new LocalStorage();
