import classNames from 'classnames';

import { ReactComponent as CheckCircle } from 'assets/images/icons/check-circle.svg';

export const PassWordRulesBlock = ({ password = '' }: { password: string }) => {
  const hasValidLength = (s: string) => s.length >= 8;
  const hasNumber = (s: string) => /\d/.test(s);
  const hasUpperCase = (s: string) => s !== s.toLowerCase();
  const hasLowerCase = (s: string) => s !== s.toUpperCase();

  return (
    <div className="w-full p-3 bg-primary-gray-50 rounded-lg border border-primary-gray-100 flex-col justify-start items-start gap-3 inline-flex ctv-password-rules">
      <div
        className={classNames('rule-block', {
          valid: hasValidLength(password),
        })}
      >
        <CheckCircle className="w-5 h-5 relative" />
        <div className="rule-text ">8 characters min.</div>
      </div>
      <div
        className={classNames('rule-block', {
          valid: hasNumber(password),
        })}
      >
        <CheckCircle className="w-5 h-5 relative" />
        <div className="rule-text">1 number</div>
      </div>
      <div
        className={classNames('rule-block', {
          valid: hasUpperCase(password),
        })}
      >
        <CheckCircle className="w-5 h-5 relative" />
        <div className="rule-text">1 Uppercase</div>
      </div>
      <div
        className={classNames('rule-block', {
          valid: hasLowerCase(password),
        })}
      >
        <CheckCircle className="w-5 h-5 relative" />
        <div className="rule-text">1 Lowercase</div>
      </div>
    </div>
  );
};

PassWordRulesBlock.displayName = 'PassWordRulesBlock';
