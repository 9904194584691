import { Modal as ModalAntd, ModalProps } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';

export type { ModalProps };

export const Modal = memo(({ className, ...rest }: ModalProps) => (
  <ModalAntd className={classNames('ctv-modal', className)} {...rest} maskClosable={true} />
));
Modal.displayName = 'Modal';
