export const restrictionDimensionPair = {
  CAMPAIGN: ['CAMPAIGN'],
  MEDIA_TYPE: ['MEDIA_TYPE', 'CAMPAIGN'],
  LINE_ITEM: ['LINE_ITEM', 'MEDIA_TYPE', 'CAMPAIGN'],
  AUDIENCE: ['AUDIENCE', 'LINE_ITEM', 'MEDIA_TYPE', 'CAMPAIGN'],
  CREATIVE: ['CREATIVE', 'AUDIENCE', 'LINE_ITEM', 'MEDIA_TYPE', 'CAMPAIGN'],
  PUBLISHER: ['PUBLISHER', 'MEDIA_TYPE', 'CAMPAIGN'],
};

export const visibleDimensions = [
  'CREATIVE',
  'AUDIENCE',
  'LINE_ITEM',
  'MEDIA_TYPE',
  'CAMPAIGN',
  'PUBLISHER',
];

export const visibleSubcategories = ['LEADS', 'PAGE_VISITS'];
