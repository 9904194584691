import { Tag } from 'antd';
import { TagProps as TagPropsAntd } from 'antd/es/tag';
import classNames from 'classnames';

import { ctvTwMerge } from 'services/twMerge/ctvTwMerge';

type ActiveProps = {
  active?: boolean;
};

type TagProps = ActiveProps & TagPropsAntd;

const preventDefault = (e: React.MouseEvent<HTMLElement>) => {
  e.preventDefault();
};

export const Chip = ({ className, children, active = false, onClose, ...rest }: TagProps) => (
  <Tag
    className={ctvTwMerge(
      classNames(
        'ctv-chip',
        {
          'ctv-chip-active': active,
        },
        className,
      ),
    )}
    closable
    onClose={(e) => {
      preventDefault(e);
      onClose && onClose(e);
    }}
    {...rest}
  >
    <div className="ctv-chip-text">{children}</div>
  </Tag>
);
