/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getErrorMessage } from 'utils';

import { miscApi } from './miscApi';
import { Constants, DeviceTypes, MediaTypes } from './miscApiTypes';
import {
  getConstantsFailure,
  getConstantsStart,
  getConstantsSuccess,
  getDeviceTypesFailure,
  getDeviceTypesStart,
  getMediaTypesFailure,
  getMediaTypesStart,
  getMediaTypesSuccess,
} from './miscSlice';

function* getCostantsFlow() {
  try {
    const response: Constants = yield call(miscApi.getConstants);

    yield put(getConstantsSuccess(response));
  } catch (error) {
    yield put(getConstantsFailure(getErrorMessage(error)));
  }
}

function* getMediaTypesFlow() {
  try {
    const response: MediaTypes = yield call(miscApi.getMediaTypes);

    yield put(getMediaTypesSuccess(response));
  } catch (error) {
    yield put(getMediaTypesFailure(getErrorMessage(error)));
  }
}

function* getDeviceTypesFlow() {
  try {
    const response: DeviceTypes = yield call(miscApi.getDeviceTypes);

    yield put(getMediaTypesSuccess(response));
  } catch (error) {
    yield put(getDeviceTypesFailure(getErrorMessage(error)));
  }
}

export function* miscSagas() {
  yield all([
    takeLatest(getConstantsStart.type, getCostantsFlow),
    takeLatest(getMediaTypesStart.type, getMediaTypesFlow),
    takeLatest(getDeviceTypesStart.type, getDeviceTypesFlow),
  ]);
}
