import { ReactNode, useEffect, useState } from 'react';

import { ReactComponent as IconArrow } from 'assets/images/icons/arrow-right.svg';

import { Sider } from 'components/common';

export const PerformanceSidebar = ({
  onCollapse,
  children,
}: {
  onCollapse?: (collapsed: boolean) => void;
  children: ReactNode;
}) => {
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    onCollapse && setTimeout(() => onCollapse(collapsed), 250);
  }, [collapsed, onCollapse]);

  return (
    <Sider
      breakpoint="sm"
      className="w-80 h-[556px] p-2 bg-primary-gray-50 shadow-none rounded-lg"
      width={collapsed ? '56px' : '320px'}
      trigger={null}
      collapsible
      reverseArrow
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div
        className={`flex cursor-pointer ${collapsed ? 'items-center justify-center' : 'justify-end'}`}
      >
        <IconArrow
          className={`w-5 h-5 m-2 ${collapsed ? 'rotate-180' : ''}`}
          onClick={() => setCollapsed(!collapsed)}
        />
      </div>
      {!collapsed && children}
    </Sider>
  );
};
