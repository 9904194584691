/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { expandConstants } from 'utils';

import { getCurrentAdvertiserId } from 'modules/organization/organizationSlice';
import {
  setAdvancedConfiguration,
  setDefaultAdvancedConfiguration,
} from 'modules/performance/performanceSlice';

import { selectConstants, useApiDataSlicePrefetch } from './dataApiSlice';

export const useDataPrefetch = () => {
  const dispatch = useDispatch();
  const prefetchConstants = useApiDataSlicePrefetch('constants');
  const prefetchMediaTypes = useApiDataSlicePrefetch('mediaTypes');
  const prefetchDeviceTypes = useApiDataSlicePrefetch('deviceTypes');
  const prefetchAudiences = useApiDataSlicePrefetch('audiences');
  const prefetchPublishers = useApiDataSlicePrefetch('publishers');
  const prefetchCreatives = useApiDataSlicePrefetch('creatives');
  const prefetchLineItems = useApiDataSlicePrefetch('lineItems');
  const prefetchSubcategories = useApiDataSlicePrefetch('subcategories');
  const prefetchInsertionOrders = useApiDataSlicePrefetch('insertionOrders');

  const advertiserId = useSelector(getCurrentAdvertiserId);
  const constants = useSelector(selectConstants);

  useEffect(() => {
    prefetchConstants(null);
    prefetchMediaTypes(null);
    prefetchDeviceTypes(null);
    prefetchPublishers(null);

    if (advertiserId) {
      prefetchCreatives(advertiserId);
      prefetchAudiences(advertiserId);
      prefetchInsertionOrders(advertiserId);
      prefetchLineItems(advertiserId);
      prefetchSubcategories(advertiserId);
    }
  }, [
    prefetchConstants,
    prefetchDeviceTypes,
    prefetchMediaTypes,
    prefetchAudiences,
    prefetchCreatives,
    prefetchInsertionOrders,
    prefetchPublishers,
    prefetchLineItems,
    prefetchSubcategories,
    advertiserId,
  ]);

  useEffect(() => {
    if (constants) {
      const configuration = {
        methodology: expandConstants(constants.MethodologyOptions).LAST_TOUCH.value,
        showUniques: constants.AttributionSettings.TOTAL.value,
        conversionWindow: constants.ConversionWindow[constants.ConversionWindow.length - 1],
      };

      dispatch(setDefaultAdvancedConfiguration(configuration));
      dispatch(setAdvancedConfiguration(configuration));
    }
  }, [constants]);
};
