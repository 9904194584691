import { Button as ButtonAntd } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';

import { buttonVariants } from './buttonVariants';
import { ButtonProps } from './types';

export const Button = memo(
  ({
    children,
    disabled,
    isLoading,
    variant,
    size,
    className,
    Icon,
    ...rest
  }: Omit<ButtonProps, 'type'>) => {
    const { buttonBody, text, icon } = buttonVariants({ color: variant, size });

    return (
      <ButtonAntd
        className={classNames(buttonBody(), className)}
        disabled={disabled}
        loading={isLoading}
        {...rest}
      >
        {isLoading ? (
          ''
        ) : (
          <div className="justify-center items-center inline-flex gap-1.5 ">
            {Icon && <Icon className={icon()} width="2em" height="2em" />}
            <span className={text()}>{children}</span>
          </div>
        )}
      </ButtonAntd>
    );
  },
);
Button.displayName = 'Button';
