import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

import {
  Button,
  Field, //  Indicator,
  Input,
  Progress,
} from 'components/common';

import { useOrganization } from 'modules/organization/useOrganization';

import { AdvertiserFormInputs, CreateAdvertiserFormProps } from './agencySettingsTypes';

const MAX_LENGTH = 30;

const schema = object().shape({
  name: string().required('Advertisert name is required'),
});

export const CreateAdvertiserForm = ({
  onClose,
  setCurrentStep,
  currentStep,
  agencyData,
}: CreateAdvertiserFormProps) => {
  const [advertiserName, setAdvertiserName] = useState<string>('');
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<AdvertiserFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
    },
  });

  const { createAdvertiser, removeCreatedAgency, createdAgency, createAgency } = useOrganization();

  const handleFormSubmit = handleSubmit(({ name }) => {
    setAdvertiserName(name);
    createAgency(agencyData);
  });

  useEffect(() => {
    if (createdAgency && advertiserName) {
      createAdvertiser({
        name: advertiserName,
        agencyId: createdAgency.id,
      });
      removeCreatedAgency();
      onClose();
    }
  }, [createdAgency, createAdvertiser, advertiserName, removeCreatedAgency, onClose]);

  return (
    <>
      <div className="self-stretch px-6 justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 py-5 flex-col justify-start items-start gap-2 inline-flex">
          <h1 className="self-stretch text-base-md font-medium leading-snug text-secondary-black">
            Create new advertiser
          </h1>
        </div>
      </div>
      <div className="px-6 pt-3 pb-6 gap-6 stretch-to-form">
        <div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
          <form
            onSubmit={handleFormSubmit}
            className="w-full gap-4 flex flex-col justify-start items-start"
          >
            <Field
              label={'Advertiser name'}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder="Enter advertiser name..."
                  maxLength={MAX_LENGTH}
                />
              )}
              control={control}
              name={'name'}
              error={errors.name?.message}
              secondaryLabel={
                <div className="text-base-xs leading-tight font-medium flex items-center text-primary-gray-500">
                  {`${watch('name').length}/${MAX_LENGTH}`}
                </div>
              }
            />
          </form>
          {/* <div className="stretch-to-form gap-3">
            {errorText && (
              <Indicator variant="danger" size="lg">
                {errorText}
              </Indicator>
            )}
          </div> */}
        </div>
      </div>
      <div className="self-stretch h-16 px-6 border-t border-primary-gray-100 justify-between items-center flex">
        <div className="items-center justify-between w-full flex">
          <Progress currentStep={currentStep} steps={2} />
          <div className="justify-end items-center gap-2 flex">
            <Button
              size="md"
              variant="secondary"
              onClick={() => setCurrentStep(1)}
              className="w-20"
            >
              Back
            </Button>
            <Button size="md" variant="primary" disabled={!isValid} onClick={handleFormSubmit}>
              Create
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
