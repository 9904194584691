import { Skeleton as SkeletonAntd, SkeletonProps } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';

import { ctvTwMerge } from 'services/twMerge/ctvTwMerge';

export const Skeleton = memo(({ className, ...rest }: SkeletonProps) => {
  return <SkeletonAntd className={ctvTwMerge(classNames('ctv-skeleton'), className)} {...rest} />;
});

Skeleton.displayName = 'Skeleton';
