/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDataPrefetch } from 'modules/apiData/useDataPrefetch';

const Root = ({ children }: any) => {
  useDataPrefetch();

  return <div>{children}</div>;
};

export default Root;
