export const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*$/;

export const paginationOptions = [10, 25, 50, 100];

export const METRIC_COLORS = {
  SPEND: '#6D49D4', // Spend
  REVENUE: '#E55E79', // Revenue
  ROAS: '#1135F3', // ROAS
  CHECKOUTS: '#EB1EEF', // Checkouts
  CPA_CHECKOUTS: '#00DCC2', // CPA (Checkouts)
  CR_CHECKOUTS: '#FAC20A', // Conversion rate (Checkouts)
  LEADS: '#FF9E2C', // Leads
  CPA_LEADS: '#AE58C5', // CPA (Leads)
  CR_LEADS: '#07A0D3', // Conversion rate (Leads)
  PAGE_VISITS: '#ABD601', // Page visits
  CPA_PAGE_VISITS: '#42189D', // CPA (Page visits)
  PCT_25: '#E7D445', // Video completion 25%
  PCT_50: '#6BB2A5', // Video completion 50%
  PCT_75: '#101A74', // Video completion 75%
  PCT_100: '#9B2111', // Video completion
  VCR: '#76ABE0', // Video completion rate
  CLICKS: '#FF2E00', // Clicks
  CTR: '#00A571', // CTR
  CPC: '#1AE1E8', // CPC
  IMPRESSIONS: '#00B2FF', // Impressions
  CPM: '#71EA12', // CPM
  REACH: '#FF6200', // Reach
  FREQUENCY: '#075A5F', // Frequency
};

export type MetricKeys = keyof typeof METRIC_COLORS;
