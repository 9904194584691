import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import classNames from 'classnames';
import { memo } from 'react';
import * as React from 'react';

import { ReactComponent as IconChevronSort } from 'assets/images/icons/chevron-selector-vertical.svg';

import { GridPagination } from 'components/common/gridPagination';

import { ctvTwMerge } from 'services/twMerge/ctvTwMerge';

export const Grid = memo(({ className, rows, columns, ...rest }: DataGridProps) => (
  <DataGrid
    rows={rows}
    columns={columns}
    className={ctvTwMerge(classNames('ctv-grid'), className)}
    disableColumnMenu
    disableColumnResize
    disableRowSelectionOnClick
    autoHeight
    slots={{
      columnSortedAscendingIcon: IconChevronSort,
      columnSortedDescendingIcon: IconChevronSort,
      pagination: () => <GridPagination />,
    }}
    {...rest}
  />
));

Grid.displayName = 'Grid';
