/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers } from '@reduxjs/toolkit';

import { auth } from 'modules/auth/authSlice';
import { misc } from 'modules/misc/miscSlice';
import { org } from 'modules/organization/organizationSlice';
import { performance } from 'modules/performance/performanceSlice';
import { pixel } from 'modules/pixel/pixelSlice';
import { report } from 'modules/report/reportSlice';
import { user } from 'modules/user/userSlice';
import { xandr } from 'modules/xandr/xandrSlice';

import { ctvApi } from './rootApiSlice';

export const createRootReducer = () => {
  return combineReducers({
    auth,
    user,
    org,
    xandr,
    performance,
    misc,
    pixel,
    report,
    [ctvApi.reducerPath]: ctvApi.reducer,
  });
};
