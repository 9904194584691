import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { object, string, ref } from 'yup';

import {
  Field,
  Wordmark,
  VideoBackground,
  PasswordInput,
  PassWordRulesBlock,
  Button,
  Indicator,
} from 'components/common';
import { passwordRegex } from 'components/constants';

import { PasswordConfirmation } from 'modules/auth/types';
import { useAuth } from 'modules/auth/useAuth';

interface PasswordsType extends PasswordConfirmation {
  newPassword: string;
}

export const CreateNewPasswordPage = () => {
  const { createNewPassword, isLoading, errorText } = useAuth();
  const [searchParams] = useSearchParams();

  const schema = object()
    .shape({
      newPassword: string()
        .min(8, 'Password does not meet the requirements.')
        .matches(passwordRegex, 'Password does not meet the requirements.')
        .required('New password is required'),
      confirmPassword: string()
        .required('Confirm password is required')
        .oneOf([ref('newPassword')], 'Your passwords do not match.'),
    })
    .required();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<PasswordsType>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  // Querystring should be like this: http://localhost:3000/createnewpassword?code=343443&email=aderke@gmail.com&company=coolmedia
  // Info for Create New Password email template
  const handleFormSubmit = handleSubmit((data) => {
    //parsing manually to avoid escaping + sign
    const email = searchParams
      .toString()
      .split('&')
      .find((s) => s.startsWith('email'))
      ?.slice(6);

    const submitData = {
      ...data,
      confirmationCode: searchParams.get('code') || '',
      username: decodeURIComponent(email || ''),
    };

    createNewPassword(submitData);
  });

  return (
    <VideoBackground>
      <div className="dialog w-128 p-10 bg-white rounded-xl shadow flex-col justify-start items-center inline-flex">
        <div className="stretch-to-form gap-12">
          <Wordmark variant="gradient-dark" className="w-40 h-7" />
          <form onSubmit={handleFormSubmit} className="w-full">
            <div className="stretch-to-form gap-10">
              <div className=" stretch-to-form gap-3">
                <h1 className="self-stretch text-heading-4xl font-bold leading-snug text-secondary-black">
                  Create a new password.
                </h1>
              </div>
              <div className="stretch-to-form gap-3">
                <div className="stretch-to-form gap-6">
                  <div className="stretch-to-form gap-1.5">
                    <Field
                      label={'New password'}
                      render={({ field, fieldState: { invalid } }) => (
                        <PasswordInput {...field} invalid={invalid} />
                      )}
                      name="newPassword"
                      control={control}
                      error={errors.newPassword?.message}
                    />
                    <PassWordRulesBlock password={watch('newPassword')} />
                  </div>

                  <Field
                    label={'Confirm password'}
                    render={({ field, fieldState: { invalid } }) => (
                      <PasswordInput {...field} invalid={invalid} />
                    )}
                    name="confirmPassword"
                    control={control}
                    error={errors.confirmPassword?.message}
                  />
                </div>
                {errorText && (
                  <Indicator variant="danger" size="lg">
                    {errorText}
                  </Indicator>
                )}
              </div>
              <Button
                size="lg"
                variant="primary"
                isLoading={isLoading}
                disabled={!isValid}
                className="w-full"
                onClick={handleFormSubmit}
              >
                Save and login
              </Button>
            </div>
          </form>
        </div>
      </div>
    </VideoBackground>
  );
};
