import * as echarts from 'echarts';
import type { EChartOption, EChartsType, TooltipComponentOption } from 'echarts';
import React, { useEffect, useRef, type CSSProperties } from 'react';

type SeriesLine = EChartOption.SeriesLine & {
  color: string;
  itemStyle?: EChartOption.SeriesLine['itemStyle'] & {
    color?: EChartOption.LinearGradient | string;
  };
  tooltip?: TooltipComponentOption;
  barWidth?: string;
  barGap?: string;
};

type SeriesBar = EChartOption.SeriesBar & {
  color: string;
  itemStyle?: EChartOption.SeriesBar['itemStyle'] & {
    color?: EChartOption.LinearGradient | string;
  };
  tooltip?: TooltipComponentOption;
  barWidth?: string | number;
  barGap?: string;
};

export type ChartOption = Omit<EChartOption, 'series'> & {
  series: SeriesLine[] | SeriesBar[];
};

interface EChartsComponentProps {
  option: ChartOption;
  style?: CSSProperties;
  className?: string;
}

const getBaseItemStyleColor = (color: string): EChartOption.LinearGradient => ({
  global: false,
  type: 'linear',
  x: 0,
  y: 0,
  x2: 0,
  y2: 1,
  colorStops: [
    {
      offset: 0,
      color,
    },
    {
      offset: 1,
      color: 'white',
    },
  ],
});

const baseOption: ChartOption = {
  grid: {
    top: 4,
    left: 0,
    right: 0,
  },
  tooltip: {
    trigger: 'axis',
    backgroundColor: '#3b3c3d',
    borderColor: '#3b3c3d',
    textStyle: {
      color: '#f1f1f2',
    },
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLabel: {
      margin: 16,
      color: '#8c8e92',
      showMinLabel: false,
      showMaxLabel: false,
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      show: false,
    },
  },
  axisPointer: {
    lineStyle: {
      color: '#3b3c3d',
      type: 'solid',
    },
  },
  series: [
    {
      type: 'line',
      smooth: true,
      color: 'black',
      cursor: 'default',
      showSymbol: false,
      areaStyle: {
        opacity: 0.25,
      },
      lineStyle: {
        width: 2,
        color: '',
      },
      itemStyle: {
        color: getBaseItemStyleColor('black'),
      },
    },
  ],
};

export const Chart: React.FC<EChartsComponentProps> = ({
  option,
  style = {
    height: '400px',
    width: '100%',
  },
  className,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let chartInstance: EChartsType | undefined;

    if (chartRef.current) {
      chartInstance = echarts.init(chartRef.current);
    }

    const [defaultSeriesOption] = baseOption.series;
    const chartOption: EChartOption = {
      ...baseOption,
      ...option,
      grid: { ...baseOption.grid, ...option.grid },
      tooltip: { ...baseOption.tooltip, ...option.tooltip },
      xAxis: { ...baseOption.xAxis, ...option.xAxis },
      yAxis: { ...baseOption.yAxis, ...option.yAxis },
      series: option.series.map((series) => {
        const { color, ...restSeries } = series;
        const seriesOption = { ...defaultSeriesOption, ...restSeries };

        if (color) {
          if ((seriesOption.itemStyle?.color as EChartOption.LinearGradient)?.colorStops?.length) {
            seriesOption.itemStyle = {
              ...seriesOption.itemStyle,
              color: getBaseItemStyleColor(color),
            };
          }
          if ('lineStyle' in seriesOption) {
            if (seriesOption.lineStyle) {
              seriesOption.lineStyle = {
                ...seriesOption.lineStyle,
                color,
              };
            }
          }
        }

        return seriesOption;
      }),
    };

    if (chartInstance) {
      chartInstance.setOption(chartOption);
    }

    const handleResize = () => {
      if (chartInstance) {
        chartInstance.resize();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (chartInstance) {
        chartInstance.dispose();
      }
    };
  }, [option]);

  return <div className={className} ref={chartRef} style={style}></div>;
};
