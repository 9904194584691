import { TableProps } from 'antd';
import classNames from 'classnames';

import { ReactComponent as IconChevronSort } from 'assets/images/icons/chevron-selector-vertical.svg';

import { Table } from 'components/common';

import { EventTableProps, TableData } from './PixelVerificationTypes';
import { createEventRows } from './utils';

export const EventsTable = ({ data }: EventTableProps) => {
  const categoryCell = (value: string, { children }: TableData) => (
    <div
      className={classNames('pr-3 grow shrink basis-0 text-base-sm font-normal leading-tight', {
        'pl-6': !children,
      })}
    >
      {value}
    </div>
  );

  const eventColumns: TableProps<TableData>['columns'] = [
    {
      dataIndex: 'category',
      title: 'Event',
      width: 256,
      fixed: 'left',
      sorter: (a, b) =>
        a.children && b.children ? ('' + a.category).localeCompare(b.category || '') : 0,
      sortIcon: () => <IconChevronSort width={20} height={20} />,
      showSorterTooltip: false,
      render: categoryCell,
    },
    {
      dataIndex: 'totalEvents',
      title: 'Total events',
    },
  ];

  const rows = data && createEventRows(data);

  return rows && rows.length ? (
    <div className="grid w-full px-5 mb-5 xandr-events-table-wrapper">
      <Table
        dataSource={rows}
        columns={eventColumns}
        pagination={false}
        expandable={{
          defaultExpandAllRows: true,
          showExpandColumn: false,
          expandedRowClassName: () => 'ctv-expanded-row',
        }}
      />
    </div>
  ) : null;
};
