/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';

import { PixelEventsRequest, PixelRequest } from './pixelApiTypes';
import {
  getPixelCategoriesStart,
  getPixelDailyEventsStart,
  getPixelEventsStart,
  getPixelExportStart,
  getPixelState,
  getPixelWidgetStart,
} from './pixelSlice';

export const usePixel = () => {
  const dispatch = useDispatch();
  const state = useSelector(getPixelState);
  const isLoading = state.isLoading;
  const errorText = state.error;
  const widget = state.widget;
  const events = state.events;
  const eventsDaily = state.eventsDaily;
  const categories = state.categories;

  function getPixelWidget(payload: PixelRequest) {
    dispatch(getPixelWidgetStart(payload));
  }

  function getPixelEvents(payload: PixelEventsRequest) {
    dispatch(getPixelEventsStart(payload));
  }

  function getPixelCategories(payload: PixelRequest) {
    dispatch(getPixelCategoriesStart(payload));
  }

  function getPixelExport() {
    dispatch(getPixelExportStart());
  }

  function getPixelDailyEvents(payload: PixelRequest) {
    dispatch(getPixelDailyEventsStart(payload));
  }

  return {
    getPixelWidget,
    getPixelEvents,
    getPixelCategories,
    getPixelExport,
    getPixelDailyEvents,
    errorText,
    isLoading,
    widget,
    events,
    eventsDaily,
    categories,
  };
};
