/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox as CheckboxAntd, CheckboxProps } from 'antd';
import {
  CheckboxGroupProps as CheckboxGroupPropsAntd,
  CheckboxChangeEvent,
} from 'antd/es/checkbox';
import { CheckboxOptionType } from 'antd/lib/checkbox/Group';
import classNames from 'classnames';
import { memo, useCallback, useEffect, useState } from 'react';

import { ctvTwMerge } from 'services/twMerge/ctvTwMerge';

type CheckboxGroupProps = {
  onChange: (value: CheckboxOptionType[]) => void;
  column?: boolean;
  selectAllLabel?: string;
  handleSelectAll?: (values: string[]) => void;
  options: CheckboxOptionType<string | number>[];
};

export const CheckboxGroup = memo(
  ({
    children,
    className,
    onChange,
    column = false,
    selectAllLabel = 'Select All',
    handleSelectAll,
    options,
    ...rest
  }: Omit<CheckboxGroupPropsAntd, 'onChange' | 'options'> & CheckboxGroupProps) => {
    const [isAllSelected, setIsAllSelected] = useState(false);

    useEffect(() => {
      setIsAllSelected(rest.value?.length === options.length || isAllSelected);
    }, [options]);

    const handleChange = useCallback(
      (values: CheckboxOptionType[]) => {
        onChange(values);
        setIsAllSelected(values.length === options.length);
      },
      [onChange, options],
    );

    const handleAll = (e: CheckboxChangeEvent) => {
      const isChecked = e.target.checked;
      const newValues = isChecked ? options.map(({ value }) => value) : [];

      setIsAllSelected(isChecked);
      handleSelectAll && handleSelectAll(newValues as string[]);
    };

    return (
      <div
        className={ctvTwMerge(
          classNames('ctv-checkbox', {
            'flex flex-col justify-start items-start': column,
          }),
          className,
        )}
      >
        {handleSelectAll ? (
          <Checkbox onChange={handleAll} checked={isAllSelected}>
            {selectAllLabel}
          </Checkbox>
        ) : null}
        <CheckboxAntd.Group
          className={ctvTwMerge(
            classNames('ctv-checkbox', {
              'flex flex-col justify-start items-start': column,
            }),
            className,
          )}
          onChange={handleChange}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          options={options as CheckboxOptionType<any>[]}
          {...rest}
        >
          {children}
        </CheckboxAntd.Group>
      </div>
    );
  },
);

CheckboxGroup.displayName = 'CheckboxGroup';

export const Checkbox = memo(({ children, className, ...rest }: CheckboxProps) => (
  <div className="checkbox-wrapper">
    <CheckboxAntd className={ctvTwMerge(classNames('ctv-checkbox'), className)} {...rest}>
      {children}
    </CheckboxAntd>
  </div>
));

Checkbox.displayName = 'Checkbox';
