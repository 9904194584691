// eslint-disable-next-line import/named
import { object, string, number, array, InferType } from 'yup';

import { InsertionOrder, LineItem, PriceType } from 'modules/xandr/xandrApiTypes';

import { formatInsertionOrders } from './utils';

export const schema = object().shape({
  id: string().required('Id is required'),
});
export type XandrConnectionFormInputs = InferType<typeof schema>;

export const insertionOrderEditSchema = object().shape({
  id: number().required(),
  name: string(),
  displayName: string().trim().required('Campaign name is required'),
});
export type XandrEditInsertionOrdersFormInputs = InferType<typeof insertionOrderEditSchema>;

export const lineItemEditSchema = object().shape({
  id: number().required(),
  name: string(),
  displayName: string().trim().required('Campaign name is required'),
  priceType: string().required(),
  audienceName: string().trim().required(),
  cpm: number().when('priceType', {
    is: PriceType.CPM,
    then: (schema) => schema.required().positive('cpm amount is required'),
    otherwise: (schema) => schema,
  }),
  budgetCap: number().when('priceType', {
    is: PriceType.CPM,
    then: (schema) => schema.required('Budget cap amount is required').positive(),
    otherwise: (schema) => schema,
  }),
  mediaType: number().required().positive(),
  devices: array().required().min(1, 'At least one device is required'),
});
export type XandrEditLineItemFormInputs = InferType<typeof lineItemEditSchema>;

export type XandrConnectionModalTypes = {
  onClose: () => void;
};
export type EditInsertionOrdersModalProps = {
  onClose: () => void;
  selectedInsertionOrder: InsertionOrder;
};
export type EditLineItemModalProps = {
  onClose: () => void;
  selectedLineItem: LineItem;
};

export type TableData = ReturnType<typeof formatInsertionOrders>[0];
