import { object, string } from 'yup';

import { Advertiser } from 'modules/organization/organizationApiTypes';

export interface AdvertiserFormInputs {
  name: string;
}
export const MAX_LENGTH = 30;

export const schema = object().shape({
  name: string().trim().required('Name is required'),
});

export type CreateAdvertiserModalProps = {
  onClose: () => void;
};
export type EditAdvertisersModalProps = {
  onClose: () => void;
  selectedAdvertiser: Advertiser | null;
};
