import { api } from 'services/api';

import { CONSTANT_URL, DEVICE_TYPES_URL, MEDIA_TYPES_URL } from './constants';
import { Constants, DeviceTypes, MediaTypes } from './miscApiTypes';

const getConstants = () => {
  return api.get<Constants>(CONSTANT_URL);
};

const getMediaTypes = () => {
  return api.get<MediaTypes>(MEDIA_TYPES_URL);
};

const getDeviceTypes = () => {
  return api.get<DeviceTypes>(DEVICE_TYPES_URL);
};

export const miscApi = {
  getConstants,
  getDeviceTypes,
  getMediaTypes,
};
