import { Select as SelectAntd, SelectProps as SelectAntdProps } from 'antd';
import classNames from 'classnames';
import { ChevronDown, ChevronUp } from 'feather-icons-react';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { ReactNode, forwardRef, useState } from 'react';

interface SelectProps extends SelectAntdProps {
  placeholder?: ReactNode;
  prefixText?: string;
  prefixIcon?: ReactNode;
}

export const Select = forwardRef<BaseSelectRef, SelectProps>(
  ({ className, placeholder, prefixText, prefixIcon, value, ...rest }: SelectProps, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleDropdownVisibleChange = (open: boolean) => {
      setIsOpen(open);
    };

    const containerClass = classNames('select-container', {
      'without-prefix': !prefixText && !prefixIcon,
      'text-prefix': !!prefixText,
      'icon-prefix': !!prefixIcon,
    });

    return (
      <div className={containerClass} data-prefix={prefixText}>
        {prefixIcon && <div className="prefix-icon">{prefixIcon}</div>}
        <SelectAntd
          ref={ref}
          placeholder={placeholder}
          suffixIcon={
            isOpen ? (
              <ChevronUp size="16" strokeWidth="1.5" />
            ) : (
              <ChevronDown size="16" strokeWidth="1.5" />
            )
          }
          maxTagCount="responsive"
          defaultActiveFirstOption={false}
          onDropdownVisibleChange={handleDropdownVisibleChange}
          popupClassName="ctv-select-dropdown"
          className={classNames('ctv-select', className)}
          //this is done show placeholder in case of numeric values
          {...(!(value === 0) && { value })}
          {...rest}
        />
      </div>
    );
  },
);
Select.displayName = 'Select';

export const Option = SelectAntd.Option;
