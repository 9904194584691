import { Progress as ProgressAntd, ProgressProps as ProgressLineProps } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';

type ProgressProps = {
  currentStep: number;
  steps: number;
  className?: string;
};

export const Progress = memo(({ currentStep, steps, className }: ProgressProps) => {
  const addEmptydots = () => <div className="h-1.5 w-1.5 rounded-full bg-primary-gray-200" />;
  const currentClassName = 'relative h-1.5 w-[18px] rounded-full bg-primary-electric-500';

  const current = <div className={classNames(currentClassName, `step-${currentStep}`)} />;
  const dots = Array(steps + 1)
    .fill('.')
    .map(addEmptydots)
    .concat([current])
    .reverse();

  return (
    <div
      className={classNames(
        'gap-1.5 flex-row flex h-1.5 -translate-x-3',
        'ctv-progress',
        className,
      )}
    >
      {dots}
    </div>
  );
});

Progress.displayName = 'Progress';

export const ProgressLine = memo(({ className, ...rest }: ProgressLineProps) => (
  <ProgressAntd className={classNames('ctv-progress-line', className)} {...rest} />
));

ProgressLine.displayName = 'ProgressLine';
