import { yupResolver } from '@hookform/resolvers/yup';
import { Plus } from 'feather-icons-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

import { Field, Input, IconButton, Chip } from 'components/common';

interface EmailType {
  email: string;
}
type EmailInputProps = {
  setEmails: (value: string[]) => void;
  label?: string;
  emails?: string[];
  fieldError?: string;
  placeholder?: string;
};

const schema = object()
  .shape({
    email: string().email('Please enter a valid email address.').required('Email is required'),
  })
  .required();

export const EmailInput = ({
  setEmails,
  label = '',
  emails = [],
  fieldError,
  placeholder = '',
}: EmailInputProps) => {
  const [emailList, setEmailList] = useState(emails);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<EmailType>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  useEffect(() => {
    setEmails(emailList);
  }, [emailList, setEmails]);

  const addEmail = (email: string) => {
    setEmailList((prev) => Array.from(new Set([...prev]).add(email)));
  };
  const removeEmail = (email: string) => {
    setEmailList((prev) => {
      const newSet = new Set([...prev]);

      newSet.delete(email);

      return Array.from(newSet);
    });
  };

  const handleEmailFormSubmit = handleSubmit(({ email }) => {
    reset();
    addEmail(email);
  });

  return (
    <form onSubmit={handleEmailFormSubmit} className="w-full ctv-email-form">
      <div className="stretch-to-form gap-3">
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex w-full">
          <Field
            label={label}
            render={({ field, fieldState: { invalid } }) => (
              <div className="justify-start items-end gap-2 inline-flex w-full">
                <Input {...field} invalid={invalid} placeholder={placeholder} />
                <div className="justify-start items-start flex">
                  <IconButton
                    variant="secondary"
                    size="md"
                    Icon={Plus}
                    disabled={!isValid}
                    onClick={handleEmailFormSubmit}
                  />
                </div>
              </div>
            )}
            control={control}
            name={'email'}
            error={errors.email?.message?.toString() || fieldError}
          />
        </div>
        <div className="justify-start items-start gap-2 inline-flex flex-wrap email-list">
          {emailList.map((email, i) => (
            <Chip key={`email-${i}`} onClose={() => removeEmail(email)}>
              {email}
            </Chip>
          ))}
        </div>
      </div>
    </form>
  );
};
