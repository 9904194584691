import classNames from 'classnames';
import { ChevronLeft, ChevronRight } from 'feather-icons-react';

import { paginationOptions } from 'components/constants';

import { ctvTwMerge } from 'services/twMerge/ctvTwMerge';

import { IconButton } from '../button';
import { Select, Option } from '../select';

type TablePaginationProps = {
  totalPages: number;
  totalCount?: number;
  setPageSize: (n: number) => void;
  pageSize: number;
  page: number;
  setPage: (n: number) => void;
  className?: string;
};

export const TablePagination = ({
  totalPages,
  totalCount,
  setPage,
  setPageSize,
  pageSize,
  page,
  className,
}: TablePaginationProps) => {
  const handlePageSizeChange = (size: number) => {
    setPage(0);
    setPageSize(size);
  };

  const setNextPage = () => {
    setPage(page + 1);
  };

  const setPreviousPage = () => {
    setPage(page - 1);
  };
  const isNextEnabled = page < totalPages - 1;
  const isPreviousEnabled = page > 0;

  const getPageRange = () => {
    const lastPossibleItem = (page + 1) * pageSize;
    const firstItem = lastPossibleItem - pageSize + 1;
    const totalItems = totalCount ?? totalPages * pageSize;
    const lastItem = lastPossibleItem > totalItems ? totalItems : lastPossibleItem;

    return `${firstItem}-${lastItem} of ${totalItems}`;
  };

  return (
    <div
      className={ctvTwMerge(
        classNames(
          'h-16 px-5 border-t border-primary-gray-100 justify-between items-center inline-flex w-full',
        ),
        className,
      )}
    >
      <div className="justify-start items-center gap-2 flex">
        <Select value={`Show ${pageSize} rows`} className="w-auto" onChange={handlePageSizeChange}>
          {paginationOptions.map((size) => (
            <Option key={size} value={size}>
              {size}
            </Option>
          ))}
        </Select>
        <div className="text-primary-gray-500 text-base-xs w-full">{getPageRange()}</div>
      </div>
      <div className="inline-flex justify-start">
        <IconButton
          Icon={ChevronLeft}
          variant="tertiary"
          onClick={setPreviousPage}
          disabled={!isPreviousEnabled}
        />
        <IconButton
          Icon={ChevronRight}
          variant="tertiary"
          onClick={setNextPage}
          disabled={!isNextEnabled}
        />
      </div>
    </div>
  );
};
