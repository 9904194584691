import { TableProps } from 'antd';

import { Table } from 'components/common';
import { TablePagination } from 'components/common/tablePagination';

import { PixelEvent } from 'modules/pixel/pixelApiTypes';

import { TimeStampTableProps } from './PixelVerificationTypes';
import { formatTimeStamp, valueOrDashFormatter } from './utils';

export const TimeStampTable = ({
  events,
  setPageSize,
  pageSize,
  page,
  setPage,
}: TimeStampTableProps) => {
  const eventColumns: TableProps<PixelEvent>['columns'] = [
    {
      dataIndex: 'timestamp',
      title: 'Timestamp',
      width: 192,
      fixed: 'left',
      ellipsis: false,
      render: formatTimeStamp,
    },
    {
      dataIndex: 'orderNumber',
      title: 'Order number',
      width: 128,
      render: valueOrDashFormatter,
    },
    {
      dataIndex: 'checkoutValue',
      title: 'Checkout value',
      width: 128,
      render: valueOrDashFormatter,
    },
    {
      dataIndex: 'category',
      title: 'Category',
      width: 128,
      render: valueOrDashFormatter,
    },
    {
      dataIndex: 'subcategory',
      title: 'Sub-category',
      width: 128,
      render: valueOrDashFormatter,
    },
    {
      dataIndex: 'referrerUrl',
      title: 'Referrer Url',
      render: (value: string) =>
        value ? (
          <a href={value} className="text-base-sm hover:text-primary-electric-300">
            {value}
          </a>
        ) : (
          '-'
        ),
    },
  ];

  return events && events.data.length ? (
    <div className="grid w-full ">
      <Table
        className="px-5"
        dataSource={events.data}
        columns={eventColumns}
        pagination={false}
        scroll={{ x: 1000 }}
      />
      <TablePagination
        pageSize={pageSize}
        totalPages={events.totalPages}
        totalCount={events.totalCount}
        setPageSize={setPageSize}
        page={page}
        setPage={setPage}
      />
    </div>
  ) : null;
};
