import { api } from 'services/api';

import {
  PIXEL_EVENTS_DAILY_URL,
  PIXEL_EVENTS_EXPORT_URL,
  PIXEL_EVENTS_OVERVIEW_URL,
  PIXEL_EVENTS_URL,
  PIXEL_WIDGET_URL,
} from './constants';
import {
  PixelEvents,
  PixelEventsRequest,
  PixelWidget,
  PixelRequest,
  PixelEventCategory,
  PixelEventDaily,
} from './pixelApiTypes';

const getPixelWidget = (payload: PixelRequest) => {
  return api.post<PixelWidget>(PIXEL_WIDGET_URL, payload);
};

const getPixelEvents = (payload: PixelEventsRequest) => {
  return api.post<PixelEvents>(PIXEL_EVENTS_URL, payload);
};

const getPixelCategories = (payload: PixelRequest) => {
  return api.post<PixelEventCategory[]>(PIXEL_EVENTS_OVERVIEW_URL, payload);
};

const getPixelExport = (payload: PixelEventsRequest) => {
  return api.post<unknown>(PIXEL_EVENTS_EXPORT_URL, payload);
};

const getPixelDailyEvents = (payload: PixelRequest) => {
  return api.post<PixelEventDaily[]>(PIXEL_EVENTS_DAILY_URL, payload);
};

export const pixelApi = {
  getPixelWidget,
  getPixelEvents,
  getPixelCategories,
  getPixelExport,
  getPixelDailyEvents,
};
