import { Layout } from 'antd';
import { ReactNode } from 'react';

const { Header: HeaderAntd } = Layout;

export type HeaderProps = {
  children: ReactNode;
};

export const Header = ({ children }: HeaderProps) => {
  return (
    <HeaderAntd className="ctv-header p-0 bg-white border-b border-solid border-b-1 border-primary-gray-200">
      <div className="h-16 flex justify-end items-center px-8 gap-3">{children}</div>
    </HeaderAntd>
  );
};
