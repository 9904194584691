/* eslint-disable no-constant-condition */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { object, string, array } from 'yup';

import { Button, Field, CheckboxGroup, Input, FieldLabel, Skeleton } from 'components/common';
import { Select, Option } from 'components/common/select';

import { useConstantsQuery } from 'modules/apiData/dataApiSlice';
import { useAuth } from 'modules/auth/useAuth';
import { defaultAgencyState } from 'modules/organization/organizationApiTypes';
import { useOrganization } from 'modules/organization/useOrganization';
import { useUser } from 'modules/user/useUser';
import { RoleKey, User, UserRoles } from 'modules/user/userApiTypes';

type InviteMembersModalProps = {
  onClose: () => void;
  user: undefined | User;
};

interface FormInputs {
  role: string;
  advertiser?: string;
  campaignAccess?: string[];
}

const schema = object().shape({
  role: string().oneOf(Object.keys(UserRoles)).required('Role is required'),
  advertiser: string().when('role', {
    is: UserRoles.LIMITED_ACCESS.value,
    then: (schema) => schema.required('Advertiser is required'),
    otherwise: (schema) => schema,
  }),
  campaignAccess: array().when('role', {
    is: UserRoles.LIMITED_ACCESS.value,
    then: (schema) => schema.min(1, 'At least one campaign is required').required(),
    otherwise: (schema) => schema,
  }),
});

export const EditMembersModal = ({ onClose, user }: InviteMembersModalProps) => {
  const { updateMember } = useUser();
  const { currentAgency, getCurrentAgency, getCampaigns, campaigns, isLoading } = useOrganization();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    values: {
      role: user?.role || '',
      advertiser: user?.advertiser?.id || '',
      campaignAccess: user?.insertionOrders?.map((io) => io.id.insertionOrderId.toString()) || [],
    },
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const isLimitedAccess = watch('role') === UserRoles.LIMITED_ACCESS.value;
  const { role } = useAuth();
  const { data: constants } = useConstantsQuery(null);

  const handleEditFormSubmit = handleSubmit((data) => {
    const payload = {
      role: data.role,
      subId: user?.subId || '',
      currentSelectedAgencyId: currentAgency.id,
    };

    const limitedAccessData = isLimitedAccess
      ? { advertiserId: data.advertiser || '', campaignIds: data.campaignAccess }
      : {};

    updateMember({ ...payload, ...limitedAccessData });
    handleClose();
  });

  const defaultAdvertiserId = user?.advertiser?.id || currentAgency?.advertisers[0].id || '';

  useEffect(() => {
    currentAgency === defaultAgencyState && getCurrentAgency();
  }, [getCurrentAgency, currentAgency]);

  useEffect(() => {
    if (defaultAdvertiserId) {
      setValue('advertiser', defaultAdvertiserId);
      getCampaigns(defaultAdvertiserId);
    }
  }, [defaultAdvertiserId]);

  const handleChangeSelect = async (advertiserId: string) => {
    setValue('campaignAccess', []);
    getCampaigns(advertiserId);
  };

  const filterUserRoles = (): {
    label: string;
    value: string;
  }[] => {
    const roleHierarchy = (constants && constants.RoleHierarchy) || {};

    const accessibleRoles = roleHierarchy[role as string];
    const filteredRoles: any = [];

    accessibleRoles.forEach((key) => {
      filteredRoles.push(UserRoles[key as RoleKey]);
    });

    return filteredRoles;
  };

  return (
    <div className="w-128 bg-white rounded-xl shadow flex-col justify-start items-start inline-flex">
      <div className="self-stretch px-6 justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 py-5 flex-col justify-start items-start gap-2 inline-flex">
          <h1 className="self-stretch text-base-md font-semibold leading-snug text-secondary-black">
            Edit member
          </h1>
        </div>
      </div>
      <div className="px-6 pt-3 pb-6 gap-6 stretch-to-form">
        <div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
          <form
            onSubmit={handleEditFormSubmit}
            className="w-full gap-4 flex flex-col justify-start items-start"
          >
            <div className="flex-col  gap-2 flex w-full">
              <FieldLabel htmlFor="name">Name</FieldLabel>
              <Input disabled value={user?.fullName} />
            </div>
            <div className="flex-col gap-2 flex w-full">
              <FieldLabel htmlFor="email">Email</FieldLabel>
              <Input disabled value={user?.email} />
            </div>
            <Field
              label={'Role'}
              render={({ field }) => (
                <Select className="w-full" {...field}>
                  {filterUserRoles().map(({ label, value }, i) => (
                    <Option key={`role-dropdown-${i}`} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              )}
              control={control}
              name={'role'}
              error={errors.role?.message}
            />
            {isLimitedAccess ? (
              <>
                <Field
                  label={'Advertiser'}
                  render={({ field }) => (
                    <Select
                      className="w-full"
                      {...field}
                      onChange={(id) => {
                        handleChangeSelect(id);
                        field.onChange(id);
                      }}
                    >
                      {currentAgency.advertisers.map(({ name, id }, i) => (
                        <Option key={`adv-dropdown-${i}`} value={id}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  )}
                  control={control}
                  name={'advertiser'}
                  error={errors.advertiser?.message}
                />
                <Field
                  label={'Campaign access'}
                  render={({ field }) =>
                    isLoading ? (
                      <div className="widget-container flex w-full">
                        {Array.from({ length: 4 }).map((_, index) => (
                          <Skeleton key={index} className="flex-1 h-[140px]" />
                        ))}
                      </div>
                    ) : campaigns?.length === 0 ? (
                      <div className="text-base-md">No available campaigns</div>
                    ) : (
                      <div className="basis-0 p-2.5 w-full overflow-y-scroll h-36 rounded-lg border border-primary-gray-100 bg-primary-gray-50">
                        <CheckboxGroup
                          {...field}
                          options={
                            campaigns?.map(({ name, id }) => ({
                              label: name,
                              value: id.toString(),
                            })) || []
                          }
                          column
                          className="gap-3"
                          handleSelectAll={field.onChange}
                          selectAllLabel="All Campaigns"
                        />
                      </div>
                    )
                  }
                  control={control}
                  className="w-full"
                  name={'campaignAccess'}
                  error={errors.campaignAccess?.message}
                />
              </>
            ) : null}
          </form>
          {/* <div className="stretch-to-form gap-3">
            {errorText && (
              <Indicator variant="danger" size="lg">
                {errorText}
              </Indicator>
            )}
          </div> */}
        </div>
      </div>
      <div className="self-stretch h-16 px-6 border-t border-primary-gray-100 justify-end items-center gap-2 inline-flex">
        <div className="justify-end items-center gap-2 flex">
          <Button size="md" variant="secondary" onClick={handleClose} className="w-20">
            Cancel
          </Button>
          <Button size="md" variant="primary" disabled={!isValid} onClick={handleEditFormSubmit}>
            Save changes
          </Button>
        </div>
      </div>
    </div>
  );
};
