import { Radio as RadioAntd, RadioProps, RadioGroupProps } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';

import { ctvTwMerge } from 'services/twMerge/ctvTwMerge';

interface RadioButtonProps extends Omit<RadioProps, 'size'> {
  cards?: boolean;
}

export const RadioGroup = memo(({ children, className, ...rest }: RadioGroupProps) => (
  <RadioAntd.Group className={ctvTwMerge(classNames('ctv-radio-group'), className)} {...rest}>
    {children}
  </RadioAntd.Group>
));

RadioGroup.displayName = 'RadioGroup';

export const Radio = memo(({ children, className, ...rest }: Omit<RadioProps, 'size'>) => (
  <RadioAntd className={ctvTwMerge(classNames('ctv-radio'), className)} {...rest}>
    {children}
  </RadioAntd>
));

Radio.displayName = 'Radio';

export const RadioButton = memo(({ children, className, cards, ...rest }: RadioButtonProps) => (
  <RadioAntd.Button
    className={ctvTwMerge(
      classNames('ctv-radio', { 'ctv-radio-cards': cards }, { 'ctv-radio-text': !cards }),
      className,
    )}
    {...rest}
  >
    {children}
  </RadioAntd.Button>
));

RadioButton.displayName = 'RadioButtons';
