import { PlusOutlined } from '@ant-design/icons';
import { Edit, Trash2 } from 'feather-icons-react';
import { useCallback, useEffect, useState } from 'react';

import { CurrentViewModal } from 'components/Views/CurrentViewModal';
import { DeleteCustomViewModal } from 'components/Views/DeleteCustomViewModal';
import { Button, Modal } from 'components/common';
import { Select } from 'components/common/select';

import { usePerformance } from 'modules/performance/usePerformance';

interface CustomOptionType {
  label: string;
  value: string;
  isCustom?: boolean;
}

export const Views = () => {
  const [isOpenCurrentViewModal, setIsOpenCurrentViewModal] = useState(false);
  const [isOpenDeleteViewModal, setIsOpenDeleteViewModal] = useState(false);
  const [clickedViewId, setClickedViewId] = useState<string | null>(null);
  const { getViews, views, selectedView, setViewId } = usePerformance();

  useEffect(() => {
    !views && getViews();
  }, [getViews, views]);

  const setCurrentView = useCallback(() => {
    if (views && !selectedView) {
      const defaultView = views.find((view) => view.isDefault);

      return defaultView ? defaultView.viewId : views[0].viewId;
    }

    return selectedView;
  }, [selectedView, views]);

  const handleCurrentViewModal = (isOpen: boolean) => {
    setIsOpenCurrentViewModal(isOpen);
  };

  const handleDeleteViewModal = (isOpen: boolean) => {
    setIsOpenDeleteViewModal(isOpen);
  };

  const isNewCustomViewSelected = selectedView === '0';

  return (
    <div>
      <Select
        placeholder="Select..."
        prefixText="View:"
        className="w-64"
        value={setCurrentView()}
        options={views?.map((el) => ({ label: el.name, value: el.viewId, isCustom: el.isCustom }))}
        optionRender={(option) => {
          const customOption = option.data as CustomOptionType;
          const isSaved = customOption.value !== '0';

          return (
            <div className="flex justify-between items-center w-full">
              <div className="truncate max-w-full text-base-sm font-normal leading-tight">
                {option.label}
              </div>
              {customOption.isCustom && (
                <div className="actions flex gap-2">
                  <Edit
                    onClick={(e: Event) => {
                      e.stopPropagation();
                      setClickedViewId(customOption.value);
                      handleCurrentViewModal(true);
                    }}
                  />
                  {isSaved && (
                    <Trash2
                      onClick={(e: Event) => {
                        e.stopPropagation();
                        setClickedViewId(customOption.value);
                        handleDeleteViewModal(true);
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          );
        }}
        onChange={(value) => {
          setViewId(value);
        }}
        dropdownRender={(menu) => (
          <>
            {menu}
            <div className="button-container flex justify-center px-3 pt-1.5 pb-3">
              <Button
                size="md"
                variant="primary"
                Icon={PlusOutlined}
                disabled={!isNewCustomViewSelected}
                onClick={() => {
                  clickedViewId ? setClickedViewId(null) : setClickedViewId('0');
                  handleCurrentViewModal(true);
                }}
              >
                Save current view
              </Button>
            </div>
          </>
        )}
      />
      <Modal
        open={isOpenCurrentViewModal}
        footer={null}
        destroyOnClose={true}
        onCancel={() => handleCurrentViewModal(false)}
      >
        <CurrentViewModal
          onClose={() => handleCurrentViewModal(false)}
          view={views?.find((view) => view.viewId === clickedViewId)}
        />
      </Modal>

      <Modal
        open={isOpenDeleteViewModal}
        footer={null}
        onCancel={() => handleDeleteViewModal(false)}
      >
        <DeleteCustomViewModal
          onClose={() => handleDeleteViewModal(false)}
          viewId={clickedViewId}
        />
      </Modal>
    </div>
  );
};
