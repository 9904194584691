/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';

import { FilterDimension } from 'components/FilterDimension';
import { Dimensions, Metrics } from 'components/Incrementality/incrementalityTypes';

import { IncrementalityFilters } from 'modules/performance/performanceApiTypes';

export const IncrementalityFilter = ({
  incrementalityFilter,
  onChange,
}: {
  incrementalityFilter: IncrementalityFilters | null | undefined;
  onChange: (data: IncrementalityFilters) => void;
}) => {
  const [dimension, setDimension] = useState<string | null>(
    incrementalityFilter?.dimension || Dimensions.OVERALL.value,
  );
  const [metric, setMetric] = useState<string | null>(
    incrementalityFilter?.metric || Metrics.LEADS.value,
  );

  const getOptions = useCallback(
    (items: typeof Dimensions | typeof Metrics) =>
      Object.entries(items).map(([key, { label }]) => ({
        label,
        value: key,
      })),
    [],
  );

  useEffect(() => {
    if (dimension && metric) {
      onChange({
        dimension,
        metric,
      });
    }
  }, [dimension, metric]);

  return (
    <>
      <FilterDimension
        prefixText="Dimension:"
        allOptions={getOptions(Dimensions)}
        selectedOption={dimension}
        onChange={(value) => setDimension(value)}
      />
      <FilterDimension
        prefixText="Metric:"
        allOptions={getOptions(Metrics)}
        selectedOption={metric}
        onChange={(value) => setMetric(value)}
      />
    </>
  );
};
