/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';

import { XandrEditLineItemFormInputs } from 'components/XandrSettings/XandrConnectionTypes';

import { getCurrentAdvertiserId } from 'modules/organization/organizationSlice';

import './xandrApiTypes';
import {
  editInsertionOrderStart,
  editLineItemStart,
  enableInsertionOrderStart,
  getInsertionOrdersStart,
  getXandrConnectionStart,
  getXandrState,
  getXandrStatusStart,
  refreshXandrConnectionStart,
  removeXandrConnectionStart,
  syncInsertionOrderStart,
  syncLineItemStart,
  syncAllInsertionOrdersStart,
  getLineItemsStart,
  getAudiencesStart,
} from './xandrSlice';

export const useXandr = () => {
  const dispatch = useDispatch();
  const state = useSelector(getXandrState);
  const advertiserId = useSelector(getCurrentAdvertiserId);
  const connection = state.connection;
  const insertionOrders = state.insertionOrders;
  const status = state.status;
  const isLoading = state.isLoading;
  const audiences = state.audiences;
  const lineItems = state.lineItems;

  function getXandrConnection(xandrAdvertiserId: string) {
    dispatch(
      getXandrConnectionStart({
        xandrAdvertiserId: xandrAdvertiserId,
        advertiserId,
      }),
    );
  }

  function getXandrStatus() {
    dispatch(getXandrStatusStart(advertiserId));
  }

  function refreshXandrConnection() {
    dispatch(refreshXandrConnectionStart(advertiserId));
  }

  function removeXandrConnection() {
    dispatch(removeXandrConnectionStart(advertiserId));
  }

  function syncLineItem(id: number) {
    dispatch(syncLineItemStart(id));
  }

  function editLineItem({
    id,
    displayName,
    audienceName,
    priceType,
    mediaType,
    devices,
    cpm,
    budgetCap,
  }: XandrEditLineItemFormInputs): Promise<void> {
    return new Promise((resolve, reject) => {
      dispatch(
        editLineItemStart({
          advertiserId,
          lineItem: {
            id,
            displayName,
            audienceName,
            priceType,
            cpm,
            budgetCap,
            mediaTypeId: mediaType,
            deviceTypeIds: devices,
          },
          resolve,
          reject,
        }),
      );
    });
  }

  function getInsertionOrders() {
    dispatch(getInsertionOrdersStart(advertiserId));
  }

  function getLineItems() {
    if (advertiserId) {
      dispatch(getLineItemsStart({ page: 0, size: 10000, advertiserId: advertiserId }));
    }
  }

  function getAudiences() {
    if (advertiserId) {
      dispatch(getAudiencesStart({ page: 0, size: 10000, advertiserId: advertiserId }));
    }
  }

  function editInsertionOrder(id: number, displayName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      dispatch(
        editInsertionOrderStart({
          advertiserId,
          insertionOrder: { id: id, displayName: displayName },
          resolve,
          reject,
        }),
      );
    });
  }

  function syncInsertionOrder(id: number) {
    dispatch(syncInsertionOrderStart(id));
  }

  function enableInsertionOrder(id: number, enabled: boolean) {
    dispatch(enableInsertionOrderStart({ id, enabled, advertiserId }));
  }

  function syncAllInsertionOrders() {
    dispatch(syncAllInsertionOrdersStart(advertiserId));
  }

  const errorText = state.error;

  return {
    getXandrConnection,
    getXandrStatus,
    refreshXandrConnection,
    removeXandrConnection,
    connection,
    insertionOrders,
    errorText,
    status,
    isLoading,
    syncLineItem,
    editLineItem,
    getInsertionOrders,
    editInsertionOrder,
    syncInsertionOrder,
    enableInsertionOrder,
    syncAllInsertionOrders,
    getLineItems,
    getAudiences,
    lineItems,
    audiences,
  };
};
