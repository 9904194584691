import { memo } from 'react';

import { ReactComponent as LogoBlack } from 'assets/images/logo/logoBlack.svg';
import { ReactComponent as LogoGradientDark } from 'assets/images/logo/logoGradientDark.svg';
import { ReactComponent as LogoGradientLight } from 'assets/images/logo/logoGradientLight.svg';
import { ReactComponent as LogoIconBlack } from 'assets/images/logo/logoIconBlack.svg';
import { ReactComponent as LogoIconGradientDark } from 'assets/images/logo/logoIconGradientDark.svg';
import { ReactComponent as LogoIconGradientLight } from 'assets/images/logo/logoIconGradientLight.svg';
import { ReactComponent as LogoIconWhite } from 'assets/images/logo/logoIconWhite.svg';
import { ReactComponent as LogoSolidDark } from 'assets/images/logo/logoSolidDark.svg';
import { ReactComponent as LogoSolidLight } from 'assets/images/logo/logoSolidLight.svg';
import { ReactComponent as LogoWhite } from 'assets/images/logo/logoWhite.svg';

type WordmarkVariant =
  | 'gradient-dark'
  | 'gradient-light'
  | 'solid-dark'
  | 'solid-light'
  | 'black'
  | 'white';
type WordmarkIconVariant = Omit<WordmarkVariant, 'solid-dark' | 'solid-light'>;

interface WordmarkProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  variant: WordmarkVariant;
}
interface WordmarkIconProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  variant: WordmarkIconVariant;
}

export const Wordmark = memo(({ variant, className }: WordmarkProps) => (
  <>
    {variant === 'gradient-dark' && <LogoGradientDark className={className} />}
    {variant === 'gradient-light' && <LogoGradientLight className={className} />}
    {variant === 'solid-dark' && <LogoSolidDark className={className} />}
    {variant === 'solid-light' && <LogoSolidLight className={className} />}
    {variant === 'black' && <LogoBlack className={className} />}
    {variant === 'white' && <LogoWhite className={className} />}
  </>
));

Wordmark.displayName = 'Wordmark';

export const WordmarkIcon = memo(({ variant, className }: WordmarkIconProps) => (
  <>
    {variant === 'gradient-dark' && <LogoIconGradientDark className={className} />}
    {variant === 'gradient-light' && <LogoIconGradientLight className={className} />}
    {variant === 'black' && <LogoIconBlack className={className} />}
    {variant === 'white' && <LogoIconWhite className={className} />}
  </>
));

WordmarkIcon.displayName = 'WordmarkIcon';
