import { extendTailwindMerge } from 'tailwind-merge';

const themeConfig = {
  fontSize: [
    'base',
    'title',
    'heading-5xl',
    'heading-4xl',
    'heading-3xl',
    'heading-2xl',
    'heading-xl',
    'base-lg',
    'base-md',
    'base-sm',
    'base-xs',
  ],
  backgroundImage: ['main'],
  boxShadow: [],
  colors: [
    'primary-gray-900',
    'primary-gray-800',
    'primary-gray-700',
    'primary-gray-600',
    'primary-gray-500',
    'primary-gray-400',
    'primary-gray-300',
    'primary-gray-200',
    'primary-gray-100',
    'primary-gray-75',
    'primary-gray-50',
    'primary-gray-25',

    'primary-electric-900',
    'primary-electric-800',
    'primary-electric-700',
    'primary-electric-600',
    'primary-electric-500',
    'primary-electric-400',
    'primary-electric-300',
    'primary-electric-200',
    'primary-electric-100',
    'primary-electric-75',
    'primary-electric-50',
    'primary-electric-25',

    'primary-punch-900',
    'primary-punch-800',
    'primary-punch-700',
    'primary-punch-600',
    'primary-punch-500',
    'primary-punch-400',
    'primary-punch-300',
    'primary-punch-200',
    'primary-punch-100',
    'primary-punch-75',
    'primary-punch-50',
    'primary-punch-25',

    'secondary-black',
    'secondary-gray',
    'secondary-white',

    'text-500',
    'text-200',
    'text-100',
    'text-50',

    'system-danger-900',
    'system-danger-800',
    'system-danger-700',
    'system-danger-600',
    'system-danger-500',
    'system-danger-400',
    'system-danger-300',
    'system-danger-200',
    'system-danger-100',
    'system-danger-75',
    'system-danger-50',
    'system-danger-25',
    'system-danger-hover',
    'system-danger-active',

    'system-warning-900',
    'system-warning-800',
    'system-warning-700',
    'system-warning-600',
    'system-warning-500',
    'system-warning-400',
    'system-warning-300',
    'system-warning-200',
    'system-warning-100',
    'system-warning-75',
    'system-warning-50',
    'system-warning-25',
    'system-warning-hover',
    'system-warning-active',

    'system-success-900',
    'system-success-800',
    'system-success-700',
    'system-success-600',
    'system-success-500',
    'system-success-400',
    'system-success-300',
    'system-success-200',
    'system-success-100',
    'system-success-75',
    'system-success-50',
    'system-success-25',
    'system-success-hover',
    'system-success-active',
  ],
};

const classGroupsConfig = {
  fontSize: [
    'text-inherit',
    'text-base',
    'text-title',
    'text-heading-5xl',
    'text-heading-4xl',
    'text-heading-3xl',
    'text-heading-xl',
    'text-base-lg',
    'text-base-md',
    'text-base-sm',
    'text-base-xs',
  ],
  backgroundImage: ['bg-main'],
  boxShadow: [],
  backgroundColors: [
    'bg-system-danger-900',
    'bg-system-danger-800',
    'bg-system-danger-700',
    'bg-system-danger-600',
    'bg-system-danger-500',
    'bg-system-danger-400',
    'bg-system-danger-300',
    'bg-system-danger-200',
    'bg-system-danger-100',
    'bg-system-danger-75',
    'bg-system-danger-50',
    'bg-system-danger-25',

    'bg-system-warning-900',
    'bg-system-warning-800',
    'bg-system-warning-700',
    'bg-system-warning-600',
    'bg-system-warning-500',
    'bg-system-warning-400',
    'bg-system-warning-300',
    'bg-system-warning-200',
    'bg-system-warning-100',
    'bg-system-warning-75',
    'bg-system-warning-50',
    'bg-system-warning-25',

    'bg-system-success-900',
    'bg-system-success-800',
    'bg-system-success-700',
    'bg-system-success-600',
    'bg-system-success-500',
    'bg-system-success-400',
    'bg-system-success-300',
    'bg-system-success-200',
    'bg-system-success-100',
    'bg-system-success-75',
    'bg-system-success-50',
    'bg-system-success-25',

    'bg-secondary-white',
  ],
};

const conflictingClassGroupModifiersConfig = {
  hover: classGroupsConfig.backgroundColors,
  active: classGroupsConfig.backgroundColors,
};

const conflictingClassGroupsConfig = {
  textColor: [
    'text-primary-gray-900',
    'text-primary-gray-800',
    'text-primary-gray-700',
    'text-primary-gray-600',
    'text-primary-gray-500',
    'text-primary-gray-400',
    'text-primary-gray-300',
    'text-primary-gray-200',
    'text-primary-gray-100',
    'text-primary-gray-75',
    'text-primary-gray-50',
    'text-primary-gray-25',

    'text-secondary-black',
    'text-secondary-gray',
    'text-secondary-white',

    'text-text-500',
    'text-text-200',
    'text-text-100',
    'text-text-50',

    'text-system-danger-900',
    'text-system-danger-800',
    'text-system-danger-700',
    'text-system-danger-600',
    'text-system-danger-500',
    'text-system-danger-400',
    'text-system-danger-300',
    'text-system-danger-200',
    'text-system-danger-100',
    'text-system-danger-75',
    'text-system-danger-50',
    'text-system-danger-25',

    'text-system-warning-900',
    'text-system-warning-800',
    'text-system-warning-700',
    'text-system-warning-600',
    'text-system-warning-500',
    'text-system-warning-400',
    'text-system-warning-300',
    'text-system-warning-200',
    'text-system-warning-100',
    'text-system-warning-75',
    'text-system-warning-50',
    'text-system-warning-25',

    'text-system-success-900',
    'text-system-success-800',
    'text-system-success-700',
    'text-system-success-600',
    'text-system-success-500',
    'text-system-success-400',
    'text-system-success-300',
    'text-system-success-200',
    'text-system-success-100',
    'text-system-success-75',
    'text-system-success-50',
    'text-system-success-25',
  ],
  backgroundColors: classGroupsConfig.backgroundColors,
};

export const ctvMergeConfig = {
  theme: themeConfig,
  classGroups: classGroupsConfig,
  conflictingClassGroups: conflictingClassGroupsConfig,
  conflictingClassGroupModifiers: conflictingClassGroupModifiersConfig,
};

export const ctvTwMerge = extendTailwindMerge(ctvMergeConfig);
