import { Check } from 'feather-icons-react';

import { Select } from 'components/common/select';

interface FilterDimensionProps {
  prefixText: string;
  allOptions: { label: string; value: string }[];
  selectedOption: string | null;
  onChange: (option: string) => void;
}

export const FilterDimension = ({
  prefixText,
  allOptions,
  selectedOption,
  onChange,
}: FilterDimensionProps) => {
  return (
    <div>
      <Select
        placeholder="Select..."
        prefixText={prefixText}
        className="filter-dropdown w-64"
        popupClassName="filter-dropdown-select-dropdown"
        value={selectedOption}
        options={allOptions}
        menuItemSelectedIcon={<Check size="20" className="ml-2" />}
        onChange={onChange}
      />
    </div>
  );
};
