import { PagingRequestValues } from 'modules/organization/organizationApiTypes';

import { api } from 'services/api';

import {
  REPORTS_TEMPLATES_URL,
  REPORTS_URL,
  REPORTS_DOWNLOAD_URL,
  REPORTS_STATUS_URL,
} from './constants';
import {
  ReportsPagingResponse,
  ReportTemplate,
  Report,
  ReportCreatePayload,
  ReportStatusResponse,
} from './reportApiTypes';

const getReports = (payload: PagingRequestValues) => {
  const sortBy = payload.sortBy ? `&sortBy=${payload.sortBy}` : '';
  const direction = payload.direction ? `&direction=${payload.direction}` : '';
  const search = payload.search ? `&search=${payload.search}` : '';

  const optionalParamsString = sortBy && direction ? sortBy.concat(direction, search) : search;

  return api.get<ReportsPagingResponse>(
    `${REPORTS_URL}?advertiserId=${payload.advertiserId}&page=${payload.page}&size=${payload.size}${optionalParamsString}`,
  );
};

const saveReport = (payload: ReportCreatePayload) => {
  return api.post<Report>(REPORTS_URL, payload);
};

const getReportTemplates = () => {
  return api.get<ReportTemplate[]>(REPORTS_TEMPLATES_URL);
};

const downloadReport = (reportId: string) => {
  return api.get<unknown>(`${REPORTS_DOWNLOAD_URL}/${reportId}`, { responseType: 'blob' });
};

const getReportStatus = (reportId: string) => {
  return api.get<ReportStatusResponse>(`${REPORTS_STATUS_URL}/${reportId}`);
};

const deleteReport = (reportId: string) => {
  return api.delete(`${REPORTS_URL}/${reportId}`);
};

export const reportApi = {
  getReports,
  saveReport,
  getReportTemplates,
  deleteReport,
  downloadReport,
  getReportStatus,
};
