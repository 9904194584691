import { toast, ToastOptions } from 'react-toastify';

const options: ToastOptions<unknown> | undefined = {
  position: 'bottom-center',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
  theme: 'light',
  //transition: Bounce,
};

const NotificationService = {
  success: (message: string) => {
    toast.success(message, options);
  },
  error: (message: string) => {
    toast.error(message, options);
  },
  info: (message: string) => {
    toast.info(message, options);
  },
  warn: (message: string) => {
    toast.warn(message, options);
  },
};

export default NotificationService;
