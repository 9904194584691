export type User = {
  advertiserId: string | undefined | null;
  email: string | undefined;
  fullname: string | undefined;
  phone: string | undefined;
  role: string | undefined;
  id: string | undefined;
  userpic: string | undefined;
};

export type SigninPayload = {
  username: string;
  password: string;
};

export type SignupPayload = {
  username: string;
  fullname: string;
  tempPassword: string;
  password: string;
};

export type PasswordConfirmation = {
  confirmPassword: string;
};

export type ForgotPasswordPayload = {
  username: string;
};

export type ChangePasswordPayload = {
  oldPassword: string;
  newPassword: string;
  resolve?: () => void;
  reject?: (error: string) => void;
};

export type CreateNewPasswordPayload = {
  newPassword: string;
  confirmationCode: string;
  username: string;
};

export type AuthToken = string;

export type AuthResponse = {
  user: User;
};

export type Profile = {
  advertiser: string | null;
  creationDate: string;
  email: string;
  fullName: string;
  id: string;
  modificationDate: string;
  phoneNumber: string;
  role: string;
  status: string;
  subId: string;
};

export type AuthState = {
  isLoading: boolean;
  user: User | null;
  error: string | null;
  idToken: string | undefined | null;
  accessToken: string | undefined | null;
};

export type UserRequestValues = {
  subId: string;
  fullName?: string | null;
  phone?: string | null;
  pictureUrl?: string | null;
};

export enum AuthMessages {
  NO_REGISTERED_USER = 'There is no such registered user',
  NOT_SENT = 'Confirmation code was not sent to email',
}

export enum AuthStepStatuses {
  CONFIRM_SIGN_IN = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
  CONFIRM_RESET_PASSWORD = 'CONFIRM_RESET_PASSWORD_WITH_CODE',
}
