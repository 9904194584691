import { Spin as SpinAntd, SpinProps as SpinPropsAntd } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';

import { ReactComponent as SpinBlack } from 'assets/images/icons/spin-black.svg';
import { ReactComponent as SpinElectric } from 'assets/images/icons/spin-electric.svg';
import { ReactComponent as SpinWhite } from 'assets/images/icons/spin-white.svg';

const indicators = {
  electric: <SpinElectric />,
  black: <SpinBlack />,
  white: <SpinWhite />,
};

interface SpinProps extends SpinPropsAntd {
  variant?: 'electric' | 'black' | 'white';
}

export const Spin = memo(({ className, variant = 'electric', ...rest }: SpinProps) => (
  <SpinAntd
    wrapperClassName="w-full"
    className={classNames('ctv-spinner', className)}
    indicator={indicators[variant]}
    {...rest}
  />
));

Spin.displayName = 'Spin';
