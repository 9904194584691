import { useState } from 'react';

import { ReactComponent as IconSettingSlider } from 'assets/images/icons/settings-slider.svg';

import { AdvancedConfigurationModal } from 'components/AdvancedConfiguration/AdvancedConfigurationModal';
import { IconButton, Modal } from 'components/common';
import { IconType } from 'components/common/button/types';

import { AdvancedConfiguration as Configuration } from 'modules/performance/performanceApiTypes';

export const AdvancedConfiguration = ({
  advancedConfiguration,
  onChange,
}: {
  advancedConfiguration: Configuration;
  onChange: (data: Configuration) => void;
}) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const handleModalAction = (isOpen: boolean) => setIsOpenModal(isOpen);

  return (
    <>
      <IconButton
        className="ml-2"
        size="md"
        variant="outline"
        Icon={IconSettingSlider as IconType}
        onClick={() => handleModalAction(true)}
      />
      <Modal open={isOpenModal} closable={false} footer={null} destroyOnClose>
        <AdvancedConfigurationModal
          advancedConfiguration={advancedConfiguration}
          onClose={() => handleModalAction(false)}
          onSubmit={onChange}
        />
      </Modal>
    </>
  );
};
