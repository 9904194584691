export const INSERTION_ORDER_URL = 'insertion-order/update';

export const INSERTION_ORDER_FULL_URL = 'insertion-order/full';

export const INSERTION_ORDER_SYNC_URL = 'insertion-order/sync';

export const INSERTION_ORDER_STATUS_URL_LIST = 'insertion-order/status/list';

export const LINE_ITEM_EDIT_URL = 'line-item/update';

export const LINE_ITEM_SYNC_URL = 'line-item/sync';

export const LINE_ITEMS_URL = 'line-item';

export const AUDIENCE_URL = 'line-item/audience';

export const XANDR_STATUS_URL = 'xandr/status';

export const XANDR_REMOVE_URL = 'xandr/remove';

export const XANDR_REFRESH_URL = 'xandr/refresh';

export const XANDR_CONNECTION_URL = 'xandr/connection';
