import { Button as ButtonAntd } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';

import { iconButtonVariants } from './buttonVariants';
import { IconButtonProps } from './types';

export const IconButton = memo(
  ({ Icon, variant, disabled, size, className, ...rest }: Omit<IconButtonProps, 'type'>) => {
    const { buttonBody, icon } = iconButtonVariants({ color: variant, size });

    return (
      <ButtonAntd
        shape="circle"
        disabled={disabled}
        className={classNames(buttonBody(), className)}
        {...rest}
      >
        {Icon && <Icon className={icon()} width="2em" height="2em" />}
      </ButtonAntd>
    );
  },
);
IconButton.displayName = 'IconButton';
