import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DraggableSyntheticListeners,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import { ReactNode, createContext, useMemo, useContext } from 'react';

import { ReactComponent as Dots } from 'assets/images/icons/dots.svg';

type ItemType = { value: string };
type SortableItemType = { value: string; children: ReactNode };
type SortableListProps<T> = {
  items: T[];
  setItems: (value: string[]) => void;
  renderItem: (item: T) => ReactNode;
};

interface Context {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
  isDragging: boolean;
}

const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {},
  isDragging: false,
});

export const SortableItem = ({ value, children }: SortableItemType) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id: value,
  });

  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
      isDragging,
    }),
    [attributes, listeners, setActivatorNodeRef, isDragging],
  );

  return (
    <SortableItemContext.Provider value={context}>
      <div
        className="w-full"
        ref={setNodeRef}
        style={{ transform: CSS.Transform.toString(transform), transition }}
      >
        {children}
      </div>
    </SortableItemContext.Provider>
  );
};

export const DragHandle = () => {
  const { attributes, listeners, ref, isDragging } = useContext(SortableItemContext);

  return (
    <div ref={ref} {...listeners} {...attributes}>
      <Dots
        className={classNames('w-5 h-5 relative cursor-grab', {
          'cursor-grabbing': isDragging,
        })}
      />
    </div>
  );
};

const SortableList = <T extends ItemType>({
  items,
  setItems,
  renderItem,
}: SortableListProps<T>) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const oldIndex = items.findIndex((i) => i.value === active.id);
      const newIndex = items.findIndex((i) => i.value === over?.id);

      setItems(arrayMove(items, oldIndex, newIndex).map((v) => v.value));
    }
  };

  const sortableItems = items.map((i) => ({ ...i, id: i.value }));

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={sortableItems} strategy={verticalListSortingStrategy}>
        <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
          {sortableItems.map((item) => (
            <SortableItem key={item.value} value={item.value}>
              {renderItem(item)}
            </SortableItem>
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default SortableList;
