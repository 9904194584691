import { AxiosInstance } from 'axios';

import { authStorage } from 'modules/auth/authStorage';

export function addRequestInterceptor(instance: AxiosInstance) {
  instance.interceptors.request.use(
    async (config) => {
      const authToken = await authStorage.getIdToken();

      if (authToken) {
        if (config.headers) {
          config.headers['Authorization'] = `Bearer ${authToken}`;
        }
      }

      return config;
    },
    (err) => Promise.reject(err),
  );
}
